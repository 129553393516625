import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBox } from '../../../../../common';
export const PublicChoiceBox = React.memo(({ defaultHide, setHide }) => {
    const { t } = useTranslation();
    const [list, setList] = React.useState([
        { id: '1', title: '공개', checked: false },
        { id: '2', title: '비공개', checked: false },
    ]);
    React.useEffect(() => {
        setList(list.map(item => ({ ...item, checked: item.id === (defaultHide ? '2' : '1') })));
    }, [defaultHide]);
    return (React.createElement("div", { className: "field-global-001" },
        React.createElement("div", { className: "task-label" }, t('assignment.isPublic')),
        React.createElement("div", { className: "fext-field" },
            React.createElement(CheckBox, { isRadioButton: true, list: list, handlerCheck: item => {
                    setHide(item === '2' ? true : false);
                } }))));
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../common';
import { MENU_LIST } from './constant';
export const AdminMenuDetail = React.memo(({ menu, setMenu }) => {
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null, MENU_LIST.map(item => (React.createElement(Button, { key: item, className: `admin-menu-detail-btn ${menu === item ? 'active' : ''}`, text: `${t(`menu.${item}`)}`, onClick: () => {
            setMenu(item);
            APP.eventManager.publish('CHANGE_MENU', {
                showDepth: 'list',
                props: {},
            });
        } })))));
});

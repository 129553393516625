import React, { useMemo, useState } from 'react';
import { Button, CheckBox, DropDown, Icon, InputElement, SearchBar, } from '../../../../common';
import moment from 'moment';
import { useGlobalState } from '../../../../GlobalStateProvider';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
const SendSMSTitleStyled = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;

	.fext-field {
		input {
			border-color: var(--color-grey-w, #e6e8ed) !important;
		}
	}

	.dropdown-mobile-small {
		width: ${props => (props.$isMobile ? '' : '110px')};
	}

	.search-bar-big {
		height: 45px;
	}

	.sms-check {
		min-width: ${props => (props.$isDesktop ? '210px' : '')};
	}

	.btn_light_pur {
		p {
			font-size: ${props => !props.$isDesktop && '12px'};
		}
	}

	.dropdown-wrap {
		width: ${props => (props.$isTablet ? 'unset' : '100%')};
		display: flex;
		gap: 10px;
	}

	.active-bar {
		display: flex;
		flex-direction: row;
		gap: 14px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;
		flex: 1;
	}
`;
//데스크탑에서 체크박스 리스트
const STATE_CHECKBOX_LIST = [
    { id: 'status', title: 'sendSMS.smsType', checked: false },
    { id: 'content', title: 'sendSMS.content', checked: false },
    { id: 'sender', title: 'sendSMS.sender', checked: false },
];
//패드, 모바일에서 드롭다운 리스트
const FILTER_DROPDOWN_LIST = [
    { id: 'select', title: 'common.select' },
    { id: 'status', title: 'sendSMS.smsType' },
    { id: 'content', title: 'sendSMS.content' },
    { id: 'sender', title: 'sendSMS.sender' },
];
const STATE_DROPDOWN_LIST = [
    { id: 'select', title: 'common.select' },
    { id: 'created', title: 'sendSMS.reservation' },
    { id: 'sent', title: 'sendSMS.sent' },
    { id: 'failed', title: 'sendSMS.failed' },
];
const COMMON_DROPDOWN_LIST = [{ id: 'select', title: 'common.select' }];
export const SendSMSTitle = React.memo(({ setStartDate, startDate, setEndDate, endDate, handlerFilterList, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const { t } = useTranslation();
    const endRef = React.useRef(null);
    const startRef = React.useRef(null);
    const [list, setList] = React.useState(STATE_CHECKBOX_LIST);
    const [state, setState] = React.useState(STATE_DROPDOWN_LIST[0]);
    const [filter, setFilter] = React.useState(FILTER_DROPDOWN_LIST[0]);
    const [searchOption, setSearchOption] = useState({});
    const handlerCheck = (id, checked) => {
        setList(prevList => {
            return prevList.map(item => {
                if (item.id === id) {
                    return { ...item, checked: true };
                }
                return { ...item, checked: false };
            });
        });
    };
    //라디오 버튼 클릭 시 동작
    const { checkTitle, dropList } = useMemo(() => {
        const checkTitle = isDesktop ? list.find(item => item.checked)?.id : filter.id;
        const dropList = checkTitle === 'status' ? STATE_DROPDOWN_LIST : COMMON_DROPDOWN_LIST;
        if (checkTitle === 'status' && state.id !== 'select') {
            setSearchOption({
                [checkTitle]: state.id === 'sent' ? [state.id, 'sending'] : state.id,
            });
        }
        else {
            setSearchOption({ undefined });
            setState(COMMON_DROPDOWN_LIST[0]);
        }
        return {
            checkTitle: checkTitle,
            dropList,
        };
    }, [list, state, filter]);
    const filterList = (filter) => {
        if (checkTitle === 'status')
            return;
        setSearchOption(filter);
    };
    const handlerSearch = () => {
        handlerFilterList([
            searchOption,
            {
                ...(startDate || endDate
                    ? {
                        ...(startDate && {
                            send_time_after: moment(startDate)
                                .startOf('day')
                                .format('YYYY-MM-DDTHH:mm:ssZ'),
                        }),
                        ...(endDate && {
                            send_time_before: moment(endDate)
                                .endOf('day')
                                .format('YYYY-MM-DDTHH:mm:ssZ'),
                        }),
                    }
                    : {}),
            },
        ]);
    };
    const stateDropdown = useMemo(() => (React.createElement(DropDown, { className: "dropdown-mobile-small", value: state, list: dropList, setDropValue: setState })), [state, dropList]);
    const handlerCheckStartDate = startDate => {
        if (moment(startDate).isAfter(endDate)) {
            alert(`${endDate} 이전의 날짜를 선택해주세요.`);
            startRef.current.value = '';
            return;
        }
        else {
            setStartDate(startDate);
            setEndDate(endDate);
        }
    };
    const handlerCheckEndDate = endDate => {
        if (moment(endDate).isBefore(startDate)) {
            alert(`${startDate} 이후의 날짜를 선택해주세요.`);
            endRef.current.value = '';
            return;
        }
        else {
            setEndDate(endDate);
            setStartDate(startDate);
        }
    };
    return (React.createElement(SendSMSTitleStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement("div", { className: "active-bar" },
            React.createElement("div", { className: "field-global-001", style: {
                    flexDirection: isMobile ? 'column' : 'row',
                    width: isDesktop && 'unset',
                } },
                isDesktop ? (React.createElement(React.Fragment, null,
                    React.createElement(Button, { className: "btn_light_grey xs", text: "\uC720\uD615", styles: {
                            buttonWrap: {
                                width: '66px',
                            },
                        } }),
                    React.createElement(CheckBox, { className: "sms-check", isRadioButton: true, list: list, handlerCheck: handlerCheck }),
                    stateDropdown)) : (React.createElement("div", { className: "dropdown-wrap" },
                    React.createElement(DropDown, { className: "dropdown-mobile-small", value: filter, list: FILTER_DROPDOWN_LIST, setDropValue: setFilter }),
                    stateDropdown)),
                isDesktop && (React.createElement(Button, { className: "btn_light_grey xs", text: t('supportRoom.dateSearch'), styles: {
                        buttonWrap: {
                            width: '75px',
                        },
                    } })),
                React.createElement("div", { className: "fext-field", style: { flex: 1 } },
                    React.createElement(InputElement, { ref: startRef, type: "date", defaultValue: startDate, onChange: handlerCheckStartDate, style: { minWidth: isDesktop && '140px', height: '41px' } }),
                    React.createElement(Icon, { icon: "dash", width: 12 }),
                    React.createElement(InputElement, { ref: endRef, type: "date", defaultValue: endDate, onChange: handlerCheckEndDate, style: { minWidth: isDesktop && '140px', height: '41px' } }))),
            React.createElement("div", { className: "field-global-001", style: { width: isDesktop && 'unset' } },
                React.createElement(SearchBar, { className: "search-bar-big", filterData: checkTitle, handlerFilterList: filterList, isIconHide: true, isDisable: checkTitle === 'status' ? true : false }),
                React.createElement("div", { className: "btn-container", style: { margin: 0 } },
                    React.createElement(Button, { className: `btn_light_pur ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('supportRoom.search'), styles: {
                            buttonWrap: {
                                maxWidth: !isMobile && '80px',
                                height: '40px',
                            },
                        }, onClick: handlerSearch }))))));
});

import throttle from 'lodash/throttle';
import moment from 'moment';
import React from 'react';
import { Table } from '../../../common';
import { useMemberInfo } from '../../../server';
import { useTranslation } from 'react-i18next';
export const StatisticsList = React.memo(({ id, onlyOne = false, searchOption, initPage, defaultProps, }) => {
    const { t } = useTranslation();
    const countRef = React.useRef(10);
    const [page, setPage] = React.useState(initPage);
    const [totalCount, setTotalCount] = React.useState(0);
    const [testData, setTestData] = React.useState([]);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'siteName', space: 1 },
            { title: 'name', space: 1 },
            { title: 'idf', space: 1 },
            { title: 'phone', space: 1 },
            { title: 'role', space: 1 },
            { title: 'inviteKlass', space: 1 },
            { title: 'lastConnection', space: 1 },
        ];
        const tableReadDataKeys = [
            { title: 'tenant_id', space: 1 },
            { title: 'nickname', space: 1 },
            { title: 'identifier', space: 1 },
            { title: 'phone_number', space: 1 },
            { title: 'level_name', space: 1 },
            { title: 'found_classes', space: 1 },
            { title: 'last_accessed_time', space: 1 },
        ];
        return { headTitleList, tableReadDataKeys };
    }, []);
    // 해당 클래스에 해당하는 사용자 가져오기
    const { data, refetch } = useMemberInfo({
        ...(id ? { id } : {}),
        ...(searchOption ? { nickname_like: searchOption } : {}),
        offset: page * countRef.current,
        limit: countRef.current,
        orderby: ['level ASC', 'id'],
        with_more_info: true,
    });
    const refetchList = throttle(() => refetch(), 1000);
    React.useEffect(() => {
        refetchList();
    }, [id, searchOption, page, countRef.current]);
    React.useEffect(() => {
        if (data) {
            const { total_count, items, found_count } = data;
            if (total_count > 0 && found_count === 0) {
            }
            else {
                if (items.length > 0) {
                    setTestData(items);
                    setTotalCount(total_count);
                    return;
                }
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    React.useEffect(() => {
        setPage(initPage);
    }, [initPage]);
    const handlerMoreData = (page) => {
        setPage(page);
        APP.eventManager.publish('UPDATE_PAGE', page);
    };
    const handlerSelectItem = (item) => {
        APP.eventManager.publish('CHANGE_MENU', {
            showDepth: 'detail',
            props: { id: item.id },
        });
    };
    const renderEmpty = React.useMemo(() => totalCount <= 0 && (React.createElement("div", { className: "empty_data" },
        React.createElement("p", null, "\uB4F1\uB85D\uB41C \uD074\uB798\uC2A4\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4."))), [totalCount]);
    const renderItem = (item) => {
        if (!item)
            return;
        return tableReadDataKeys.map((headItem, idx) => (React.createElement("div", { key: idx, className: "table-item", style: {
                flex: headItem.space,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                overflow: 'hidden',
            } }, (() => {
            switch (headItem.title) {
                case 'nickname':
                    return (React.createElement("p", { className: "overText2 underline" }, item[headItem.title]));
                case 'phone_number':
                    if (!item[headItem.title])
                        return React.createElement("p", null, "-");
                    return React.createElement("p", { className: "overText2" }, item[headItem.title]);
                case 'last_accessed_time':
                    if (!item[headItem.title])
                        return React.createElement("p", null, "-");
                    return (React.createElement("p", { className: "overText2" }, moment(item[headItem.title]).format('YYYY-MM-DD, HH:mm')));
                case 'level_name':
                    return (React.createElement("p", { className: "overText2" }, t(`common.${item[headItem.title]}`)));
                case 'found_classes':
                    if (!item[headItem.title])
                        return React.createElement("p", null, "-");
                    return React.createElement("p", { className: "overText2" },
                        item[headItem.title].length,
                        " ");
                default:
                    return React.createElement("p", { className: "overText2" }, item[headItem.title]);
            }
        })())));
    };
    return (React.createElement(Table, { className: `header-center ${onlyOne ? 'table-white-grey' : ''}`, showIndex: true, isInnerPage: true, isInifinite: false, page: page, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderItem: renderItem, renderEmpty: renderEmpty, handlerSelectItem: handlerSelectItem, handlerMoreData: handlerMoreData }));
});

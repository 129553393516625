import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { ReplyRoomTable } from '../../../DST/DSTT007/component';
import { WeeksDropDown } from '../../../DST/DSTT007/component/DSTT007_Detail_Child';
import { ReplayDropDown } from '../../../DST/DSTT007/component/DSTT007_Detail_Child/ReplayDropDown';
import { Button } from '../../../../common';
import { Colors } from '../../../../Colors';
import { useGlobalState } from '../../../../GlobalStateProvider';
export const ReviewClassListStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-self: stretch;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	position: relative;

	.dropdown-container {
		display: flex;
		flex-direction: ${props => (props.$isMobile && props.$isHome ? 'column' : 'row')};
		gap: 20px;
	}

	.live-list-header {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
	}

	.class-review {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h3-font-size, 24px);
		line-height: var(--h3-line-height, 36px);
		font-weight: var(--h3-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.live-list {
		background: var(--color-white, #ffffff);
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		gap: ${props => (props.$isHome ? '20px' : '0px')};
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		width: 100%;
		height: 100%;

		.dropdown-list {
			top: ${props => props.$isHome ? (props.$isMobile ? '53px' : '58px ') : '53px'}!important;
			max-height: ${props => (props.$isHome ? '198px ' : '171px')}!important;
		}
	}

	.review-class {
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		padding: ${props => !props.$isHome && '23px 0'};
		width: 100%;
		height: 100%;
		flex: 1;
	}

	.review-class-list {
		background: var(--color-white, #ffffff);
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 1px;
		padding: 22px;
		display: flex;
		flex-direction: column;
		gap: 6px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		override: hidden;
		position: relative;
	}

	.new {
		background: var(--color-pink, #f46790);
		border-radius: 20px;
		padding: 0px 10px 0px 10px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;

		.text {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: var(--b6-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b6-font-size, 10px);
			line-height: var(--b6-line-height, 20px);
			font-weight: var(--b6-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.dropdown-list {
		top: ${props => (props.$isHome ? '68px' : '106px')};
	}

	.class-title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-weight: var(--h4-font-weight, 700);
		width: 100%;
	}

	.regist-date-wrapper {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: flex-start;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.regist-date {
		color: var(--color-grey-w, #b6bac0);
		text-align: left;
		font-family: var(--b6-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b6-font-size, 10px);
		line-height: var(--b6-line-height, 20px);
		font-weight: var(--b6-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		min-width: 40px;
	}

	.regist-time {
		color: var(--color-grey-l, #83868a);
		text-align: left;
		font-family: var(--b6-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b6-font-size, 10px);
		line-height: var(--b6-line-height, 20px);
		font-weight: var(--b6-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		min-width: 83px;
	}

	.review-class-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	.dropdown-small {
		.dropdown-field {
			border-color: var(--color-silver, #f3f4f8);
		}
	}

	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	.dropdown-container {
		width: 100%;
		gap: 10px;

		.dropdown-mobile-small {
			width: 100%;

			.placeholder {
				color: var(--black, #101010);
			}
		}
		.weeks-dropdown {
			.dropdown-list {
				top: ${props => props.$isHome && '43px !important'};
			}
		}
	}

	.live-list {
		padding: ${props => (props.$isHome ? '5px' : '20px')};
	}
	.review-class-list {
		height: 135px;
	}
	.class-title {
		font-size: var(--h4-font-size, 14px);
		line-height: var(--h4-line-height, 19px);
	}
	.table-item-wrap {
		display: flex;
		gap: 24px;
	}
	.review-class-wrapper {
		gap: 14px;
	}
`;
const tabletStyles = css `
	.live-list {
		padding: ${props => (props.$isHome ? '5px' : '32px 48px 32px 48px')};
	}
	.review-class-list {
		height: 135px;
	}
	.class-title {
		font-size: var(--h4-font-size, 14px);
		line-height: var(--h4-line-height, 19px);
	}
	.review-class-wrapper {
		gap: 16px;
	}
`;
const desktopStyles = css `
	.live-list {
		padding: ${props => (props.$isHome ? '5px' : '32px 48px 32px 48px')};
	}
	.review-class-list {
		height: 160px;
	}
	.class-title {
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
	}
	.review-class-wrapper {
		gap: 20px;
	}
`;
export const ReviewClassList = React.memo(({ isHome = false }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useGlobalState();
    const [totalCount, setTotalCount] = React.useState(0);
    const [showCount, setShowCount] = React.useState(4);
    const [page, setPage] = React.useState(0);
    const [selectDay, setSelectDay] = React.useState('');
    const [selectYear, setSelectYear] = React.useState();
    const [selectMonth, setSelectMonth] = React.useState();
    const [week, setWeek] = React.useState();
    const [nextReview, setNextReview] = React.useState(0);
    const updatedData = (data) => {
        const { totalCount, count, page } = data;
        setTotalCount(totalCount);
        setShowCount(count);
        setPage(page);
    };
    useEffect(() => {
        APP.eventManager.subscribe('SEND_REVIEW_LIST', updatedData);
        return () => {
            APP.eventManager.unsubscribe('SEND_REVIEW_LIST', updatedData);
        };
    }, []);
    // > 버튼 클릭 시
    const nextButton = () => {
        APP.eventManager.publish('UPDATE_REPLY_PAGE', page + 1);
    };
    // < 버튼 클릭 시
    const previousButton = () => {
        APP.eventManager.publish('UPDATE_REPLY_PAGE', page - 1);
    };
    return (React.createElement(ReviewClassListStyled, { "$isHome": isHome, "$isTablet": isTablet, "$isMobile": isMobile, className: "review_class_list" },
        !isHome && (React.createElement("div", { className: "live-list-header" },
            React.createElement("div", { className: "class-review" }, t('conference.conferenceReview')))),
        React.createElement("div", { className: "live-list" },
            React.createElement("div", { className: "dropdown-container" },
                React.createElement(ReplayDropDown, { setDropValue: setSelectDay, setSelectYear: setSelectYear, setSelectMonth: setSelectMonth, selcetMonth: selectMonth, selectYear: selectYear, isHome: isHome }),
                React.createElement(WeeksDropDown, { isDisable: false, defaultWeek: undefined, handlerChange: setWeek, isHome: isHome })),
            React.createElement("div", { className: "review-class" },
                React.createElement("div", { className: "review-class-wrapper" },
                    page > 0 && (React.createElement(Button, { className: `arrow active`, option: {
                            buttonBeforeIcon: {
                                show: true,
                                className: 'arrow',
                                name: 'arrow',
                                size: 24,
                                color: Colors.black,
                            },
                        }, styles: {
                            buttonWrap: {
                                borderRadius: '85px',
                                width: isMobile ? '27px' : '32px',
                                height: isMobile ? '27px' : '32px',
                                backgroundColor: 'white',
                                boxShadow: 'var(--shadow-bg-box-shadow, 0px 2px 6px 0px rgba(140, 143, 148, 0.4))',
                            },
                        }, onClick: previousButton })),
                    React.createElement(ReplyRoomTable, { isHome: isHome, round: week ? parseInt(week) : undefined, year: selectYear ? parseInt(selectYear) : undefined, month: selectMonth ? parseInt(selectMonth) : undefined }),
                    showCount * (page + 1) < totalCount && (React.createElement(Button, { className: `arrow active`, option: {
                            buttonBeforeIcon: {
                                show: true,
                                className: 'arrow-right',
                                name: 'arrow',
                                size: 24,
                                color: Colors.black,
                            },
                        }, styles: {
                            buttonWrap: {
                                borderRadius: '85px',
                                width: isMobile ? '27px' : '32px',
                                height: isMobile ? '27px' : '32px',
                                backgroundColor: 'white',
                                boxShadow: 'var(--shadow-bg-box-shadow, 0px 2px 6px 0px rgba(140, 143, 148, 0.4))',
                            },
                        }, onClick: nextButton })))))));
});
{
    /* <Button
                        className={`arrow active`}
                        option={{
                            buttonBeforeIcon: {
                                show: true,
                                className: 'arrow-right',
                                name: 'arrow',
                                size: 24,
                                color: Colors.black,
                            },
                        }}
                        styles={{
                            buttonWrap: {
                                borderRadius: '85px',
                                width: '32px',
                                height: '32px',
                                backgroundColor: 'white',
                                boxShadow:
                                    'var(--shadow-bg-box-shadow, 0px 2px 6px 0px rgba(140, 143, 148, 0.4))',
                            },
                        }}
                        onClick={() => nextButton()}
                    /> */
}

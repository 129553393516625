import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useGlobalState } from '../../../GlobalStateProvider';
import { Button, InputElement } from '../../../common';
import { deleteAssignmentList, postAssignmentList, postFile, postQuestion, postUpdateAssignmentList, useAssignmentList, useQuestionList, } from '../../../server';
import { MobileTabLayout } from '../../base/layout';
import { SurveyContainer } from '../../base/layout/Survey/SurveyContainer';
import { AttachFile, ClassNameDropDown, DSTP001Styled, EndDate, EvaluationCount, ProgressDate, QuestionType, ScoreContainer, StartDate, SubjectType, SubjectTypeDetail, } from './component/DSTP001_Regist_Child';
import { PublicChoiceBox } from './component/DSTP001_Regist_Child/PublicChoiceBox';
import { default_subject, REFRESH_ASSIGNMENT_LIST } from './constant';
import { checkAttachmentData, checkOMRData, checkSurveyData, createEvalArray, ensureUniqueKeys, getDefaultScore, getQuestionType, } from './function';
// 출제 유형 - 기본 값
const default_questionType = { id: 'select', title: 'common.select' };
export const DSTP001_Regist = React.memo(({ id, isBackOffice = false, isTest = false, isReserve = false, setUpdateCheckList, isTeacher, isModify, isLive = false, }) => {
    const [t] = useTranslation();
    const { classesId, isTablet, isMobile } = useGlobalState();
    const [className, setClassName] = useState(''); // 수업명
    const [assignmentName, setAssignmentName] = useState(''); // 과제명 or 테스트 명
    const [classId, setClassId] = useState(''); // 반 id
    const [startDate, setStartDate] = useState(''); // 시작일
    const [startTime, setStartTime] = useState(''); // 시작 시간
    const [endDate, setEndDate] = useState(''); // 마감일
    const [endTime, setEndTime] = useState(''); // 마감 시간
    const [duration, setDuration] = useState(''); // 시험 시간
    const [questionType, setQuestionType] = useState(default_questionType); // 출제 유형
    const [originalQuestionType, setOriginalQuestionType] = useState(questionType); // 출제 유형
    const [subject, setSubject] = useState(undefined); // 과목
    const [originalSubject, setOriginalSubject] = useState(default_subject); // 과목
    const [evalList, setEvalList] = useState([]); // 평가 항목
    const [scoreBlank, setScoreBlank] = useState(false); // 전체 공백점수
    const [evalItemCnt, setEvalItemCnt] = useState(0); // 평가 항목 개수
    const [attachFileList, setAttachFileList] = useState([]); // 첨부파일
    const [initEvalData, setInitEvalData] = useState([]); // 초기 설정 값을 위한 값 (re render 방지)
    const [evalData, setEvalData] = useState(initEvalData); // 평가 항목 데이터
    const [surveyData, setSurveyData] = useState([]); // 설문 데이터
    const [checkSubjectList, setCheckSubjectList] = useState([]); // 과목 선택 리스트
    const [load, setLoad] = useState(false); // 데이터 로드 여부
    const [hide, setHide] = useState(false); // 공개 여부
    const [item, setItem] = useState(undefined); // 선택된 항목
    const [mobilePage, setMobilePage] = useState(0);
    const deleteMutation = useMutation(deleteAssignmentList);
    const fileMutation = useMutation(postFile);
    const assignmentMutation = useMutation(postAssignmentList);
    const updateAssignmentMutation = useMutation(postUpdateAssignmentList);
    const questionMutation = useMutation(postQuestion);
    // 과제 데이터 불러오기
    const { data, refetch } = useAssignmentList({
        id,
        ...(isBackOffice ? { back_office: true } : { class_id: classesId }),
    });
    // 질문 데이터 불러오기
    const { data: questionData, refetch: questionReftch } = useQuestionList({ test_id: id });
    useEffect(() => {
        if (!id) {
            setItem(undefined);
            setAssignmentName('');
            setClassId('');
            setClassName('');
            setStartDate(moment().format('YYYY-MM-DD'));
            setStartTime(moment().format('HH:mm'));
            setEndDate(moment().format('YYYY-MM-DD'));
            setEndTime(moment().format('HH:mm'));
            setQuestionType(default_questionType);
            setAttachFileList([]);
            setSubject(default_subject);
            setHide(false);
            setOriginalQuestionType(default_questionType);
            setOriginalSubject(default_subject);
            setLoad(true);
            return;
        }
        refetch();
        questionReftch();
    }, [id]);
    // 과제 데이터 불러오기 - 초기 값 설정
    useEffect(() => {
        if (!data || !id)
            return;
        const { found_count, items } = data;
        if (found_count > 0) {
            const item = items[0];
            console.log({ item });
            setItem(item);
            setAssignmentName(item.title);
            setClassId(item.conference_id);
            setClassName(item.conference_name);
            setStartDate(moment(item.startline).format('YYYY-MM-DD'));
            setStartTime(moment(item.startline).format('HH:mm'));
            setEndDate(moment(item.deadline).format('YYYY-MM-DD'));
            setEndTime(moment(item.deadline).format('HH:mm'));
            setDuration(item.duration);
            const questionType = getQuestionType(item.type);
            setQuestionType({ id: questionType, title: `assignment.${questionType}` });
            setOriginalQuestionType({ id: questionType, title: `assignment.${questionType}` });
            setAttachFileList(item.attachments);
            console.log({ questionType });
            setSubject(questionType === 'OMR'
                ? { id: item.subject, title: `assignment.${item.subject}` }
                : default_subject);
            setOriginalSubject({ id: item.subject, title: `assignment.${item.subject}` });
            setHide(item.status === 'hide' ? true : false);
            setLoad(true);
        }
    }, [data]);
    // 현재 질문에 선택된 평가항목 기본값 설정
    useEffect(() => {
        if (!questionData)
            return;
        const { total_count, items } = questionData;
        if (total_count > 0) {
            // 중복 제거된 evalList 생성
            const evalList = items
                .map(item => item.subject_item)
                .reduce((acc, current) => {
                const isDuplicate = acc.find(item => item === current);
                if (!isDuplicate) {
                    acc.push(current);
                }
                return acc;
            }, []);
            setCheckSubjectList(evalList); // 중복이 제거된 evalList 상태로 설정
        }
    }, [questionData]);
    // 평가 항목 데이터 불러오기 - 초기 값 설정
    useEffect(() => {
        if (questionData) {
            let originEvalData = [];
            const { total_count, items } = questionData;
            if (total_count > 0) {
                items?.forEach(item => {
                    const evalTableProps = {
                        id: item.number,
                        type: item.type,
                        score: item.point,
                        answer: item.answer,
                        examples: item.examples,
                    };
                    const existingDataIndex = originEvalData.findIndex(dataItem => dataItem.key === item.subject_item);
                    if (existingDataIndex !== -1) {
                        // Append evalTableProps to the existing array
                        originEvalData[existingDataIndex].data = [
                            ...originEvalData[existingDataIndex].data,
                            evalTableProps,
                        ];
                    }
                    else {
                        // Add new entry if subject_item is not in data
                        originEvalData.push({
                            key: item.subject_item,
                            data: [evalTableProps],
                        });
                    }
                });
            }
            if (questionType.id === 'OMR-free') {
                setEvalItemCnt(originEvalData.length);
            }
            // Update initEvalData without duplicate updates
            if (JSON.stringify(initEvalData) !== JSON.stringify(originEvalData)) {
                setInitEvalData(originEvalData);
            }
        }
    }, [questionData, originalQuestionType, originalSubject]);
    // 평가 항목 데이터 불러오기 ( 자유형일때 세팅 , 기본형은 SubjectTypeDetail.tsx에서 세팅 )
    useEffect(() => {
        if (item?.type === 'OMR (자유형)' && originalQuestionType.id === questionType.id) {
            let evalList = [];
            checkSubjectList.map((item, index) => {
                evalList.push({
                    id: item,
                    title: item,
                    checked: true,
                });
            });
            setEvalList(evalList);
        }
    }, [item?.type, questionType, checkSubjectList]);
    // 평가 항목 데이터 불러오기 - 과목 및 평가 항목 변경 시
    useEffect(() => {
        let data = [];
        evalList?.forEach(evalItem => {
            if (evalItem.checked) {
                const isExistevalData = evalData.find(dataItem => dataItem.key === evalItem.title)
                    ? evalData.find(dataItem => dataItem.key === evalItem.title)
                    : originalQuestionType.id === questionType.id
                        ? initEvalData.find(item => item.key === evalItem.title)
                        : undefined;
                if (isExistevalData) {
                    // 수정된 데이터를 evalData에 적용
                    data.push(isExistevalData);
                }
                else {
                    const defaultScore = getDefaultScore(subject.id, evalItem.title);
                    const newData = createEvalArray(defaultScore, scoreBlank);
                    data.push({ data: newData, key: evalItem.title });
                }
            }
        });
        const test = ensureUniqueKeys(data);
        data = test
            .filter(item => {
            return evalList?.some(i => i.checked && item.key === i.title);
        })
            .map(item => {
            return {
                ...item,
                data: item.data
                    .map(i => {
                    return {
                        ...i,
                        score: scoreBlank ? 0 : i.score,
                    };
                })
                    .sort((a, b) => a.id - b.id),
            };
        })
            .sort((a, b) => {
            const subjectOrder = [
                '공통',
                '선택 - 언어와매체',
                '선택 - 화법과 작문',
                '듣기',
                '읽기',
                '선택 - 미적분',
                '선택 - 확률과 통계',
                '선택 - 기하',
            ];
            return (
            // @ts-ignore
            subjectOrder.indexOf(a.key) - subjectOrder.indexOf(b.key));
        });
        if (questionType.id === 'OMR-free') {
            setEvalItemCnt(data.length);
        }
        if (_.isEqual(data, evalData))
            return;
        setEvalData(data);
    }, [evalList, questionType, subject?.id, initEvalData]);
    /**
     *
     * 출제 유형이 변경되는 경우
     *
     * OMR  -> 1. 과목 선택 생성
     *      -> 2. 평가 항목 초기화
     *
     * other -> 1. 과목 선택 제거
     *          -> 2. 평가 항목 초기화
     */
    useEffect(() => {
        if (id && !item)
            return;
        if (questionType?.id === originalQuestionType?.id) {
            if (id) {
                if (subject?.id === originalSubject?.id) {
                    setAttachFileList(item?.attachments || []);
                    setSubject({ id: item.subject, title: `assignment.${item.subject}` });
                }
            }
            else {
                if (questionType.id === 'OMR')
                    setSubject(default_subject);
                if (questionType.id === 'OMR-free')
                    setSubject({ id: 'select', title: 'assignment.select' });
            }
        }
        else {
            setAttachFileList([]);
            setEvalData([]);
            setEvalList([]);
            setEvalItemCnt(0);
        }
    }, [
        item?.attachments,
        questionType?.id,
        subject?.id,
        originalSubject?.id,
        originalQuestionType?.id,
    ]);
    useEffect(() => {
        setEvalData((prevData) => {
            // 변경된 데이터를 만듭니다.
            const newData = prevData.map(item => {
                return {
                    ...item,
                    data: item.data.map(i => {
                        // 점수가 0점인 경우 기본값을 찾습니다.
                        if (!scoreBlank) {
                            const scoreTest = getDefaultScore(subject.id, item.key);
                            const newData = createEvalArray(scoreTest, scoreBlank);
                            const test = newData.find(j => j.id === i.id) || {};
                            return {
                                ...i,
                                score: scoreBlank ? 0 : test?.score,
                            };
                        }
                        return {
                            ...i,
                            score: scoreBlank ? 0 : i.score,
                        };
                    }),
                };
            });
            return newData;
        });
    }, [scoreBlank]);
    // (onClick) 과제 불러오기
    const loadAssignmentName = () => {
        alert('과제명 불러오기');
    };
    // (onClick) 바로 시작하기
    const updateStartNow = (changeDate) => {
        if (changeDate === true) {
            const now = moment().add(3, 'minutes');
            const endDate = moment(now).add(7, 'days');
            setStartDate(now.format('YYYY-MM-DD'));
            setStartTime(now.format('HH:mm'));
            setEndDate(endDate.format('YYYY-MM-DD'));
            setEndTime(endDate.format('HH:mm'));
        }
        else {
            setStartDate('');
            setStartTime('');
            setEndDate('');
            setEndTime('');
        }
    };
    // (onClick) 팝업 닫기
    const cancelPopup = () => {
        APP.eventManager.publish('TOGGLE_POPUP');
    };
    // (onClick) 과제 제출
    const submitAssignment = async (index) => {
        let test = [];
        APP.eventManager.publish('SHOW_LOADING', true);
        APP.eventManager.publish('CHECK_ERROR');
        try {
            // 1. 과제 제목이 없는 경우 에러 처리
            if (index === undefined ? !assignmentName : index >= 0 && !assignmentName) {
                throw new Error(t('error.noAssignmentName', {
                    name: isTest ? t('assignment.testname') : t('assignment.subjectName'),
                }));
            }
            // 1. 시작일 또는 시간이 없는지 확인
            const checkStart = !startDate || !startTime;
            if (index === undefined ? checkStart : index >= 0 && checkStart) {
                throw new Error(t('error.noStartDate'));
            }
            // 2. 마감일 또는 시간이 없는지 확인
            const checkEnd = !endDate || !endTime;
            if (index === undefined ? checkEnd : index >= 0 && checkEnd) {
                throw new Error(t('error.noEndDate'));
            }
            // 미리 moment 객체를 생성하여 반복되는 작업 제거
            const startDateTime = moment(`${startDate} ${startTime}`);
            const endDateTime = moment(`${endDate} ${endTime}`);
            // 3. 시작일이 현재 시간보다 이전인지 확인
            const isCheckStartTime = !isTest && !isBackOffice && startDateTime.isBefore(moment());
            if (index === undefined ? isCheckStartTime : index >= 0 && isCheckStartTime) {
                throw new Error(t('error.lateNow'));
            }
            const isCheckTestStartTime = isTest && !isBackOffice && startDateTime.isBefore(moment().startOf('day'));
            if (index === undefined ? isCheckTestStartTime : index >= 0 && isCheckTestStartTime) {
                throw new Error(t('error.lateNow'));
            }
            // 4. 시작일이 마감일보다 늦은지 확인
            const isCheckEndTime = !isTest && !isBackOffice && startDateTime.isAfter(endDateTime);
            if (index === undefined ? isCheckEndTime : index >= 0 && isCheckEndTime) {
                throw new Error(t('error.lateEnd'));
            }
            // 5. 테스트 모드일때, 풀이 시간이 0보다 커야함 (0분 이상)
            const isCheckDuration = isTest && !isNaN(Number(duration)) && Number(duration) < 1;
            if (index === undefined ? isCheckDuration : index >= 0 && isCheckDuration) {
                throw new Error(t('error.noDuration'));
            }
            // 6. 출제유형 선택 여부 확인
            if (index === undefined
                ? questionType.id === 'select'
                : index >= 0 && questionType.id === 'select') {
                throw new Error('출제 유형을 선택해주세요');
            }
            // 7. 과목 선택 여부 확인
            const isChoiceSubject = questionType.id === 'OMR' && subject.id === 'select';
            if (index === undefined ? isChoiceSubject : index >= 1 && isChoiceSubject) {
                throw new Error('과목을 선택해주세요');
            }
            // 설문, 첨부 파일, OMR 데이터를 검증하는 함수 호출
            if (index >= 1 || index === undefined) {
                if (questionType.id === 'survey') {
                    test = await checkSurveyData(surveyData);
                }
                else if (questionType.id === 'attachment') {
                    test = checkAttachmentData(assignmentName, attachFileList);
                }
                else {
                    // 평가 항목 등록 여부 확인
                    if (!evalData || evalData.length === 0)
                        throw new Error('평가 항목은 한문제 이상 등록되어야합니다');
                    else if (index === undefined)
                        test = checkOMRData(evalData, subject);
                }
            }
            if (index === undefined) {
                // 평가 항목 검증
                if (test.length === 0) {
                    throw new Error('평가 항목은 한문제 이상 등록되어야합니다');
                }
                let convertAttachFileList = [];
                // 첨부 파일이 있는 경우
                if (attachFileList && attachFileList.length > 0) {
                    try {
                        const alredyFile = attachFileList.filter(file => typeof file === 'string');
                        const filterFile = attachFileList.filter(file => file instanceof File);
                        // 첨부 파일 업로드를 비동기로 실행하고 완료될 때까지 기다립니다.
                        if (filterFile && filterFile.length > 0) {
                            const data = await fileMutation.mutateAsync(filterFile);
                            convertAttachFileList = [...data.result.link, ...alredyFile];
                        }
                        else {
                            convertAttachFileList = alredyFile;
                        }
                    }
                    catch (error) {
                        console.error('Failed to upload files:', error);
                        return; // 에러가 발생하면 함수를 종료합니다.
                    }
                }
                // 제출할 데이터를 구성합니다/
                const data = {
                    assignment: !isTest,
                    attachments: convertAttachFileList,
                    duration: isTest ? Number(duration) : undefined,
                    ...(isBackOffice
                        ? {}
                        : {
                            deadline: moment(isTest ? `${startDate} 23:59:59` : `${endDate} ${endTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                            startline: isTest
                                ? moment(`${startDate} ${startTime}`)
                                    .add(1, 'hours')
                                    .format('YYYY-MM-DDTHH:mm:ssZ')
                                : moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                            class_id: classesId,
                        }),
                    title: assignmentName,
                    type: t(`assignment.${questionType.id}`),
                    subject: subject.id,
                    ...(isTest
                        ? {
                            conference_id: isTest
                                ? isReserve || (classId && classId === 'select')
                                    ? ''
                                    : classId
                                : '',
                        }
                        : {}),
                    back_office: isBackOffice,
                    ...(isBackOffice ? { hide } : {}),
                };
                // 과제 등록을 비동기로 실행하고 완료될 때까지 기다립니다.
                let assignmentId = undefined;
                if (id) {
                    const responseData = await updateAssignmentMutation.mutateAsync({
                        id,
                        props: data,
                    });
                    assignmentId = id;
                }
                else {
                    const responseData = await assignmentMutation.mutateAsync(data);
                    assignmentId = responseData.result.id;
                }
                if (assignmentId) {
                    try {
                        await questionMutation.mutateAsync({
                            id: assignmentId,
                            props: test,
                        });
                        if (isReserve) {
                            // @ts-ignore
                            setUpdateCheckList((prevCheckList) => {
                                return [...prevCheckList, assignmentId];
                            });
                        }
                        else {
                            // 성공적으로 질문 데이터를 등록하면 이벤트를 발행하고 팝업을 취소합니다.
                            APP.eventManager.publish(REFRESH_ASSIGNMENT_LIST);
                        }
                    }
                    catch {
                        if (!id) {
                            await deleteMutation.mutateAsync(id);
                        }
                        else {
                            // 제출할 데이터를 구성합니다/
                            const data = {
                                assignment: !isTest,
                                attachments: item.attachments,
                                duration: item.duration,
                                ...(isBackOffice
                                    ? {}
                                    : {
                                        deadline: moment(item.deadline).format('YYYY-MM-DDTHH:mm:ssZ'),
                                        startline: moment(item.startline).format('YYYY-MM-DDTHH:mm:ssZ'),
                                        class_id: classesId,
                                    }),
                                title: item.title,
                                type: item.type,
                                subject: item.subject,
                                ...(isTest
                                    ? {
                                        conference_id: item.conference_id,
                                    }
                                    : {}),
                                back_office: isBackOffice,
                                ...(isBackOffice ? { hide } : {}),
                            };
                            await updateAssignmentMutation.mutateAsync({
                                id,
                                props: data,
                            });
                        }
                    }
                    finally {
                        if (isMobile) {
                            if (isReserve) {
                                cancelPopup();
                            }
                            else {
                                APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                                    menuDetail: 'list',
                                    id: undefined,
                                });
                            }
                        }
                        else {
                            cancelPopup();
                        }
                    }
                }
            }
            else {
                setMobilePage(mobilePage + 1);
            }
        }
        catch (err) {
            if (err === '진행 예정이 아닌 설문입니다.') {
                alert(t('error.alreadyStart'));
                APP.eventManager.publish(REFRESH_ASSIGNMENT_LIST);
            }
            else {
                let errorMessage = err.message ? err.message : err;
                let cleanedMessage = errorMessage.replace('Error: ', '');
                alert(cleanedMessage);
            }
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    /**
     * Render 출제 유형
     * (과제명, 반, 시작일, 마감일, 시험 시간)
     */
    const renderAssignmentInfo = useMemo(() => (React.createElement("div", { key: "assignmentInfo", className: "assignment-info-wrap" },
        isTest && !isReserve && !isBackOffice && (React.createElement(ClassNameDropDown, { defaultClassId: item?.conference_id, classId: classId, className: className, setClassId: setClassId })),
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, isTest ? t('assignment.testname') : t('assignment.subjectName')),
            React.createElement("div", { className: "fext-field" },
                React.createElement(InputElement, { type: "text", value: assignmentName, onChange: setAssignmentName }))),
        isTest ? (isMobile && isTeacher ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label", style: { minWidth: !isMobile && 'unset' } }, t('assignment.duration')),
                React.createElement("div", { className: "text-line" },
                    React.createElement(InputElement, { type: "number", min: 0, value: duration, onChange: setDuration }),
                    t('assignment.minute'))),
            React.createElement(ProgressDate, { startDate: startDate, startTime: startTime, setStartDate: setStartDate, setStartTime: setStartTime }))) : (React.createElement("div", { className: "field-short", style: {
                flex: isTablet && 'unset',
                flexDirection: isMobile && isLive ? 'column' : 'row',
            } },
            !isBackOffice && (React.createElement(ProgressDate, { startDate: startDate, startTime: startTime, setStartDate: setStartDate, setStartTime: setStartTime })),
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, t('assignment.duration')),
                React.createElement("div", { className: "text-line" },
                    React.createElement(InputElement, { type: "number", min: 0, value: duration, onChange: setDuration }),
                    t('assignment.minute')))))) : (!isBackOffice && (React.createElement(React.Fragment, null,
            React.createElement(StartDate, { startDate: startDate, startTime: startTime, setStartDate: setStartDate, setStartTime: setStartTime, updateStartNow: updateStartNow }),
            React.createElement(EndDate, { endDate: endDate, endTime: endTime, setEndDate: setEndDate, setEndTime: setEndTime })))))), [classId, className, duration, assignmentName, startDate, startTime, endDate, endTime]);
    /**
     * Render 출제 유형
     */
    const renderQuestionType = useMemo(() => (React.createElement(QuestionType, { key: "questionType", questionType: questionType, setQuestionType: setQuestionType, setSubject: setSubject, isModify: isModify })), [questionType, isModify]);
    /**
     * Render 과목 선택
     */
    const renderSubjectType = useMemo(() => (React.createElement(SubjectType, { key: "subjectType", questionTypeId: questionType?.id, subject: subject, setSubject: setSubject })), [questionType, subject, isModify]);
    /**
     * Render
     * OMR 기본형 - 과목 상세 정보
     * OMR 자유형 - 평가 항목 개수
     * OMR 기본형, OMR 자유형, 파일첨부형 - 첨부파일
     */
    const renderSubjectInfo = useMemo(() => (React.createElement("div", { key: "subjectInfo", className: "subject-info-wrap" },
        questionType.id === 'OMR' && (React.createElement(SubjectTypeDetail, { checkSubjectList: checkSubjectList, questionTypeId: questionType?.id, originalSubjectId: originalSubject?.id, subjectId: subject?.id, scoreBlank: scoreBlank, evalList: evalList, setEvalList: setEvalList, setScoreBlank: setScoreBlank })),
        questionType.id === 'OMR-free' && (React.createElement(EvaluationCount, { evalItemCnt: evalItemCnt, evalData: evalData, subjectId: subject?.id, setEvalItemCnt: setEvalItemCnt, setEvalData: setEvalData })),
        questionType.id !== 'select' && questionType.id !== 'survey' && (React.createElement(AttachFile, { isTeacher: isTeacher, showRegist: true, isRegist: true, isLive: isLive, attachFileList: attachFileList, setAttachFileList: setAttachFileList })))), [
        id,
        questionType,
        subject,
        scoreBlank,
        evalItemCnt,
        attachFileList,
        evalData,
        initEvalData,
        isModify,
        checkSubjectList,
        evalList,
    ]);
    /**
     * Render 평가 항목
     * OMR 기본형, OMR 자유형 - 평가 항목
     * 설문형 - 설문 항목
     */
    const renderTestItem = useMemo(() => (React.createElement("div", { key: 'testItem', className: "test-item", style: { width: '100%', flex: '1', display: 'flex' } },
        (questionType.id === 'OMR' || questionType.id === 'OMR-free') && (React.createElement(ScoreContainer, { isDefault: questionType.id === 'OMR', subject: subject?.title, evalData: evalData, initEvalData: initEvalData, setEvalData: setEvalData, isModify: isModify })),
        questionType.id === 'survey' && (React.createElement(SurveyContainer, { questionId: questionType.id, item: item, test_id: item?.id, isRegist: true, handlerUpdateSurveyData: setSurveyData })))), [questionType, subject, evalList, evalData, initEvalData, surveyData, isModify, item]);
    if (!load)
        return null;
    if (isMobile) {
        return (React.createElement(DSTP001Styled, { "$isTablet": isTablet, "$isMobile": isMobile || isReserve, "$isLive": isLive },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        component: [renderAssignmentInfo, renderQuestionType],
                        styles: {
                            gap: isTest ? '20px' : '12px',
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    },
                    questionType &&
                        questionType.id !== 'survey' && {
                        id: 2,
                        component: [renderSubjectType, renderSubjectInfo],
                        styles: {
                            gap: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    },
                    questionType &&
                        questionType.id !== 'attachment' &&
                        subject && { id: 3, component: [renderTestItem] },
                ].filter(i => i), showNav: isReserve ? false : true, showPageButton: true, previousPageList: {
                    title: isTest
                        ? isModify
                            ? t('assignment.testModify')
                            : t('assignment.registTest')
                        : isModify
                            ? t('assignment.modify')
                            : t('assignment.registAssignment'),
                    styles: { fontFamily: 'NotoSansKr-Bold, sans-serif' },
                }, isSubmit: true, submitAssignment: submitAssignment, mobilePage: mobilePage, setMobilePage: setMobilePage })));
    }
    return (React.createElement(DSTP001Styled, { "$isTablet": isTablet, "$isMobile": isMobile, "$isLive": isLive },
        React.createElement("div", { className: "task-info-container-wrapper", style: {
                minHeight: (questionType.id === 'select' || questionType.id === 'attachment') &&
                    (isMobile ? 'auto' : isTablet ? 'unset' : '500px'),
            } },
            React.createElement("div", { className: "task-info-container" },
                renderAssignmentInfo,
                React.createElement("div", { className: "field-short", style: {
                        flex: isTablet && 'unset',
                        flexDirection: isMobile && isLive ? 'column' : 'row',
                    } },
                    renderQuestionType,
                    renderSubjectType),
                renderSubjectInfo,
                isBackOffice && (React.createElement(PublicChoiceBox, { defaultHide: item?.status === 'hide' ? true : false, setHide: setHide }))),
            renderTestItem),
        React.createElement("div", { className: "btn-container", style: { justifyContent: 'center' } },
            React.createElement(Button, { text: "common.cancel", className: "btn_white xmd", onClick: cancelPopup }),
            React.createElement(Button, { text: "common.okay", className: "btn_default xmd", onClick: e => submitAssignment() }))));
});

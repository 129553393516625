import moment from 'moment';
export const DEFAULT_KLASS_ITEM = {
    id: '',
    creation_time: moment().toISOString(),
    status: '',
    tenant_id: '',
    identifier: '',
    title: '',
    subject: '',
    startline: moment().toISOString(),
    deadline: moment().toISOString(),
    capacity: 0,
    hidden_menus: [],
    members: [],
    managers: [],
    host: '',
    members_name: [],
    managers_name: [],
    host_name: '',
};

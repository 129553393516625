import React, { forwardRef, useEffect, useRef, useState } from 'react';
const InputElement = forwardRef(({ id, className, type, style, value, defaultValue, placeholder, isEnterPrevent = false, textarea = false, isDisable = false, isNumber = false, min, max, tabIndex, isAutoHeight = false, maxNumLen, onSubmit, onChange, onKeyDown, onFocus, onBlur, }, ref) => {
    const [height, setHeight] = useState('auto');
    const [content, setContent] = useState(value || defaultValue || '');
    const textareaRef = useRef(null);
    const autoGrow = element => {
        element.style.height = 'auto';
        element.style.height = `${element.scrollHeight}px`;
    };
    useEffect(() => {
        if (textarea) {
            autoGrow(textareaRef.current);
        }
    }, [content, textarea]);
    const onKeyUpdateInput = (e) => {
        e.stopPropagation();
        if (isEnterPrevent) {
            if ((e.key === 'Space' || e.key === 'Enter' || e.key === 'NumpadEnter') &&
                !e.shiftKey) {
                e.preventDefault();
                onSubmit && onSubmit();
                return;
            }
        }
        if (onKeyDown) {
            // @ts-ignore
            onKeyDown(e.target?.value);
        }
    };
    const updateInputValue = (e) => {
        const { value } = e.target;
        if (isNumber || type === 'number') {
            if (value &&
                (!/^-?\d+$/.test(value) ||
                    (maxNumLen && value.replace('-', '').length > maxNumLen))) {
                e.preventDefault();
                // 음수 기호를 유지하면서 숫자만 남기고 최대 maxNumLen 자리까지만 유지
                e.target.value = value
                    .replace(/[^-\d]/g, '')
                    .slice(0, value.startsWith('-') ? maxNumLen + 1 : maxNumLen);
                return;
            }
        }
        setContent(value);
        onChange && onChange(value);
    };
    return textarea ? (React.createElement("textarea", { ref: textareaRef, id: id, className: className, value: value, defaultValue: defaultValue, placeholder: placeholder, style: { ...style, ...(isAutoHeight ? { height } : {}) }, disabled: isDisable, spellCheck: false, onKeyDown: onKeyUpdateInput, onChange: updateInputValue, onFocus: onFocus, onBlur: onBlur, readOnly: isDisable, maxLength: max ? Number(max) : undefined })) : (React.createElement("input", { ref: ref, id: id, className: className, type: type, value: value, defaultValue: defaultValue, placeholder: placeholder, style: style, disabled: isDisable, spellCheck: false, onKeyDown: onKeyUpdateInput, onChange: updateInputValue, onFocus: onFocus, onBlur: onBlur, min: min, tabIndex: tabIndex, readOnly: isDisable, max: type === 'date' ? '9999-12-31' : undefined, maxLength: max ? Number(max) : undefined }));
});
export default InputElement;

import { BACK_MENUT_LIST, CLASS_MENUT_LIST, SERVICE_MENUT_LIST, STATISTICS_MENUT_LIST, } from './constant';
export const getMenuDetailData = (menu, menuDetail) => {
    switch (menu) {
        case 'serviceManagment':
            return SERVICE_MENUT_LIST.includes(menuDetail) ? menuDetail : SERVICE_MENUT_LIST[0];
        case 'classManagment':
            return CLASS_MENUT_LIST.includes(menuDetail) ? menuDetail : CLASS_MENUT_LIST[0];
        case 'backOffice':
            return BACK_MENUT_LIST.includes(menuDetail) ? menuDetail : BACK_MENUT_LIST[0];
        case 'statistics':
            return STATISTICS_MENUT_LIST.includes(menuDetail)
                ? menuDetail
                : STATISTICS_MENUT_LIST[0];
    }
    return '';
};

import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { DropDown } from '../../../../../common';
export const ManagerDropDown = React.memo(({ dropdownList, defaultValue, excludeList, handlerUpdateManager, isDisable, }) => {
    const [value, setValue] = useState(defaultValue);
    const { isMobile } = useGlobalState();
    useEffect(() => {
        if (value) {
            handlerUpdateManager(value.id);
        }
    }, [value]);
    return (React.createElement(DropDown, { className: `dropdown-${isMobile ? 'mini' : 'live'}`, list: dropdownList, excludeList: excludeList, value: value, setDropValue: setValue, styles: {
            itemWrap: {
                padding: '8px 10px',
                top: '45px',
                maxHeight: isMobile ? '200px' : '230px',
            },
        }, isDisable: isDisable }));
});

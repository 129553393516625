import React, { useEffect } from 'react';
import { DropDown } from '../../../../../common';
// 참여 권한 선택
const liveModeList = [
    { id: 'admin', title: 'conference.admin' },
    { id: 'host', title: 'conference.host' },
    { id: 'manager', title: 'conference.manager' },
    { id: 'participant', title: 'conference.participant' },
];
const default_mode = { id: 'participant', title: 'conference.participant' };
export const MemberRoleDropDown = React.memo(({ isDisable = false, defaultRole, handlerUpdateOption, }) => {
    const [dropValue, setDropValue] = React.useState(default_mode);
    useEffect(() => {
        // defaultRole이 변경될 때만 dropValue를 설정
        const drop_item = liveModeList.find(item => item.id === defaultRole) || default_mode;
        setDropValue(drop_item);
    }, [defaultRole]);
    useEffect(() => {
        // dropValue가 defaultRole과 다른 경우에만 handlerUpdateOption을 호출
        if (dropValue.id !== defaultRole) {
            handlerUpdateOption(dropValue);
        }
    }, [dropValue, defaultRole, handlerUpdateOption]);
    return (React.createElement(DropDown, { isDisable: isDisable, className: `dropdown-live ${isDisable ? 'disable' : ''}`, value: dropValue, list: liveModeList, option: {
            downIcon: {
                show: !isDisable,
            },
        }, setDropValue: setDropValue }));
});

import { throttle } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Button } from '../../../common';
import { deleteKlassItem, getClassesList, modifyKlassItem, postKlassItem, postKlassMenu, useClassesList, } from '../../../server/classes';
import { getUserId } from '../../base/account';
import { MembersTable } from '../common';
import { CheckboxInput, DurationDateInput, TextInput } from '../ui';
import { DropDownInput } from '../ui/DropDownInput';
import { KLASS_MENU_LIST } from './constant';
import { DEFAULT_KLASS_ITEM } from './types';
export const KlassItem = React.memo(({ id, isModify }) => {
    const user_id = useSelector((state) => getUserId(state));
    const [item, setItem] = React.useState(DEFAULT_KLASS_ITEM);
    const deleteKlassMutation = useMutation(deleteKlassItem);
    const modifyKlassMutation = useMutation(modifyKlassItem);
    const registKlassMutation = useMutation(postKlassItem);
    const registKlassMenuMutation = useMutation(postKlassMenu);
    // 사이트 명 중복 체크 여부
    const [isSiteNameCheck, setIsSiteNameCheck] = React.useState(id ? true : false);
    // 사이트명
    const [siteName, setSiteName] = React.useState('');
    // 클래스명
    const [title, setTitle] = React.useState('');
    // 과목명
    const [subject, setSubject] = React.useState('');
    // 시작일
    const [startDate, setStartDate] = React.useState('');
    // 종료일
    const [endDate, setEndDate] = React.useState('');
    // 상태
    const [status, setStatus] = React.useState('');
    // 메뉴 숨김
    const [hiddenMenus, setHiddenMenus] = React.useState([]);
    const { data, refetch } = useClassesList({ id, with_name: true });
    const refetchList = throttle(() => refetch(), 1000);
    // (onClick) 취소 버튼 클릭 시 이전 페이지로 이동
    const cancelMenu = () => {
        APP.eventManager.publish('PREVIOUS_MENU');
    };
    // (onClick) 버튼 클릭 시 회사 정보 삭제
    const handlerDelete = async () => {
        if (!id)
            return;
        APP.eventManager.publish('SHOW_LOADING', true);
        try {
            await deleteKlassMutation.mutateAsync(id);
            // 상세 페이지 - 수정 페이지로 이동
            APP.eventManager.publish('CHANGE_MENU', {
                showDepth: 'list',
                props: undefined,
            });
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    // (onClick) 버튼 클릭 시 회사 정보 등록 및 수정
    const handlerSubmit = async () => {
        APP.eventManager.publish('SHOW_LOADING', true);
        try {
            if (id) {
                if (isModify) {
                    if (item.identifier !== siteName && !isSiteNameCheck) {
                        alert('사이트명 중복 체크를 해주세요.');
                        return;
                    }
                    await modifyKlassMutation.mutateAsync({
                        class_id: id,
                        props: {
                            capacity: 1,
                            deadline: moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
                            startline: moment(startDate)
                                .startOf('day')
                                .format('YYYY-MM-DDTHH:mm:ssZ'),
                            subject: subject,
                            title: title,
                            teacher_id: user_id,
                        },
                    });
                    await registKlassMenuMutation.mutateAsync({
                        class_id: id,
                        props: {
                            menus: hiddenMenus,
                        },
                    });
                    // 상세 페이지 - 수정 페이지로 이동
                    APP.eventManager.publish('CHANGE_MENU', {
                        showDepth: 'detail',
                        props: { id },
                    });
                }
                else {
                    // 상세 페이지 - 수정 페이지로 이동
                    APP.eventManager.publish('CHANGE_MENU', {
                        showDepth: 'modify',
                        props: { id },
                    });
                }
            }
            else {
                const response = await registKlassMutation.mutateAsync({
                    capacity: 1,
                    deadline: moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
                    startline: moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
                    subject: subject,
                    title: title,
                    teacher_id: user_id,
                    identifier: siteName,
                });
                const class_id = response.result.id;
                await registKlassMenuMutation.mutateAsync({
                    class_id: class_id,
                    props: {
                        menus: hiddenMenus,
                    },
                });
                // 상세 페이지 - 수정 페이지로 이동
                APP.eventManager.publish('CHANGE_MENU', {
                    showDepth: 'list',
                    props: undefined,
                });
            }
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    React.useEffect(() => {
        id && refetchList();
    }, [id]);
    React.useEffect(() => {
        if (!item)
            return;
        setSiteName(item.identifier);
        setTitle(item.title);
        setSubject(item.subject);
        setStartDate(item.startline);
        setEndDate(item.deadline);
        setStatus(item.status);
        setHiddenMenus(item.hidden_menus);
        // setMemberIds(item.members);
    }, [item]);
    React.useEffect(() => {
        if (data && id) {
            const { total_count, items, found_count } = data;
            if (total_count > 0 && found_count === 0) {
            }
            else {
                if (items.length > 0) {
                    const item = items[0];
                    setItem(item);
                    return;
                }
            }
        }
        setItem(DEFAULT_KLASS_ITEM);
    }, [data]);
    return (React.createElement("div", { className: "regist_container" },
        React.createElement("form", { onSubmit: e => e.preventDefault() },
            React.createElement("div", { className: "regist_box_wrapper half float-top" },
                React.createElement("div", { className: "regist_box" },
                    React.createElement(TextInput, { className: `${isModify && !id && siteName
                            ? isSiteNameCheck
                                ? 'ok'
                                : 'error'
                            : ''}`, title: "\uC0AC\uC774\uD2B8\uBA85", defaultValue: item.identifier, handlerUpdate: (name) => {
                            setSiteName(name);
                            setIsSiteNameCheck(false);
                        }, disabled: !isModify || (isModify && id) ? true : false, noShowButtonArea: !isModify, buttonOption: isModify &&
                            !id && {
                            title: '중복체크',
                            className: 'btn-semi-round btn-blue btn-xmd',
                            handlerClick: () => {
                                if (item.identifier === siteName) {
                                    alert('사용 가능한 사이트명입니다.');
                                    setIsSiteNameCheck(true);
                                    return;
                                }
                                getClassesList({ identifier: siteName }).then((response) => {
                                    console.log(response);
                                    const { total_count, items, found_count } = response.result;
                                    if (total_count > 0 && found_count > 0) {
                                        alert('이미 존재하는 사이트명입니다.');
                                        setIsSiteNameCheck(false);
                                    }
                                    else {
                                        alert('사용 가능한 사이트명입니다.');
                                        setIsSiteNameCheck(true);
                                    }
                                });
                            },
                        } }),
                    React.createElement(TextInput, { title: "\uD074\uB798\uC2A4\uBA85", defaultValue: item.title, handlerUpdate: setTitle, disabled: !isModify, noShowButtonArea: !isModify }),
                    React.createElement(TextInput, { title: "\uACFC\uBAA9\uBA85", defaultValue: item.subject, handlerUpdate: setSubject, disabled: !isModify, noShowButtonArea: !isModify }),
                    React.createElement(DurationDateInput, { type: "date", title: "\uAE30\uAC04 \uC124\uC815", defaultFirstValue: moment(item.startline).format('YYYY-MM-DD'), defaultSecondValue: moment(item.deadline).format('YYYY-MM-DD'), handlerFirstUpdate: setStartDate, handlerSecondUpdate: setEndDate, disabled: !isModify, noShowButtonArea: !isModify }),
                    React.createElement(DropDownInput, { title: "\uC0C1\uD0DC", disabled: !isModify, noShowButtonArea: !isModify, noSelectOption: {
                            show: false,
                            id: null,
                            title: '선택 안함',
                        }, defaultValue: item.status, handlerUpdate: setStatus, dropdownList: [
                            {
                                id: 'created',
                                value: '사용',
                            },
                            {
                                id: 'deleted',
                                value: '미사용',
                            },
                        ] }),
                    React.createElement(CheckboxInput, { className: "hidden_menus", title: "\uBA54\uB274 \uAD00\uB9AC", disabled: !isModify, noShowButtonArea: !isModify, defaultValues: KLASS_MENU_LIST.filter(menu => !hiddenMenus.includes(menu.id)).map(menu => menu.id), handlerUpdate: (selectedMenus) => {
                            const updatedHiddenMenus = KLASS_MENU_LIST.filter(menu => !selectedMenus.includes(menu.id)).map(menu => menu.id);
                            setHiddenMenus(updatedHiddenMenus);
                        }, options: KLASS_MENU_LIST }),
                    React.createElement("div", { className: "btn-container", style: { width: '100%' } },
                        !!id && !isModify && (React.createElement(Button, { text: "\uC0AD\uC81C", className: "btn-default-round btn-peach btn-xmd", onClick: handlerDelete })),
                        React.createElement(Button, { text: !id ? '등록' : '수정', className: "btn-default-round btn-blue btn-xmd", onClick: handlerSubmit }),
                        React.createElement(Button, { text: "\uCDE8\uC18C", className: "btn-default-round btn-grey btn-xmd", onClick: cancelMenu }))),
                !!id && (React.createElement("div", { className: "regist_box", style: { gap: 12 } },
                    React.createElement(MembersTable, { id: id, isPopup: false, isModify: isModify, defaultValues: [item.host, ...item.managers, ...item.members] })))))));
});

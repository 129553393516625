import React from 'react';
import { Table } from '../../../common';
export const CompanyList = React.memo(({ searchOption, initPage }) => {
    const countRef = React.useRef(10);
    const [page, setPage] = React.useState(initPage);
    const [totalCount, setTotalCount] = React.useState(120);
    const [testData, setTestData] = React.useState([]);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'companyName', space: 1 },
            { title: 'companyId', space: 1 },
            { title: 'url', space: 1 },
            { title: 'userId', space: 1 },
            { title: 'serviceSetting', space: 1 },
            { title: 'serviceStart', space: 1 },
            { title: 'serviceEnd', space: 1 },
        ];
        const tableReadDataKeys = [
            { title: 'name', space: 1 },
            { title: 'id', space: 1 },
            { title: 'url', space: 1 },
            { title: 'userId', space: 1 },
            { title: 'serviceSetting', space: 1 },
            { title: 'serviceStart', space: 1 },
            { title: 'serviceEnd', space: 1 },
        ];
        return { headTitleList, tableReadDataKeys };
    }, []);
    React.useEffect(() => {
        setPage(initPage);
    }, [initPage]);
    React.useEffect(() => {
        let list = [];
        for (let i = page * countRef.current; i < (page + 1) * countRef.current; i++) {
            list.push({
                name: 'companyName' + i,
                id: i,
                url: 'url' + i,
                userId: 'userId' + i,
                serviceSetting: 'serviceSetting' + i,
                serviceStart: 'serviceStart' + i,
                serviceEnd: 'serviceEnd' + i,
            });
        }
        setTestData(list);
    }, [page]);
    const handlerMoreData = (page) => {
        setPage(page);
        APP.eventManager.publish('UPDATE_PAGE', page);
    };
    const renderEmpty = React.useMemo(() => totalCount <= 0 && (React.createElement("div", { className: "empty_data" },
        React.createElement("p", null, "\uB4F1\uB85D\uB41C \uD68C\uC0AC\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4."))), [totalCount]);
    const renderItem = (item) => {
        if (!item)
            return;
        return tableReadDataKeys.map((headItem, idx) => (React.createElement("div", { key: idx, className: "table-item", style: {
                flex: headItem.space,
                justifyContent: 'flex-start',
                alignItems: 'center',
                display: 'flex',
                overflow: 'hidden',
            } },
            React.createElement("p", { className: "overText2" }, item[headItem.title]))));
    };
    const handlerSelectItem = (item) => {
        APP.eventManager.publish('CHANGE_MENU', {
            showDepth: 'detail',
            props: { id: item.id },
        });
    };
    return (React.createElement(Table, { isInnerPage: true, isInifinite: false, page: page, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderItem: renderItem, renderEmpty: renderEmpty, handlerSelectItem: handlerSelectItem, handlerMoreData: handlerMoreData }));
});

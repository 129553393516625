import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button } from '../../../common';
export const DurationDateInput = forwardRef(({ title, defaultFirstValue = '', defaultSecondValue = '', handlerFirstUpdate, handlerSecondUpdate, noShowButtonArea = false, buttonStyle = {
    width: '123px',
}, buttonOption, ...props }, ref) => {
    const firstInputRef = useRef(null);
    const secondInputRef = useRef(null);
    const [firstValue, setFirstValue] = useState(defaultFirstValue);
    const [secondValue, setSecondValue] = useState(defaultSecondValue);
    useImperativeHandle(ref, () => ({
        focusFirst: () => firstInputRef.current?.focus(),
        focusSecond: () => secondInputRef.current?.focus(),
        getFirstValue: () => firstValue,
        getSecondValue: () => secondValue,
        setFirstValue: (newValue) => {
            setFirstValue(newValue);
            if (firstInputRef.current) {
                firstInputRef.current.value = newValue;
            }
        },
        setSecondValue: (newValue) => {
            setSecondValue(newValue);
            if (secondInputRef.current) {
                secondInputRef.current.value = newValue;
            }
        },
    }));
    const onFirstChange = (e) => {
        const newValue = e.target.value;
        setFirstValue(newValue);
        handlerFirstUpdate?.(newValue);
    };
    const onSecondChange = (e) => {
        const newValue = e.target.value;
        setSecondValue(newValue);
        handlerSecondUpdate?.(newValue);
    };
    useEffect(() => {
        setFirstValue(defaultFirstValue);
        setSecondValue(defaultSecondValue);
    }, [defaultFirstValue, defaultSecondValue]);
    return (React.createElement("div", { className: "text-input" },
        React.createElement("label", { className: "title" }, title),
        React.createElement("div", { className: "date_wrap" },
            React.createElement("input", { ref: firstInputRef, value: firstValue, onChange: onFirstChange, ...props }),
            React.createElement("span", null, "~"),
            React.createElement("input", { ref: secondInputRef, value: secondValue, onChange: onSecondChange, ...props })),
        !noShowButtonArea && (React.createElement("div", { style: buttonStyle }, buttonOption && (React.createElement(Button, { className: buttonOption.className, text: buttonOption.title, onClick: buttonOption.handlerClick }))))));
});

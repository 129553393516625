import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Colors } from '../../../Colors';
import { Icon } from '../../../common';
export const SearchInput = forwardRef(({ className = '', defaultValue, isIconHide, setSearchOption, ...props }, ref) => {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    useImperativeHandle(ref, () => ({
        focus: () => inputRef.current?.focus(),
        getValue: () => inputRef.current?.value || '',
        setValue: (newValue) => {
            if (inputRef.current) {
                inputRef.current.value = newValue;
            }
        },
    }));
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const getSize = () => {
        if (className.includes('sm'))
            return 20;
        if (className.includes('lg'))
            return 28;
        return 24;
    };
    const onSubmit = () => {
        if (inputRef.current) {
            setSearchOption(inputRef.current.value);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSubmit();
        }
    };
    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = defaultValue || '';
        }
    }, [defaultValue]);
    return (React.createElement("div", { className: `flex row search-bar search-admin-bar ${className} ${isFocused ? 'focus' : ''}` },
        React.createElement("input", { ref: inputRef, defaultValue: defaultValue, onFocus: handleFocus, onBlur: handleBlur, onKeyDown: handleKeyDown, placeholder: "\uAC80\uC0C9\uC5B4\uB97C \uC785\uB825\uD558\uC138\uC694", ...props }),
        !isIconHide && (React.createElement("div", { className: "flex row icon", onClick: onSubmit },
            React.createElement(Icon, { icon: "search", size: getSize(), fill: Colors.tur })))));
});

import { getAccessToken } from '../../features/base/account';
import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
const getToken = () => getAccessToken(APP.store.getState);
// (클래스) 클래스 리스트생성
export const postKlassItem = async (data) => {
    const url = `/api/mimac/v1/admin/class`; // API 엔드포인트 생성
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
            'X-Tenant-ID': 'mimac',
        },
        body: JSON.stringify(data), // classData 객체를 JSON 문자열로 변환하여 전송
    };
    return fetchData(url, options);
};
// (클래스) 클래스 리스트생성
export const postKlassMenu = async (data) => {
    const url = `/api/mimac/v1/admin/class/${data.class_id}/hide-menu`; // API 엔드포인트 생성
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data.props), // classData 객체를 JSON 문자열로 변환하여 전송
    };
    return fetchData(url, options);
};
// (클래스) 클래스 리스트 삭제
export const deleteKlassItem = async (class_id) => {
    const url = `/api/mimac/v1/admin/class/${class_id}/delete`; // API 엔드포인트 생성
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    };
    return fetchData(url, options);
};
// (클래스) 클래스 리스트 수정
export const modifyKlassItem = async (data) => {
    const url = `/api/mimac/v1/admin/class/${data.class_id}/update`; // API 엔드포인트 생성
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data.props), // classData 객체를 JSON 문자열로 변환하여 전송
    };
    return fetchData(url, options);
};
// (클래스) 클래스 리스트 수정
export const modifyKlassMember = async (data) => {
    const url = `/api/mimac/v1/admin/class/${data.class_id}/enroll`; // API 엔드포인트 생성
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data.props), // classData 객체를 JSON 문자열로 변환하여 전송
    };
    return fetchData(url, options);
};
// (클래스) 클래스 리스트 수정
export const deleteKlassMember = async (data) => {
    const url = `/api/mimac/v1/admin/class/${data.class_id}/leave`; // API 엔드포인트 생성
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data.props), // classData 객체를 JSON 문자열로 변환하여 전송
    };
    return fetchData(url, options);
};
// (클래스) 클래스 리스트 조회
export const getClassesList = async (props) => {
    const queryString = objectToQueryString(props); // props를 쿼리 문자열로 변환
    const url = `/api/mimac/v1/classes?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (클래스) 클래스 리스트 가져오기
export const useClassesList = (data) => {
    return useCustomQuery([data], () => getClassesList(data));
};

import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../../Colors';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Button, InputElement, Table, } from '../../../../../common';
import { createFreeEvalArray } from '../../function';
function showAlert(message) {
    // 모든 input, textarea, select 요소에서 포커스를 해제
    // 모든 input, textarea 요소에서 포커스를 해제
    document.querySelectorAll('input, textarea').forEach(input => {
        input.blur();
    });
    // 포커스가 해제된 후 잠시 기다린 뒤 alert를 실행
    setTimeout(() => {
        alert(message);
    }, 100); // 100ms 정도 지연을 주어 키보드가 사라질 시간을 확보
}
export const EvaluationCountTable = React.memo(({ evalItemCnt, evalData, subjectId, setEvalData, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile, isDesktop } = useGlobalState();
    const [updatedData, setUpdatedData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [testData, setTestData] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'choiceSubject', space: 1, sort: false },
            { title: 'multipleChoice', space: 1, sort: false },
            { title: 'subjective', space: 1, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'choiceSubject', space: 1 },
            { title: 'multipleChoice', space: 1 },
            { title: 'subjective', space: 1 },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, []);
    const generateTestData = (count) => {
        const testDataArray = [];
        if (count >= evalData.length) {
            // initEvalData의 각 항목을 테스트 데이터로 변환
            evalData.forEach((item, index) => {
                testDataArray.push({
                    id: index,
                    choiceSubject: item.key,
                    multipleChoice: item.data
                        ? item.data.filter((data) => data.type === 'choice' || data.type === 'objective').length || 1
                        : 1,
                    subjective: item.data
                        ? item.data.filter((data) => data.type === 'short' || data.type === 'subjective').length || 1
                        : 1,
                });
            });
            // count가 initEvalData의 길이보다 큰 경우 추가 데이터를 생성
            for (let i = 0; i < count - evalData.length; i++) {
                testDataArray.push({
                    id: i + evalData.length,
                    choiceSubject: undefined,
                    multipleChoice: 1,
                    subjective: 1,
                });
            }
        }
        else {
            // count가 initEvalData의 길이보다 작은 경우
            for (let i = 0; i < count; i++) {
                testDataArray.push({
                    id: i,
                    choiceSubject: undefined,
                    multipleChoice: 1,
                    subjective: 1,
                });
            }
        }
        return testDataArray;
    };
    // 일반적인 기본값 생성
    React.useEffect(() => {
        const data = generateTestData(evalItemCnt);
        setTestData(data);
        setTotalCount(evalItemCnt);
    }, [evalItemCnt, subjectId, evalData]);
    React.useEffect(() => {
        setUpdatedData(testData);
    }, [testData]);
    // (OnClick) 항목 변경
    // (OnClick) 항목 변경
    const onChange = (id, changeData) => {
        // console.log('onChange', id, changeData);
        setUpdatedData(prevData => {
            // 새로운 데이터를 생성하여 반환하도록 수정
            // console.log('prevData1', prevData);
            const newData = prevData.map(item => {
                if (item.id === id) {
                    return { ...item, ...changeData };
                }
                return item; // 변경되지 않은 경우 이전 객체를 그대로 반환
            });
            // console.log('newData1', newData);
            return newData;
        });
        setTestData(prevData => {
            // console.log('prevData2', prevData);
            const newData = prevData.map(item => {
                if (item.id === id) {
                    return { ...item, ...changeData };
                }
                return item; // 변경되지 않은 경우 이전 객체를 그대로 반환
            });
            // console.log('newData2', newData);
            return newData;
        });
    };
    const renderEmpty = useMemo(() => {
        return (React.createElement("div", { className: "flex center body3 sel_eval_item", style: { color: Colors.light_grey } }, t('assignment.selectEvalCntItem')));
    }, []);
    // (onLoad) 데이터 불러오기
    const handlerMoreData = () => {
        setPage(prevPage => prevPage + 1);
    };
    // (onSort) 정렬하기
    const handlerUpdateList = (sort, desc) => {
        // get data from server
        setTestData(_.orderBy(testData, [sort], [desc ? 'desc' : 'asc']));
    };
    function compareData(data1, data2) {
        // console.log('compareData', data1, data2);
        if (data1.length !== data2.length) {
            return false;
        }
        function normalizeType(type) {
            if (type === 'objective' || type === 'choice') {
                return 'objective';
            }
            if (type === 'subjective' || type === 'short') {
                return 'subjective';
            }
            return type;
        }
        for (let i = 0; i < data1.length; i++) {
            // Compare ids
            if (data1[i].id !== data2[i].id) {
                // console.log('id mismatch', data1[i].id, data2[i].id, 'type', data1[i].type, data2[i].type);
                return false;
            }
            if (normalizeType(data1[i].type) !== normalizeType(data2[i].type)) {
                // console.log('type mismatch', data1[i].type, data2[i].type);
                return false;
            }
        }
        return true;
    }
    // (onClick) 평가 항목 등록
    const onSubmit = () => {
        let isCheck = true;
        let newEvalData = new Map();
        let failData = [];
        // Set to track unique choiceSubject values
        const processedSubjects = new Set();
        // Iterate through updatedData without early termination
        for (const item of updatedData) {
            if (item.multipleChoice === 0 && item.subjective === 0) {
                showAlert('객관식/주관식 문항 수를 입력해주세요');
                isCheck = false;
                failData.push(item);
                continue;
            }
            // Check if values are in the range and if choiceSubject exists
            if (item.multipleChoice < 0 || item.subjective < 0) {
                showAlert('객관식/주관식 문항 수는 0보다 작을 수 없습니다. 문항 수를 조정해 주세요.');
                isCheck = false;
                failData.push(item);
                continue;
            }
            if (item.multipleChoice > 150 || item.subjective > 150) {
                showAlert('객관식/주관식 문항 수는 최대 150문항을 초과할 수 없습니다. 문항 수를 조정해 주세요.');
                isCheck = false;
                failData.push(item);
                continue;
            }
            if (item.choiceSubject === undefined || item.choiceSubject.trim() === '') {
                showAlert('과목 이름을 입력해주세요');
                isCheck = false;
                failData.push(item);
                continue;
            }
            // Check for duplicate choiceSubject
            if (processedSubjects.has(item.choiceSubject)) {
                showAlert(`중복된 과제 이름이 있습니다: ${item.choiceSubject}`);
                isCheck = false;
                failData.push(item);
                continue;
            }
            // Add the current choiceSubject to the Set
            processedSubjects.add(item.choiceSubject);
            // Create data and set in evalData
            const data = createFreeEvalArray(item.multipleChoice, item.subjective);
            if (evalData.length === 0) {
                newEvalData.set(item.choiceSubject, { key: item.choiceSubject, data });
            }
            else {
                const existingItem = evalData.find(data => data.key === item.choiceSubject);
                if (existingItem === undefined) {
                    newEvalData.set(item.choiceSubject, { key: item.choiceSubject, data });
                }
                else {
                    const evalDataItem = existingItem.data;
                    if (compareData(data, evalDataItem)) {
                        newEvalData.set(item.choiceSubject, {
                            key: item.choiceSubject,
                            data: evalDataItem,
                        });
                    }
                    else {
                        newEvalData.set(item.choiceSubject, { key: item.choiceSubject, data });
                    }
                }
            }
        }
        // If all data is valid, proceed
        if (isCheck) {
            console.log([...newEvalData.values()]);
            setEvalData([...newEvalData.values()]); // Assuming evalData needs to be an array
            // Handle mobile-specific logic
            if (isMobile) {
                if (updatedData.length === [...newEvalData.values()].length) {
                    showAlert('등록 되었습니다');
                }
            }
        }
        else {
            // // When validation fails, filter out the invalid data from updatedData
            // const validData = updatedData.filter(data => !failData.includes(data));
            // // Update the evalData with valid entries
            // setEvalData(
            // 	validData.map(data => ({
            // 		key: data.choiceSubject,
            // 		data: createFreeEvalArray(data.multipleChoice, data.subjective),
            // 	}))
            // );
            // alert('입력값에 문제가 있는 항목은 제외되었습니다.');
        }
    };
    const renderItem = (item, styles) => {
        return (React.createElement(EvalCountItem, { item: item, tabelReadDataKeys: tableReadDataKeys, styles: styles, onChange: onChange }));
    };
    return (React.createElement("div", { className: "popup-list-002-wrapper" },
        React.createElement(Table, { className: "popup-list-002", showCheckBox: false, showIndex: false, isInifinite: true, data: testData, totalCount: totalCount, page: page, sortDefault: "id", headTitleList: headTitleList, renderItem: renderItem, renderEmpty: evalItemCnt === 0 && renderEmpty, handlerMoreData: handlerMoreData, handlerUpdateList: handlerUpdateList, styles: {
                scrollContent: {
                    justifyContent: evalItemCnt === 0 && 'center',
                    minHeight: evalItemCnt === 0 && isDesktop && '100px',
                },
            }, forceShowheader: isMobile && evalItemCnt === 0 ? false : true }),
        evalItemCnt !== 0 && (React.createElement(Button, { text: "common.okay", className: `btn_line_tur ${isTablet || isMobile ? 'xxs' : 'sm'}`, styles: {
                buttonWrap: {
                    width: isMobile ? '100%' : 'unset',
                },
            }, onClick: onSubmit }))));
});
const EvalCountItem = React.memo(({ item, tabelReadDataKeys, styles, onChange, }) => {
    const choiceSubjectRef = useRef(null);
    const multipleChoiceRef = useRef(null);
    const subjectiveRef = useRef(null);
    const { isMobile } = useGlobalState();
    const { t } = useTranslation();
    React.useEffect(() => {
        if (choiceSubjectRef.current) {
            choiceSubjectRef.current.value = item.choiceSubject || '';
        }
    }, [item.choiceSubject]);
    React.useEffect(() => {
        if (multipleChoiceRef.current) {
            multipleChoiceRef.current.valueAsNumber = Number(item.multipleChoice);
        }
    }, [item.multipleChoice]);
    React.useEffect(() => {
        if (subjectiveRef.current) {
            subjectiveRef.current.valueAsNumber = Number(item.subjective);
        }
    }, [item.subjective]);
    return (React.createElement(React.Fragment, null, tabelReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, className: "body-item", style: { flex: headItem.space, ...styles?.bodyItem, overflow: 'hidden' } }, headItem.title === 'choiceSubject' ? (React.createElement(InputElement, { ref: choiceSubjectRef, className: "input-md", type: "text", placeholder: isMobile
            ? '과목 입력'
            : t(`assignment.${headItem.title}Placeholder`), style: styles?.bodyText, onChange: value => onChange(item.id, { [headItem.title]: value }) })) : (React.createElement(InputElement, { ref: headItem.title === 'multipleChoice'
            ? multipleChoiceRef
            : subjectiveRef, className: "input-md", type: "number", placeholder: t(`assignment.${headItem.title}Placeholder`), style: styles?.bodyText, onChange: value => onChange(item.id, {
            [headItem.title]: Number(value),
        }) })))))));
});

import { throttle } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../Colors';
import { useGlobalState, useHistoryStore } from '../../../../GlobalStateProvider';
import { Button, Icon, Table } from '../../../../common';
import { useQnaList } from '../../../../server/qna';
import { TOGGLE_POPUP } from '../../../base/popup';
import { changeMemuProps, updateMenu } from '../../../menu/action';
import { DSTS004_Detail } from '../DSTS004_Detail';
import { QNA_STATUS, QNA_TYPE } from '../constant';
import { QnAListInfo } from './QnAListInfo';
const QnAListTableStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;
	width: 100%;

	.no-p {
		white-space: unset !important;
	}

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.body-item {
		.pink {
			color: var(--color-pink, #f46790);
		}

		.purple {
			color: var(--pur-d, #8268d9);
		}
	}

	.qna-list-001,
	.qna-list-002 {
		width: 100%;
	}

	.locked-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.qna-list-002,
	.qna-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow: hidden;

		${props => props.$isHome &&
    css `
				border-style: solid;
				border-color: var(--color-silver, #f3f4f8);
				border-width: 1px 0px 0px 0px;

				.scroll-content {
					display: flex;
				}
			`}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 12px);
				line-height: 150%;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.file {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 4px;
				overflow: hidden;
				p {
					color: var(--color-grey-w, #b6bac0);
				}
			}

			.button {
				p {
					font-size: var(--b3-font-size, 10px);
					color: var(--color-white, #ffffff);
					width: 30px;
					height: 17px;
					display: flex;
					justify-content: center;
				}
			}

			.bold {
				font-size: 14px;
				font-weight: 700;
			}

			.grey {
				color: var(--color-grey, #666666);
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;

			padding: 10px 0px 10px 0px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
		.bold-pur {
			.file {
				p {
					color: var(--pur-d, #8268d9);
					font-weight: 700;
				}
			}
			p {
				color: var(--pur-d, #8268d9);
				font-weight: 700;
			}
		}

		.locked-post {
			background: #fcfcfc;
			border-radius: 0px 0px 12px 12px;
			padding: 40px 98px 40px 98px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}

		.locked-text {
			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b2-font-size, 18px);
			line-height: var(--b2-line-height, 36px);
			letter-spacing: var(--b2-letter-spacing, -0.02em);
			font-weight: var(--b2-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.qna-list-wrap {
		display: flex;
		flex-direction: row;
		gap: 6px;
	}

	.locked-wrap {
		gap: 4px;
		svg {
			min-width: 14px;
		}
	}
`;
const tabletStyles = css `
	.qna-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 14px);
			line-height: 150%;
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 14px);
				line-height: 150%;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.file {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 4px;
				overflow: hidden;
				p {
					color: var(--color-grey-w, #b6bac0);
				}
			}

			.button {
				p {
					font-size: var(--b3-font-size, 12px);
					color: var(--color-white, #ffffff);
					width: 45px;
					height: 21px;
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: ${props => (props.$isHome ? '10px 28px 10px 28px' : '20px ')};
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}

		.bold-pur {
			.file {
				p {
					color: var(--pur-d, #8268d9);
					font-weight: 700;
				}
			}
			p {
				color: var(--pur-d, #8268d9);
				font-weight: 700;
			}
		}

		.locked-post {
			background: #fcfcfc;
			border-radius: 0px 0px 12px 12px;
			padding: 40px 98px 40px 98px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}

		.locked-text {
			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b2-font-size, 18px);
			line-height: var(--b2-line-height, 36px);
			letter-spacing: var(--b2-letter-spacing, -0.02em);
			font-weight: var(--b2-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.qna-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.under-line {
				color: var(--color-pink, #f46790);
				text-decoration: underline;
				cursor: pointer;
			}

			button {
				p {
					font-size: 10px;
					line-height: var(--b4-line-height, 24px);
					width: 35px;
					height: 25px;
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.locked-wrap {
		gap: 10px;
	}
`;
const desktopStyles = css `
	.qna-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 6px 28px 6px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.file {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				overflow: hidden;
				p {
					color: var(--color-grey-w, #b6bac0);
				}
			}

			.button {
				p {
					font-size: var(--b3-font-size, 14px);
					color: var(--color-white, #ffffff);
					width: 49px;
					height: 30px;
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;

			padding: ${props => (props.$isHome ? '10px 28px 10px 28px' : '20px 28px 20px 28px')};
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}

		.bold-pur {
			.file {
				p {
					color: var(--pur-d, #8268d9);
					font-weight: 700;
				}
			}
			p {
				color: var(--pur-d, #8268d9);
				font-weight: 700;
			}
		}

		.locked-post {
			background: #fcfcfc;
			border-radius: 0px 0px 12px 12px;
			padding: 40px 98px 40px 98px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}

		.locked-text {
			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b2-font-size, 18px);
			line-height: var(--b2-line-height, 36px);
			letter-spacing: var(--b2-letter-spacing, -0.02em);
			font-weight: var(--b2-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.qna-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.under-line {
				color: var(--color-pink, #f46790);
				text-decoration: underline;
				cursor: pointer;
			}

			button {
				p {
					font-size: 10px;
					line-height: var(--b4-line-height, 24px);
					width: 35px;
					height: 25px;
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.locked-wrap {
		gap: 10px;
	}
`;
const hideID = (name) => {
    const hiddenPart = name.slice(3); // 문자열의 세 번째 인덱스부터 끝까지 추출
    const maskedPart = '*'.repeat(hiddenPart.length); // 추출한 부분의 길이만큼 '*' 문자를 반복
    const maskedString = name.slice(0, 3) + maskedPart; // 첫 세 글자와 가려진 부분을 결합하여 최종 결과 생성
    return maskedString;
};
const hideName = (name) => {
    //이름이 2글자인 경우 첫 글자만 노출하고 나머지 글자를 '*'로 가림
    if (name.length <= 2) {
        return name[0] + '*';
    }
    //문자열의 맨 앞과 맨 뒤 글자를 제외한 나머지 글자를 '*'로 가림
    return name[0] + '*'.repeat(name.length - 2) + name[name.length - 1];
};
export const QnAListTable = React.memo(({ defaultOpen, isTeacher, items, isRetry = false, isHome = false, id = undefined, isPopUp = false, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const dispatch = useDispatch();
    const historyStore = useHistoryStore();
    const [previousPageState, setPreviousPageState] = useState();
    const [initLoad, setInitLoad] = useState(true);
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [openCount, setOpenCount] = useState(0);
    const [closedCount, setClosedCount] = useState(0);
    const [count, setCount] = useState(isHome ? 5 : 10);
    // const [isSearchAnswer, setIsSearchAnswer] = useState<boolean>(false);
    const [importance, setImportance] = useState([]);
    const [filterOption, setFilterOption] = useState({});
    const [searchOption, setSearchOption] = useState({});
    const [isContent, setIsContent] = useState(false);
    const [isAnswer, setIsAnswer] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    // detail ID
    const [selectedId, setSelectedId] = useState();
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            ...(isHome
                ? [
                    { title: 'no', space: 1, maxWidth: 60 },
                    { title: 'live_title', space: 3 },
                    { title: 'answer_state', space: 1 },
                ]
                : [
                    { title: 'no', space: 1, maxWidth: 60, minWidth: 45 },
                    ...(isRetry ? [] : [{ title: 'dataType', space: 1, maxWidth: 32 }]),
                    { title: 'creation_time', space: 2, maxWidth: 150 },
                    { title: 'category', space: 1 },
                    { title: 'live_title', space: 4 },
                    ...(isRetry ? [] : [{ title: 'attachFile', space: 1 }]),
                    { title: 'author_name_id', space: 1 },
                    ...(isTeacher ? [{ title: 'answer_name', space: 1 }] : []),
                    { title: 'answer_state', space: 1 },
                    ...(isRetry ? [{ title: 'etc', space: 1 }] : []),
                ]),
        ];
        const tableReadDataKeys = [
            ...(isHome
                ? [
                    { title: 'index', space: 1, maxWidth: 60 },
                    { title: 'content', space: 3 },
                    { title: 'status', space: 1 },
                ]
                : [
                    { title: 'index', space: 1, maxWidth: 60, minWidth: 45 },
                    ...(isRetry ? [] : [{ title: 'private', space: 1, maxWidth: 32 }]),
                    { title: 'creation_time', space: 2, maxWidth: 150 },
                    { title: 'category', space: 1 },
                    { title: 'content', space: 4 },
                    ...(isRetry ? [] : [{ title: 'files', space: 1 }]),
                    { title: isAnswer ? '' : 'author_name', space: 1 },
                    ...(isTeacher
                        ? [
                            {
                                title: isAnswer ? 'author_name' : 'respondent_name',
                                space: 1,
                            },
                        ]
                        : []),
                    { title: 'status', space: 1 },
                    ...(isRetry ? [{ title: 'etc', space: 1 }] : []),
                ]),
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [isAnswer, isHome, isRetry, isTeacher]);
    useEffect(() => {
        return () => {
            renderSavePreviousItem();
        };
    }, [filterOption, page, startDate, endDate]);
    useEffect(() => {
        const initializeState = async () => {
            const previousPage = historyStore.getRecentPageHistory('qnaListPage');
            // 이전 페이지의 상태를 적용
            setIsContent(previousPage?.props.isContent);
            setIsAnswer(previousPage?.props.isAnswer);
            setSearchOption(previousPage?.searchOption || {});
            handlerSearch(previousPage?.searchOption);
            setPage(previousPage?.prePage || 0);
            setStartDate(previousPage?.props.startDate);
            setEndDate(previousPage?.props.endDate);
            setPreviousPageState(previousPage);
            setInitLoad(true);
        };
        initializeState();
        return () => {
            setInitLoad(false);
        };
    }, []);
    const { data: searchData, refetch: searchRefetch } = useQnaList({
        ...(previousPageState
            ? previousPageState.preSearchItems
            : {
                ...(isMobile && isPopUp ? { root_id: id } : {}),
                orderby: ['creation_time DESC'],
            }),
    });
    const { data, refetch } = useQnaList({
        ...(isMobile && isPopUp ? { root_id: id } : {}),
        offset: page === 0 ? 0 : page * count,
        limit: count,
        created_before: endDate
            ? moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
            : undefined,
        created_after: startDate
            ? moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
            : undefined,
        orderby: ['importance DESC', 'creation_time DESC'],
        status: isTeacher ? undefined : [QNA_STATUS.CLOSED, QNA_STATUS.OPEN],
        ...(isContent ? { group_by_round: true } : { with_respondent: true }),
        ...filterOption,
        type: isAnswer ? QNA_TYPE.ANSWER : QNA_TYPE.QUESTION,
    });
    const refetchList = throttle(() => {
        if (!initLoad)
            return;
        if (!isContent) {
            searchRefetch();
        }
        refetch();
    }, 1000, {
        leading: true,
        trailing: false,
    });
    useEffect(() => {
        setCount(isHome ? 5 : 10);
    }, [isHome]);
    useEffect(() => {
        APP.eventManager.subscribe('REFETCH_QNA_LIST', refetchList);
        return () => {
            APP.eventManager.unsubscribe('REFETCH_QNA_LIST', refetchList);
        };
    }, []);
    useEffect(() => {
        if (!initLoad)
            return;
        if (isRetry) {
            const data = items
                .map(i => {
                return i.type === QNA_TYPE.QUESTION ? i : null;
            })
                .filter(i => i !== null);
            setTestData(data);
            setTotalCount(data.length);
            return;
        }
        refetchList();
    }, [initLoad, filterOption, isRetry, page, items, count]);
    useEffect(() => {
        if (isRetry)
            return;
        if (data && searchData) {
            const { total_count, items, found_count, open_count, closed_count } = data;
            if (total_count > 0 && found_count === 0) {
                setPage(Math.max(page - 1, 0));
                return;
            }
            if (page === 0) {
                setImportance(items.filter(item => item.importance));
            }
            //이전 페이지 저장된 기록 초기화
            if (previousPageState) {
                setPreviousPageState(undefined);
            }
            if (found_count > 0) {
                setTestData(id ? items.filter(item => item.root_id === id) : items);
                setTotalCount(isHome ? Math.min(total_count, 5) : total_count);
                setQuestionData(searchData?.items.filter(item => item.type === QNA_TYPE.QUESTION));
                setOpenCount(open_count);
                setClosedCount(closed_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
        setOpenCount(0);
        setClosedCount(0);
    }, [data, searchData]);
    useEffect(() => {
        if (selectedId !== defaultOpen)
            setSelectedId(defaultOpen);
    }, [defaultOpen]);
    const renderSavePreviousItem = () => {
        if (!initLoad) {
            return;
        }
        // 새로운 페이지로 이동할 때 현재 상태를 기록
        const historyItem = {
            preItems: {
                ...(isMobile && isPopUp ? { root_id: id } : {}),
                offset: page === 0 ? 0 : page * count,
                limit: count,
                created_before: endDate
                    ? moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
                    : undefined,
                created_after: startDate
                    ? moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
                    : undefined,
                orderby: ['importance DESC', 'creation_time DESC'],
                status: isTeacher ? undefined : [QNA_STATUS.CLOSED, QNA_STATUS.OPEN],
                ...(isContent ? { group_by_round: true } : { with_respondent: true }),
                ...filterOption,
            },
            preSearchItems: {
                ...(isMobile && isPopUp ? { root_id: id } : {}),
                orderby: ['creation_time DESC'],
            },
            props: {
                isContent: isContent,
                isAnswer: isAnswer,
                isRetry: isRetry,
                startDate: startDate,
                endDate: endDate,
            },
            prePage: page,
            searchOption,
            //scrollTop: 0,
            pageName: 'qnaListPage',
        };
        historyStore.pushHistory(historyItem);
    };
    const handlerMoreData = (page) => setPage(page);
    // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => {
        setSearchOption(filter);
    };
    const handlerSearch = option => {
        const search = option || searchOption;
        if (search.hasOwnProperty('register_author')) {
            setFilterOption({ author: search['register_author'], type: QNA_TYPE.QUESTION });
        }
        else if (search.hasOwnProperty('answerer_author')) {
            setIsAnswer(true);
            setIsContent(false);
            setFilterOption({
                author: search['answerer_author'],
                type: QNA_TYPE.ANSWER,
            });
            return;
        }
        else if (search.hasOwnProperty('content')) {
            setIsContent(true);
            setFilterOption(search);
        }
        else {
            setIsContent(false);
            setFilterOption(search);
        }
        setIsAnswer(false);
    };
    const renderTitle = useMemo(() => {
        return (initLoad && (React.createElement(QnAListInfo, { isTeacher: isTeacher, setStartDate: setStartDate, startDate: startDate, setEndDate: setEndDate, endDate: endDate, totalCount: totalCount, openCount: openCount, closedCount: closedCount, searchOption: searchOption, handlerFilterList: handlerFilterList, handlerSearch: handlerSearch })));
    }, [
        initLoad,
        totalCount,
        openCount,
        closedCount,
        searchOption,
        handlerFilterList,
        handlerSearch,
    ]);
    const showDetail = (root_id, index) => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DSTS004_Detail,
            componentProps: {
                root_id,
                depths: index,
                isPopUp: true,
            },
            width: 1040,
            height: 884,
            title: t('supportRoom.showQuestion'),
        });
    };
    const renderItem = (item, styles, index) => {
        const listData = ((isAnswer && searchOption['author']) || (isContent && item.type === 'answer')) &&
            questionData.filter(data => item.number - data.number === 1 && item.root_id === data.root_id);
        const dataToUse = (isAnswer && searchOption['author']) || (isContent && item.type === 'answer')
            ? listData[0]
            : item;
        const isSelected = item.id === selectedId;
        if (!dataToUse)
            return;
        const renderStatus = headItem => {
            if (isContent && isTeacher && dataToUse[headItem.title] === QNA_STATUS.CREATED) {
                dataToUse[headItem.title] = QNA_STATUS.CLOSED;
            }
            return (React.createElement("p", { className: `overText2
							${dataToUse[headItem.title] === QNA_STATUS.CLOSED
                    ? 'pink'
                    : dataToUse[headItem.title] === QNA_STATUS.CREATED
                        ? 'purple'
                        : ''}` }, t(`supportRoom.${dataToUse[headItem.title]}`)));
        };
        return tableReadDataKeys.map(headItem => {
            if (isMobile &&
                ['private', 'creation_time', 'files', 'member'].includes(headItem.title))
                return null;
            return (React.createElement("div", { key: headItem.title + item.id, className: `body-item ${isSelected ? 'bold-pur' : ''}`, style: {
                    flex: headItem.space,
                    maxWidth: headItem?.maxWidth ? headItem.maxWidth : 'auto',
                    ...styles?.bodyItem,
                    overflow: 'hidden',
                } }, (() => {
                switch (headItem.title) {
                    case 'files':
                        return dataToUse.files.length > 0 ? (React.createElement("div", { className: "file" },
                            React.createElement(Icon, { icon: "file", size: isTablet ? 14 : 20, fill: isSelected ? Colors.pur : Colors.w_grey }),
                            React.createElement("p", { className: "overText2" },
                                "(",
                                dataToUse.files.length,
                                ")"))) : (React.createElement("p", null, "-"));
                    case 'private':
                        return dataToUse[headItem.title] ? (React.createElement(Icon, { icon: "locked", className: "locked", size: isTablet ? 14 : 20, fill: isSelected ? 'var(--pur-d, #8268d9)' : '#666666' })) : null;
                    case 'content':
                        return (React.createElement("div", { className: "locked-wrap overText2" },
                            isMobile && dataToUse['private'] ? (React.createElement(Icon, { icon: "locked", className: "locked", size: 14, fill: isSelected
                                    ? 'var(--pur-d, #8268d9)'
                                    : '#666666' })) : (dataToUse.id !== dataToUse.root_id && (React.createElement(Button, { className: "btn_tur mobile-xxs", text: 'supportRoom.req' }))),
                            React.createElement("p", { className: `overText2 ${isMobile ? 'bold' : ''}` }, dataToUse.title)));
                    case 'creation_time':
                        return (React.createElement("p", { className: "overText2" }, moment(dataToUse[headItem.title]).format('YYYY-MM-DD, HH:mm')));
                    case 'status':
                        return isMobile ? (React.createElement("div", { className: "qna-list-wrap" },
                            dataToUse.files.length > 0 && (React.createElement("div", { className: "file" },
                                React.createElement(Icon, { icon: "file", size: 12, fill: isSelected ? Colors.pur : Colors.w_grey }),
                                React.createElement("p", { className: "overText2" },
                                    "(",
                                    dataToUse.files.length,
                                    ")"))),
                            React.createElement("p", { className: `overText2 ${isSelected ? '' : 'grey'}` }, moment(dataToUse['creation_time']).format('YYYY-MM-DD, HH:mm')),
                            renderStatus(headItem))) : (renderStatus(headItem));
                    case 'category':
                        return isMobile ? (React.createElement("div", { style: {
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '4px',
                            } },
                            dataToUse.id !== dataToUse.root_id && (React.createElement(Button, { className: "btn_tur mobile-xxs", text: 'supportRoom.req' })),
                            React.createElement("p", { className: "overText2" }, t(`supportRoom.${dataToUse[headItem.title]}`)))) : (React.createElement("p", { className: "overText2" }, t(`supportRoom.${dataToUse[headItem.title]}`)));
                    case 'etc':
                        return (!isMobile && (React.createElement("p", { className: "under-line overText2", onClick: () => showDetail(dataToUse.root_id, Math.max(dataToUse.number - 1, 0)) }, "\uC790\uC138\uD788 \uBCF4\uAE30")));
                    case 'author_name':
                        return isTeacher ? (isMobile ? (React.createElement("div", { className: "qna-member-wrap" },
                            React.createElement("p", { className: "overText2" }, t('supportRoom.question_name', {
                                name: `${dataToUse[headItem.title]} (${dataToUse['author_idf']})`,
                            })))) : (React.createElement("p", { className: "overText2" },
                            dataToUse[headItem.title],
                            " (",
                            dataToUse['author_idf'],
                            ")"))) : (React.createElement("p", { className: "overText2" },
                            hideName(dataToUse[headItem.title]),
                            " (",
                            hideID(dataToUse['author_idf']),
                            ")"));
                    case 'respondent_name':
                        return isAnswer ? (React.createElement("p", { className: "overText2" }, t('supportRoom.answer_name', {
                            name: item[searchOption['author']
                                ? 'author_name'
                                : 'respondent_name'] === ''
                                ? '-'
                                : item[searchOption['author']
                                    ? 'author_name'
                                    : 'respondent_name'],
                        }))) : isContent ? (React.createElement("p", { className: "overText2" }, t('supportRoom.answer_name', {
                            name: item.respondent_name,
                        }))) : (React.createElement("p", { className: "overText2" }, item[headItem.title]));
                    case 'index':
                        return (React.createElement("p", { className: "overText2 no-p" }, dataToUse['importance']
                            ? '필독'
                            : dataToUse[headItem.title]));
                    default:
                        return React.createElement("p", { className: "overText2" }, dataToUse[headItem.title]);
                }
            })()));
        });
    };
    const renderEmpty = useMemo(() => totalCount <= 0 && (React.createElement("div", { className: "empty_data", style: { color: Colors.light_grey } }, t(`supportRoom.questionEmpty`))), [totalCount]);
    const renderMoreItem = (item, styles) => {
        return (React.createElement(DSTS004_Detail, { root_id: item.root_id, depths: Math.max(item.number - 1, 0), isHidePaging: true, isPopUp: isPopUp, isContent: isContent || isAnswer, 
            // isSearchAnswer={isSearchAnswer}
            importance: importance }));
    };
    const handlerSelectItem = (item) => {
        renderSavePreviousItem();
        if (QNA_STATUS.OPEN === item.status && isTeacher) {
            if (isHome) {
                dispatch(updateMenu('learningSupportRoom', { menu: 'qna', detail: 'answer' }, item.root_id, {
                    defaultOpen: item.id,
                }));
                return;
            }
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'answer',
                id: item.root_id,
            });
            return;
        }
        if (isHome) {
            dispatch(updateMenu('learningSupportRoom', { menu: 'qna', detail: 'list' }, undefined, {
                defaultOpen: item.id,
            }));
            return;
        }
        const newId = selectedId === item.id ? undefined : item.id;
        setSelectedId(newId);
        dispatch(changeMemuProps({ defaultOpen: newId }));
    };
    return (React.createElement(QnAListTableStyled, { "$isHome": isHome, "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement(Table, { className: isRetry ? 'qna-list-002' : 'qna-list-001', showCheckBox: false, isInifinite: isRetry || isHome ? true : false, selectedId: selectedId, page: page, showCount: count, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderTitle: !isRetry && !isHome && !id && renderTitle, renderItem: renderItem, renderEmpty: renderEmpty, renderMoreItem: isRetry ? undefined : renderMoreItem, handlerSelectItem: isRetry ? undefined : handlerSelectItem, handlerMoreData: isRetry ? undefined : handlerMoreData, forceShowheader: isMobile ? false : true })));
});

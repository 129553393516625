import { throttle } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Table } from '../../../common';
import { useClassesList } from '../../../server/classes';
import { TOGGLE_POPUP } from '../../base/popup';
import { MembersTable } from '../common';
import { useTranslation } from 'react-i18next';
export const KlassList = React.memo(({ onlyOne = false, searchOption, initPage, defaultProps, classId, setClassId, }) => {
    const { t } = useTranslation();
    const countRef = React.useRef(10);
    const [page, setPage] = React.useState(initPage);
    const [totalCount, setTotalCount] = React.useState(120);
    const [testData, setTestData] = React.useState([]);
    const { data, refetch } = useClassesList({
        ...(onlyOne
            ? { ...defaultProps }
            : {
                with_name: true,
                title: searchOption,
                offset: page * countRef.current,
                limit: countRef.current,
            }),
        orderby: 'creation_time DESC',
    });
    const refetchList = throttle(() => refetch(), 1000);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = onlyOne
            ? [
                { title: 'className', space: 3 },
                { title: 'startDate', space: 1 },
                { title: 'endDate', space: 1 },
                { title: 'live_status', space: 1 },
                { title: 'etc', space: 1 },
            ]
            : [
                { title: 'siteName', space: 1 },
                { title: 'className', space: 3 },
                { title: 'subjectName', space: 1 },
                { title: 'teacherName', space: 1 },
                { title: 'live_duration', space: 2 },
                { title: 'participantMember', space: 1 },
                { title: 'live_status', space: 1 },
            ];
        const tableReadDataKeys = onlyOne
            ? [
                { title: 'title', space: 3 },
                { title: 'startline', space: 1 },
                { title: 'deadline', space: 1 },
                { title: 'status', space: 1 },
                { title: 'etc', space: 1 },
            ]
            : [
                { title: 'identifier', space: 1 },
                { title: 'title', space: 3 },
                { title: 'subject', space: 1 },
                { title: 'host_name', space: 1 },
                { title: 'duration', space: 2 },
                { title: 'members', space: 1 },
                { title: 'status', space: 1 },
            ];
        return { headTitleList, tableReadDataKeys };
    }, [onlyOne]);
    React.useEffect(() => {
        setPage(initPage);
    }, [initPage]);
    React.useEffect(() => {
        refetchList();
    }, [searchOption, page, countRef.current]);
    React.useEffect(() => {
        APP.eventManager.subscribe('UPDATE_KLASS_LIST', refetchList);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_KLASS_LIST', refetchList);
        };
    }, []);
    React.useEffect(() => {
        if (data) {
            const { total_count, items, found_count } = data;
            if (total_count > 0 && found_count === 0) {
                setPage(page - 1);
                return;
            }
            else {
                if (items.length > 0) {
                    setTestData(items);
                    setTotalCount(total_count);
                    return;
                }
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    const handlerTableClick = (title, item) => {
        switch (title) {
            case 'title':
                handlerSelectItem(item);
                break;
            case 'members':
                APP.eventManager.publish(TOGGLE_POPUP, {
                    component: MembersTable,
                    componentProps: {
                        id: item.id,
                        isPopup: true,
                        isModify: false,
                        defaultValues: [item.host, ...item.managers, ...item.members],
                        handlerUpdateMemberIds: (member) => {
                            console.log(member);
                        },
                    },
                    title: '참여자 추가',
                    width: 800,
                    isFit: true,
                });
                break;
        }
    };
    const handlerMoreData = (page) => {
        setPage(page);
        APP.eventManager.publish('UPDATE_PAGE', page);
    };
    const handlerSelectItem = (item) => {
        APP.eventManager.publish('CHANGE_MENU', {
            showDepth: 'detail',
            props: { id: item.id },
        });
    };
    const renderEmpty = React.useMemo(() => totalCount <= 0 && (React.createElement("div", { className: "empty_data" },
        React.createElement("p", null, "\uB4F1\uB85D\uB41C \uD074\uB798\uC2A4\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4."))), [totalCount]);
    const renderItem = (item) => {
        if (!item)
            return;
        return tableReadDataKeys.map((headItem, idx) => (React.createElement("div", { key: idx, className: "table-item", style: {
                flex: headItem.space,
                justifyContent: 'flex-start',
                alignItems: 'center',
                display: 'flex',
                overflow: 'hidden',
                cursor: headItem.title === 'title' || headItem.title === 'members'
                    ? 'pointer'
                    : 'default',
            }, onClick: () => handlerTableClick(headItem.title, item) }, (() => {
            switch (headItem.title) {
                case 'duration':
                    return (React.createElement("p", { className: "overText2" }, `${moment(item.startline).format('YYYY-MM-DD')} ~ ${moment(item.deadline).format('YYYY-MM-DD')}`));
                case 'title':
                    return (React.createElement("p", { className: `overText2 ${onlyOne ? '' : 'underline'}` }, item[headItem.title]));
                case 'status':
                    return (React.createElement("p", { className: "overText" }, item[headItem.title] === 'inactive'
                        ? t(`status.${item[headItem.title]}`)
                        : t(`status.active`)));
                case 'members':
                    return (React.createElement("p", { className: `overText2 ${onlyOne ? '' : 'underline underline_pink'}` }, "\uCC38\uC5EC\uC790 \uCD94\uAC00"));
                case 'startline':
                case 'deadline':
                    return (React.createElement("p", { className: "overText2" }, moment(item[headItem.title]).format('YYYY-MM-DD')));
                case 'etc':
                    return (React.createElement("p", { className: `overText2 ${classId === item.id ? 'underline_pink' : 'underline'}`, style: { cursor: 'pointer' }, onClick: () => setClassId(item.id) }, "\uC790\uC138\uD788 \uBCF4\uAE30"));
                default:
                    return React.createElement("p", { className: "overText2" }, item[headItem.title]);
            }
        })())));
    };
    return (React.createElement(Table, { className: `${onlyOne ? 'table-white-grey' : ''}`, showIndex: true, isInnerPage: true, isInifinite: false, page: page, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderItem: renderItem, renderEmpty: renderEmpty, handlerMoreData: handlerMoreData }));
});

import React, { useEffect } from 'react';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { DropDown } from '../../../../../common';
export const StateDropdown = React.memo(({ handlerFilterList, isTest, type, checkItem, setType, isTeacher, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const [dropdownList, setDropdownList] = React.useState([]);
    const [state, setState] = React.useState({
        id: 'searchCondition',
        title: type === 'status' || 'type'
            ? 'common.searchCondition'
            : 'assignment.searchCondition',
    });
    useEffect(() => {
        let list = [];
        if (type === 'status') {
            list = [
                {
                    id: 'search',
                    title: 'common.searchCondition',
                },
                {
                    id: 'closed',
                    title: 'assignment.closed',
                },
                {
                    id: 'open',
                    title: 'assignment.open',
                },
                {
                    id: 'created',
                    title: 'assignment.created',
                },
            ];
        }
        else if (type === 'type') {
            list = [
                {
                    id: 'search',
                    title: 'common.searchCondition',
                },
                {
                    id: 'OMR',
                    title: 'assignment.OMR',
                },
                {
                    id: 'OMR-free',
                    title: 'assignment.OMR-free',
                },
                {
                    id: 'attachment',
                    title: 'assignment.attachment',
                },
                {
                    id: 'survey',
                    title: 'assignment.survey',
                },
            ];
        }
        else if (type === '' || !type) {
            list = [
                {
                    id: 'searchCondition',
                    title: 'assignment.searchCondition',
                },
                {
                    id: 'title',
                    title: isTest ? 'assignment.testname' : 'assignment.subjectName',
                },
                ...(isTeacher
                    ? [
                        {
                            id: 'author',
                            title: 'assignment.register',
                        },
                    ]
                    : []),
                {
                    id: 'status',
                    title: 'assignment.state',
                },
                {
                    id: 'type',
                    title: 'assignment.questionType',
                },
            ];
        }
        const checkSelect = list.find(item => item.id === checkItem);
        let item = {
            id: 'searchCondition',
            title: type === 'status' || 'type'
                ? 'common.searchCondition'
                : 'assignment.searchCondition',
        };
        if (checkItem && checkSelect) {
            item = checkSelect;
        }
        setState(item);
        setDropdownList(list);
    }, [type, checkItem, isTest, isTeacher]);
    const handlerUpdateDrop = (item) => {
        if (dropdownList.length === 0)
            return;
        if (type === 'status') {
            handlerFilterList({ status: item.id === 'search' ? undefined : item.id });
        }
        else if (type === 'type') {
            handlerFilterList({ type: item.id === 'search' ? undefined : item.id });
        }
        else {
            handlerFilterList({ [item.id]: undefined });
        }
        setState(item);
    };
    return (React.createElement(DropDown, { className: isDesktop
            ? 'dropdown-small'
            : isTablet
                ? 'dropdown-tablet-small'
                : 'dropdown-mobile-small', value: state, list: dropdownList, styles: {
            dropWrap: {
                width: isDesktop ? 184 : isTablet ? 162 : undefined,
            },
            itemWrap: {
                top: isTablet && '50px',
            },
        }, setDropValue: handlerUpdateDrop }));
});

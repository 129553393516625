import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useGlobalState } from '../../../GlobalStateProvider';
import { Button } from '../../../common';
import { connectionConferenceList, deleteConferenceList, postConferenceList, updateConferenceList, useConferenceList, useEntryData, } from '../../../server';
import { DetailTitleInfo, MobileTabLayout } from '../../base/layout';
import { LiveReservationStyled } from './DSTTStyled';
import { ConferenceInfo, MembersTable, StudySettingTable, TestSettingTable, } from './component/DSTT001_Regist_Child';
import { SettingTable } from './component/DSTT001_Regist_Child/SettingTable';
export const DSTT001_Regist = React.memo(({ isDetail = false, isModify = false, isPrevious = false, isTeacher, id, }) => {
    const { t } = useTranslation();
    const { classesId } = useGlobalState();
    const [item, setItem] = useState();
    const [conferenceMember, setConferenceMember] = useState([]);
    const [conferenceInfoData, setConferenceInfoData] = useState();
    const [isClosed, setIsClosed] = useState(false);
    // 참여자 목록
    const [memberData, setMemberData] = useState([]);
    // 테스트 설정
    const [testSettingData, setTestSettingData] = useState([]);
    // 학습 자료 설정
    const [studySettingData, setStudySettingData] = useState([]);
    // 1:1 라이브
    const [liveMemberName, setLiveMemberName] = useState([]);
    const [liveMember, setLiveMember] = useState([]);
    const [isLiveCheck, setIsLiveCheck] = React.useState(false);
    const [entryDatas, setEntryDatas] = useState([]);
    const [background, setBackground] = useState([]);
    const [load, setLoad] = useState(false);
    // 제외된 참여자 목록
    const [excludeMembers, setExcludeMembers] = useState([]);
    const setConferenceMutation = useMutation(postConferenceList);
    const updateConferenceMutation = useMutation(updateConferenceList);
    const connectionConferenceData = useMutation(connectionConferenceList);
    const deleteMutation = useMutation(deleteConferenceList);
    const { data, refetch } = useConferenceList({
        id,
        with_more_info: true,
    });
    const { data: entryData, refetch: entryDataRefetch } = useEntryData({
        ...(isDetail ? { id: id } : {}),
    });
    useEffect(() => {
        entryDataRefetch();
    }, [isDetail]);
    useEffect(() => {
        if (!id) {
            setLoad(true);
            return;
        }
        setLoad(false);
        refetch();
    }, [id]);
    useEffect(() => {
        if (entryData) {
            const { items: entryItems } = entryData;
            setEntryDatas(entryItems);
        }
    }, [entryData]);
    useEffect(() => {
        if (data) {
            const { items } = data;
            if (items && items.length > 0) {
                const item = items[0];
                setIsClosed(item.status === 'closed'
                    ? true
                    : moment().isBetween(moment(item.start_time).subtract(item.pre_entering_duration, 'minutes'), moment(item.start_time)));
                setItem(item);
                setConferenceInfoData({
                    title: item.title,
                    conferenceMode: item.type,
                    date: item.start_time,
                    duration: item.progress_duration.toString(),
                    entryDate: item.pre_entering_duration.toString(),
                });
                setConferenceMember(item.members);
                const backId = item.background.split('/')[6];
                setBackground([backId]);
                setStudySettingData(backId ? [...item.contents, backId] : [...item.contents]);
                // setLiveMember(item.qna_managers);
                setLoad(true);
                return;
            }
        }
        setConferenceInfoData({
            title: '',
            conferenceMode: undefined,
            date: undefined,
            duration: '60',
            entryDate: '10',
        });
        setConferenceMember([]);
        setStudySettingData([]);
        setLiveMemberName([]);
        setLiveMember([]);
    }, [data]);
    const previousPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: undefined,
            id: undefined,
        });
    };
    const EditPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'modify',
            id: id,
        });
    };
    const onSubmit = async () => {
        let conference_id = id;
        APP.eventManager.publish('SHOW_LOADING', true);
        try {
            if (!conferenceInfoData?.title) {
                throw new Error(t('error.emptyTitle'));
            }
            if (moment(conferenceInfoData?.date).isBefore(moment())) {
                throw new Error(t('error.lateNowConference'));
            }
            if (parseInt(conferenceInfoData?.entryDate) <= 0) {
                throw new Error(t('error.noEnterTime'));
            }
            if (parseInt(conferenceInfoData?.duration) <= 0) {
                throw new Error(t('error.noProgressTime'));
            }
            if (conferenceInfoData?.conferenceMode === 'mock_test') {
                if (testSettingData.length > 1) {
                    throw new Error(t('error.onlyOneTest'));
                }
                if (testSettingData.length <= 0) {
                    throw new Error(t('error.onlyOneTest'));
                }
                if (background && background.length > 1) {
                    throw new Error(t('error.onlyOneBackground'));
                }
                if (background && background.length <= 0) {
                    throw new Error(t('error.noSelectBackground'));
                }
            }
            else {
                if (background && background.length > 0) {
                    throw new Error(t('error.noUseBackground'));
                }
            }
            const data = {
                class_id: classesId,
                // members: memberData.map(item => ({
                // 	user_id: item.id,
                // 	role_name: item.role_name,
                // })),
                exclude_members: excludeMembers,
                pre_entering_duration: parseInt(conferenceInfoData?.entryDate) || 60,
                progress_duration: parseInt(conferenceInfoData?.duration) || 5,
                qna_managers: liveMember,
                start_time: conferenceInfoData?.date,
                title: conferenceInfoData?.title,
                ...(!isModify
                    ? {
                        type: conferenceInfoData?.conferenceMode,
                        display_member_count: false,
                    }
                    : {}),
            };
            if (isModify) {
                await updateConferenceMutation.mutateAsync({ id, props: data });
            }
            else {
                const response = await setConferenceMutation.mutateAsync(data);
                conference_id = response.result.id;
            }
            let updateConnectionConferencePromise;
            const filterBackground = studySettingData.filter(item => !background.includes(item));
            if ((filterBackground && filterBackground.length > 0) ||
                (testSettingData && testSettingData.length > 0)) {
                updateConnectionConferencePromise = connectionConferenceData.mutateAsync({
                    id: conference_id,
                    props: {
                        tests: testSettingData,
                        contents: [...(filterBackground ? filterBackground : [])],
                        ...(conferenceInfoData?.conferenceMode === 'mock_test'
                            ? {
                                background: background && background.length > 0
                                    ? background[0]
                                    : '',
                            }
                            : {}),
                    },
                });
            }
            await Promise.all([updateConnectionConferencePromise]);
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'list',
            });
        }
        catch (error) {
            console.error('Error during conference submission:', error);
            if (conference_id && !isModify) {
                try {
                    await deleteMutation.mutateAsync(conference_id);
                }
                catch (deleteError) {
                    console.error('Failed to delete conference after error:', deleteError);
                }
            }
            alert(error.message || error);
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    const { isTablet, isMobile, isDesktop } = useGlobalState();
    // 회의실 정보
    const renderConferenceInfo = useMemo(() => {
        if (id
            ? !conferenceInfoData?.title || conferenceInfoData?.title.trim() === ''
            : !conferenceInfoData)
            return;
        return (React.createElement(ConferenceInfo, { defaultItem: conferenceInfoData, setItem: setConferenceInfoData, isDetail: isDetail, isModify: isModify }));
    }, [id, conferenceInfoData, isDetail, isModify]);
    // 참여자 설정
    const renderMemberTable = useMemo(() => (React.createElement(MembersTable, { isModify: isModify, isDetail: isDetail, initMember: conferenceMember, setItem: setMemberData, allChecked: !isDetail && !isModify ? true : false, entryData: entryDatas && isDetail && entryDatas, setExclude: setExcludeMembers })), [isModify, isDetail, conferenceMember, entryDatas]);
    // 테스트 자료 설정
    const renderTestSetting = useMemo(() => {
        return (React.createElement(TestSettingTable, { isDetail: isDetail, isModify: isModify, conference_id: id, item: testSettingData, setItem: setTestSettingData }));
    }, [isModify, isDetail, testSettingData, id]);
    const renderStudySetting = useMemo(() => {
        if (!load)
            return;
        return (React.createElement(StudySettingTable, { isDetail: isDetail, isModify: isModify, initItem: studySettingData, setItem: setStudySettingData, setBackground: setBackground, mode: conferenceInfoData?.conferenceMode }));
    }, [load, conferenceInfoData?.conferenceMode, isDetail, isModify, studySettingData]);
    // 현재 탭 위치 및 이전으로 돌아가기
    const renderInfoTitle = useMemo(() => (React.createElement(DetailTitleInfo, { title: isDetail
            ? t(`conference.${isPrevious ? 'lastClass' : 'reservation'}`)
            : t('conference.reservation'), name: t(`common.${isDetail ? 'detail' : isModify ? 'modify' : 'regist'}`), previousPage: previousPage })), []);
    const renderSetting = useMemo(() => isDetail ? (React.createElement("div", { className: "field-global-001", style: { width: isDesktop ? '80%' : '100%' } },
        React.createElement("div", { className: "task-label", style: { minWidth: isMobile ? '100px' : '110px' } }, t('conference.liveQAndARoom')),
        React.createElement("div", { className: "fext-field setting_box" }, item?.qna_managers.map((member, index) => member.name &&
            member.name !== '' && (React.createElement("div", { key: index, className: "btn-file" },
            React.createElement("span", { className: "name overText" },
                " ",
                member.name))))))) : (React.createElement(SettingTable, { initLiveCheck: isLiveCheck, defaultLiveMember: item?.qna_managers, initLiveMember: liveMember, setInitLiveCheck: setIsLiveCheck, setLiveMember: setLiveMember })), [isDetail, item?.qna_managers, liveMember, isLiveCheck]);
    // 저장 버튼 가져오기
    const renderBtnContainer = useMemo(() => (React.createElement("div", { className: "btn-container", style: { marginBottom: isMobile ? '12px' : 'unset' } },
        !isClosed &&
            isTeacher &&
            item &&
            !moment().isBetween(moment(item.start_time).subtract(item.pre_entering_duration, 'minutes'), moment(item.start_time).add(item.progress_duration, 'minutes')) && (React.createElement(Button, { className: "btn_white xmd", text: "common.modify", onClick: EditPage })),
        React.createElement(Button, { className: "btn_line_pink xmd", text: "common.cancel", onClick: previousPage }),
        !isDetail && (React.createElement(Button, { className: "btn_default xmd", text: "common.okay", onClick: onSubmit })))), [
        isClosed,
        isTeacher,
        isDetail,
        id,
        conferenceInfoData,
        liveMember,
        studySettingData,
        testSettingData,
        background,
    ]);
    if (isTablet || isMobile) {
        const tabList = [
            {
                id: 1,
                title: '강의 정보',
                component: (React.createElement(React.Fragment, null,
                    renderConferenceInfo,
                    isDetail && renderSetting)),
                styles: {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: isTablet ? '24px' : '12px',
                },
            },
            {
                id: 2,
                title: '참여자 리스트',
                component: renderMemberTable,
            },
            {
                id: 3,
                title: '테스트 설정',
                component: renderTestSetting,
            },
            {
                id: 4,
                title: '학습 자료 설정',
                component: renderStudySetting,
            },
        ];
        if (!isDetail) {
            tabList.push({
                id: 5,
                title: '설정',
                component: (React.createElement(React.Fragment, null,
                    renderSetting,
                    !isMobile && renderBtnContainer)),
                styles: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                },
            });
        }
        return (React.createElement(LiveReservationStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: tabList, footer: isMobile ? undefined : renderInfoTitle, showPageButton: isMobile ? true : false, showNav: isMobile ? false : true, showPageTab: isMobile ? true : false, submitAssignment: isDetail ? previousPage : onSubmit })));
    }
    return (React.createElement(React.Fragment, null,
        renderInfoTitle,
        React.createElement(LiveReservationStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement("div", { className: "container-001" },
                renderConferenceInfo,
                isDetail && renderSetting),
            React.createElement("div", { className: "container-002" },
                renderMemberTable,
                isDetail ? (React.createElement(React.Fragment, null,
                    renderTestSetting,
                    renderStudySetting,
                    !isDetail && renderSetting)) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "setting-list" },
                        renderTestSetting,
                        renderStudySetting),
                    !isDetail && renderSetting)))),
        renderBtnContainer));
});

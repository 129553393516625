import React, { useMemo } from 'react';
import { Button } from '../../common';
import { BACK_MENUT_LIST, CLASS_MENUT_LIST, SERVICE_MENUT_LIST, STATISTICS_MENUT_LIST, } from './constant';
export const AdminMenu = React.memo(({ menu, menuDetail, setMenuDetail, }) => {
    const menus = useMemo(() => {
        switch (menu) {
            case 'serviceManagment':
                return SERVICE_MENUT_LIST;
            case 'classManagment':
                return CLASS_MENUT_LIST;
            case 'backOffice':
                return BACK_MENUT_LIST;
            case 'statistics':
                return STATISTICS_MENUT_LIST;
            default:
                return [];
        }
    }, [menu]);
    return (React.createElement("div", null, menus.map(item => (React.createElement(Button, { key: item, className: `admin-sidebar-btn ${menuDetail === item ? 'active' : ''}`, text: `menu.${item}`, onClick: () => {
            setMenuDetail(item);
            APP.eventManager.publish('CHANGE_MENU', {
                showDepth: 'list',
                props: {},
            });
        } })))));
});

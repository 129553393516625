import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAnswerList } from '../../../../server';
import { getIsTeacher } from '../../../base/account';
import { AttachFileAnswer } from './AttachFileAnswer';
export const AttachFileContainer = ({ member_id, test_id, item, isPreview, submitted, }) => {
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const [answerList, setAnswerList] = React.useState([]);
    const [attachFileList, setAttachFileList] = useState([]);
    const [submittedFileList, setSubmittedFileList] = useState([]);
    const { data: submittedAnswers, refetch: refetchAnswerData } = useAnswerList({
        test_id,
        from: member_id || '',
        order: 'value',
    });
    const questionFileList = useMemo(() => item?.attachments || [], [item?.attachments]);
    useEffect(() => {
        if (test_id && member_id !== '') {
            refetchAnswerData();
        }
        else {
            setAnswerList([]);
        }
    }, [isPreview, test_id, member_id]);
    useEffect(() => {
        if (member_id === '') {
            setAnswerList([]);
            return;
        }
        if (submittedAnswers) {
            setAnswerList(submittedAnswers.items);
        }
        else {
            setAnswerList([]);
        }
    }, [member_id, submittedAnswers]);
    useEffect(() => {
        if (!answerList || answerList.length === 0)
            return;
        const valuesArray = answerList.map(item => item?.value);
        setSubmittedFileList(valuesArray);
    }, [answerList]);
    if (isTeacher && member_id === '') {
        return (React.createElement("div", { className: "feedback-file" },
            React.createElement(AttachFileAnswer, { isTeacher: isTeacher, showRegist: false, isRegist: false, attachFileList: questionFileList })));
    }
    else {
        if (submitted) {
            return (React.createElement("div", { className: "feedback-file" },
                React.createElement(AttachFileAnswer, { isTeacher: isTeacher, showRegist: false, isRegist: false, attachFileList: submittedFileList })));
        }
        if (!isPreview) {
            return (React.createElement("div", { className: "submit-false" },
                React.createElement("p", null, "\uC81C\uCD9C\uB41C \uD30C\uC77C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.")));
        }
        return (React.createElement("div", { className: "feedback-file" },
            React.createElement(AttachFileAnswer, { isTeacher: isTeacher, showRegist: false, isRegist: false, attachFileList: attachFileList })));
    }
};

import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import { CheckBox } from '../../../../../common';
export const FilterType = React.memo(({ handlerFilterList, isTest = false, searchCheckItem, isTeacher = false, type, setType }) => {
    const checkBoxRef = useRef(null);
    const checkboxList = useMemo(() => {
        const baseList = [
            {
                id: 'title',
                title: isTest ? 'assignment.testname' : 'assignment.subjectName',
                checked: true,
            },
            { id: 'status', title: 'assignment.state', checked: false },
            { id: 'type', title: 'assignment.questionType', checked: false },
        ];
        if (isTeacher) {
            baseList.splice(1, 0, {
                id: 'author',
                title: 'assignment.register',
                checked: false,
            });
        }
        return baseList;
    }, [isTest, isTeacher]);
    const [list, setList] = React.useState(checkboxList);
    useEffect(() => {
        if (type === '') {
            if (checkBoxRef.current) {
                checkBoxRef.current.reset();
            }
        }
        setList(checkboxList.map(item => ({
            ...item,
            checked: item.id === (type || searchCheckItem),
        })));
    }, [checkboxList, type, searchCheckItem]);
    useEffect(() => {
        if (checkBoxRef.current) {
            checkBoxRef.current.reset();
        }
    }, [isTest]);
    const handlerCheck = useCallback((id) => {
        setType(id);
        const checkedItem = list.find(item => id);
        if (checkedItem) {
            if (type)
                handlerFilterList({ [type]: searchCheckItem });
        }
    }, [setType]);
    return (React.createElement(CheckBox, { isRadioButton: true, list: list, handlerCheck: handlerCheck, defaultCheckId: searchCheckItem, ref: checkBoxRef }));
});

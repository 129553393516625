import React from 'react';
import { Button } from '../../../common';
import { StatisticsList } from './StatisticsList';
export const UserInfo = React.memo(({ id }) => {
    return (React.createElement("div", { className: "admin-detail-info" },
        React.createElement("div", { className: "admin-title" },
            React.createElement("div", { className: "admin-title-left" },
                React.createElement("p", null, "\uC0AC\uC6A9\uC790 \uC815\uBCF4")),
            React.createElement("div", { className: "admin-title-right" },
                React.createElement(Button, { className: "btn btn-dark-grey btn-xmd btn-semi-round", text: "common.download" }))),
        React.createElement(StatisticsList, { onlyOne: true, searchOption: "", initPage: 0, id: id })));
});

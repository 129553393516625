import React, { useEffect } from 'react';
import { DropDown } from '../../../../../common';
import { useGradesList } from '../../../../../server';
import throttle from 'lodash/throttle';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const default_drop = { id: 'select', title: 'assignment.selectSubject' };
export const ChoiceSubject = React.memo(({ test_id, setSubjectId }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const [dropValue, setDropValue] = React.useState(default_drop);
    const [dropList, setDropList] = React.useState([]);
    const { data, refetch } = useGradesList({
        test_id,
        member_id: '',
        orderby: ["IF(subject_item='공통',0,1)", 'subject_item'],
    });
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        refetchList();
    }, [test_id]);
    useEffect(() => {
        if (!data)
            return;
        const { total_count, items } = data;
        if (total_count === 0) {
            setDropList([default_drop]);
            return;
        }
        const list = items
            .map(({ subject_item }) => ({
            id: subject_item,
            title: subject_item,
        }))
            .filter(item => item.id !== '');
        setDropList([default_drop, ...list]);
    }, [data]);
    useEffect(() => {
        setSubjectId(dropValue.id === 'select' ? undefined : dropValue.id);
    }, [dropValue.id]);
    useEffect(() => {
        APP.eventManager.subscribe('RESET_SUBJECT', refetchList);
        return () => {
            APP.eventManager.unsubscribe('RESET_SUBJECT', refetchList);
        };
    }, []);
    return (React.createElement(DropDown, { className: `dropdown-${isMobile ? 'mobile-small' : isTablet ? 'tablet' : 'small'} `, value: dropValue, list: dropList, setDropValue: setDropValue, styles: {
            dropWrap: {
                width: isDesktop ? 184 : undefined,
                flex: isTablet ? 1 : undefined,
            },
            itemWrap: {
                maxHeight: 300,
                top: isMobile && 44,
            },
        } }));
});

import { getGrades } from '../../../server';
import { objectToQueryString } from '../../../server/functions';
export const getSelectSubject = async (data, noFilterCommon) => {
    const queryString = objectToQueryString({ ...data, orderby: 'subject_item' });
    const grades = await getGrades(queryString);
    const { items } = grades.result;
    const list = items.map(({ subject_item }) => subject_item);
    if (noFilterCommon)
        return list;
    // 공통과목 제거
    const filteredList = list.filter((item) => item !== '공통');
    return filteredList;
};

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../Colors';
import { useScoreStatus } from '../../../../server';
import { SubjectListBox } from './SubjectListBox';
import { SubmitResultBox } from './SubmitResultBox';
import { useGlobalState } from '../../../../GlobalStateProvider';
const SubmitResultStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isHome ? '12px' : '21px')};
	align-items: ${props => (props.$isHome ? 'center' : 'flex-start')};
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	max-height: 300px;
	max-width: auto;

	.contents {
		display: flex;
		flex-direction: row;
		gap: 24px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		overflow: hidden;
	}

	.category-container {
		border-radius: 8px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: flex-start;
		align-self: ${props => (props.$isHome ? 'unset' : 'stretch')};
		flex-shrink: 0;
		position: relative;
		overflow: auto;
		flex-wrap: wrap;
	}

	.result-box {
		display: flex;
		flex-direction: row;
		border-radius: 8px;
		border-style: solid;
		border-color: var(--color-hr, #e6f0f1);
		border-width: ${props => (props.$isHome ? '1px' : '4px')};
		justify-content: ${props => props.$isHome && 'center'};
		width: ${props => props.$isHome && '100%'};
		gap: ${props => props.$isHome && '10px'};
		padding: ${props => props.$isHome && '10px 20px 10px 20px'};
		max-width: 280px;
	}
`;
const tabletStyles = css `
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isHome ? '12px' : '21px')};
	align-items: ${props => (props.$isHome ? 'center' : 'flex-start')};
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	max-width: ${props => (props.$isHome ? '310px' : 'auto')};

	.contents {
		display: flex;
		flex-direction: ${props => (props.$isHome ? 'column' : 'row')};
		gap: ${props => (props.$isHome ? '2px' : '24px')};
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		width: 100%;
	}

	.category-container {
		flex: 1;
		border-radius: 8px;
		border-style: ${props => !props.$isHome && 'solid'};
		border-color: ${props => !props.$isHome && 'var(--color-silver, #f3f4f8)'};
		border-width: 2px;
		padding: ${props => (props.$isHome ? '10px 0' : '16px')};
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: auto;
		flex-wrap: wrap;
		min-height: 197px;
	}

	.result-box {
		display: flex;
		flex-direction: row;
		border-radius: 8px;
		border-style: solid;
		border-color: var(--color-hr, #e6f0f1);
		border-width: ${props => (props.$isHome ? '1px' : '4px')};
		justify-content: ${props => props.$isHome && 'center'};
		width: ${props => props.$isHome && '100%'};
		gap: ${props => props.$isHome && '10px'};
		padding: ${props => props.$isHome && '15px 32px'};
	}

	.task-label {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 18px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
`;
const desktopStyles = css `
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isHome ? '12px' : '21px')};
	align-items: ${props => (props.$isHome ? 'center' : 'flex-start')};
	justify-content: flex-start;
	align-self: stretch;
	max-width: ${props => (props.$isHome ? '310px' : 'auto')};

	.contents {
		display: flex;
		flex-direction: ${props => (props.$isHome ? 'column' : 'row')};
		gap: ${props => (props.$isHome ? '2px' : '24px')};
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
	}

	.category-container {
		flex: 1;
		border-radius: 8px;
		border-style: ${props => !props.$isHome && 'solid'};
		border-color: ${props => !props.$isHome && 'var(--color-silver, #f3f4f8)'};
		border-width: 2px;
		padding: ${props => (props.$isHome ? '10px 0' : '22px 32px')};
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: auto;
	}

	.result-box {
		display: flex;
		flex-direction: row;
		border-radius: 8px;
		border-style: solid;
		border-color: var(--color-hr, #e6f0f1);
		border-width: ${props => (props.$isHome ? '1px' : '4px')};
		justify-content: ${props => props.$isHome && 'center'};
		width: ${props => props.$isHome && '100%'};
		gap: ${props => props.$isHome && '10px'};
		padding: ${props => props.$isHome && '15px 32px'};
	}

	.task-label {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 18px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
`;
export const SubmitResult = React.memo(({ isSubmit, selectName, test_id, member_id, isHome = false, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [grades, setGrades] = useState(new Map());
    const [allGrades, setAllGrades] = useState({
        score: 0,
        correctIndex: 0,
        allIndex: 0,
        allScore: 0,
    });
    const { data: scoreData, refetch: scoreRefetch } = useScoreStatus({
        id: test_id,
        member_id,
        valid_correction: true,
    });
    useEffect(() => {
        if (!scoreData)
            return;
        let result = new Map();
        let allResult = { score: 0, correctIndex: 0, allIndex: 0, allScore: 0 };
        scoreData.forEach((item) => {
            const score = item.correction === 'O' ? item.point : 0;
            const correctIndex = item.correction === 'O' ? 1 : 0;
            const allScore = item.point;
            const allIndex = 1;
            if (result.has(item.subject)) {
                const existing = result.get(item.subject);
                result.set(item.subject, {
                    score: score + existing.score,
                    correctIndex: correctIndex + existing.correctIndex,
                    allScore: allScore + existing.allScore,
                    allIndex: existing.allIndex + allIndex,
                });
            }
            else {
                result.set(item.subject, { score, correctIndex, allScore, allIndex });
            }
            allResult = {
                score: allResult.score + score,
                correctIndex: allResult.correctIndex + correctIndex,
                allScore: allResult.allScore + allScore,
                allIndex: allResult.allIndex + allIndex,
            };
        });
        setAllGrades(allResult);
        setGrades(result);
    }, [scoreData]);
    useEffect(() => {
        scoreRefetch();
    }, [test_id, member_id, isSubmit]);
    const renderResult = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement(SubmitResultBox, { iconOptions: { icon: 'check-circle', size: 36, fill: Colors.tur }, title: "assignment.correct", score: allGrades.correctIndex, total: allGrades.allIndex, isHome: isHome }),
        isHome && React.createElement("div", { className: "line" }),
        React.createElement(SubmitResultBox, { iconOptions: { icon: 'check-equal', size: 36, fill: Colors.pur }, title: "assignment.rawScore", score: allGrades.score, total: allGrades.allScore, isHome: isHome }))), [allGrades, isHome]);
    const renderResultData = useMemo(() => grades.size !== 0 && (React.createElement("div", { className: "category-container" }, Array.from(grades.entries()).map(([key, item]) => (React.createElement(SubjectListBox, { key: key, subject_item: key, score: item.score || 0, isHome: isHome }))))), [grades]);
    return (React.createElement(SubmitResultStyled, { className: "field-global-001 submit-result", "$isHome": isHome, "$isMobile": isMobile, "$isTablet": isTablet },
        !isMobile && (React.createElement("div", { className: "task-label", style: { overflow: isMobile && 'unset', width: isMobile && 'unset' } },
            React.createElement("p", { className: "overText", style: {
                    color: isHome ? Colors.dark_grey : Colors.black,
                    fontSize: isDesktop && '18px',
                } }, `${t('assignment.submitResult')} ${selectName ? `- (${selectName})` : ''}`))),
        React.createElement("div", { className: "fext-field", style: { flex: isMobile && 0 } },
            React.createElement("div", { className: "contents" },
                isHome ? React.createElement("div", { className: "result-box" }, renderResult) : renderResult,
                !isMobile && renderResultData)),
        isMobile && renderResultData));
});

import React from 'react';
import { Button } from '../../../common';
import { SearchInput } from '../ui/SearchInput';
export const KlassListTitle = React.memo(({ searchOption, setSearchOption, }) => {
    const onRegistCompany = () => {
        APP.eventManager.publish('CHANGE_MENU', {
            showDepth: 'regist',
            props: {},
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: "btn-round btn-peach btn-xmd", text: "\uD074\uB798\uC2A4 \uB4F1\uB85D", onClick: onRegistCompany }),
        React.createElement(SearchInput, { defaultValue: searchOption, setSearchOption: setSearchOption })));
});

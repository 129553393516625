import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, FileInput } from '../../../../common';
import { Colors } from '../../../../Colors';
import { useGlobalState } from '../../../../GlobalStateProvider';
const AttachFileRegistStyled = styled.div `
	width: 100%;

	.attach-field {
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 15px;
	}
`;
export const AttachFileRegist = React.memo(({ isAnswer, isTeacher, isRequestion, isRegist, defaultFileList, handlerFileUpdate, }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    const fileRef = React.useRef(null);
    const [attachFileName, setAttachFileName] = React.useState([]); //첨부파일 이름
    const [attachFileList, setAttachFileList] = React.useState([]); // 첨부파일
    useEffect(() => {
        defaultFileList && setAttachFileList(defaultFileList);
    }, [defaultFileList]);
    //첨부파일 이름 저장
    useEffect(() => {
        if (attachFileList && attachFileList.length > 0) {
            attachFileList.map((file, index) => {
                if (typeof file === 'string') {
                    const name = file.split('/')[file.split('/').length - 1];
                    setAttachFileName([...attachFileName, name]);
                    return;
                }
                setAttachFileName([...attachFileName, file.name]);
            });
        }
        handlerFileUpdate && handlerFileUpdate(attachFileList);
    }, [attachFileList]);
    // (onClick) 첨부 파일 삭제
    const deleteAttachFile = (item) => {
        setAttachFileList(attachFileList.filter(file => file !== item));
    };
    const downloadFile = (url) => {
        const a = document.createElement('a');
        a.href = url;
        a.download = url.split('/').pop() || 'download'; // 다운로드 파일명 설정
        document.body.appendChild(a); // a 요소를 문서에 추가
        a.click();
        document.body.removeChild(a); // a 요소를 문서에서 제거
    };
    // (onChange) 첨부 파일 추가
    const onChangeFile = (e) => {
        const file = e.target.files[0];
        setAttachFileList([...attachFileList, file]);
        if (fileRef.current)
            fileRef.current.value = '';
    };
    //드래그 앤 드롭 파일 막기
    const handleDrop = e => {
        e.preventDefault();
        const dt = e.dataTransfer;
        // 드롭된 항목이 폴더인지 파일인지 확인
        for (let i = 0; i < dt.items.length; i++) {
            const item = dt.items[i];
            if (item.kind === 'file') {
                const file = item.getAsFile();
                // 파일 확장자 확인 (예시: mp4, mov 확장자만 허용)
                if (file.type.startsWith('video/') &&
                    (file.type.includes('mp4') || file.type.includes('quicktime'))) {
                    // 파일 처리 로직 추가
                }
                else {
                    alert('폴더를 등록할 수 없습니다.');
                    return;
                }
            }
            else {
                alert('폴더를 등록할 수 없습니다.');
                return;
            }
        }
    };
    const handleDragOver = e => {
        e.preventDefault();
    };
    const attachFilesBox = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { onDrop: handleDrop, onDragOver: handleDragOver, className: "box", style: isAnswer
                    ? { width: '100%', minHeight: 50 }
                    : isTeacher && !isRegist
                        ? { width: '100%', maxHeight: 100 }
                        : { width: '100%' } },
                React.createElement("div", { style: {
                        width: isAnswer ? '85%' : '100%',
                        display: 'inline-flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        gap: '10px',
                    } }, attachFileList.length > 0 ? (attachFileList.map((item, index) => (React.createElement(Button, { key: index, className: "btn_sliver md_round center body5 cursor attach_item", text: typeof item === 'string'
                        ? item.split('/')[item.split('/').length - 1]
                        : item.name, styles: {
                        buttonWrap: {
                            height: 'fit-content',
                            maxWidth: isMobile ? 180 : 220,
                        },
                    }, option: {
                        buttonBeforeIcon: {
                            name: 'file',
                            color: Colors.dark_tur,
                            size: 20,
                            show: true,
                        },
                        buttonAfterIcon: {
                            name: !isRegist &&
                                !isAnswer &&
                                typeof item === 'string'
                                ? 'file-down'
                                : 'cancel',
                            color: Colors.accent,
                            size: !isRegist &&
                                !isAnswer &&
                                typeof item === 'string'
                                ? 16
                                : 12,
                            show: isRegist || isAnswer
                                ? true
                                : typeof item === 'string',
                        },
                    }, onClick: isRegist || isAnswer
                        ? () => deleteAttachFile(item)
                        : typeof item === 'string'
                            ? () => downloadFile(item)
                            : () => { } })))) : (React.createElement("p", { className: "body5", style: { color: Colors.light_grey, width: '85%' } }, isTeacher && isRegist
                    ? t('assignment.attachFilePlaceholer')
                    : t('assignment.noAttachFilePlaceholer')))),
                isAnswer && (React.createElement(FileInput, { ref: fileRef, id: "attach_file", onChange: onChangeFile },
                    React.createElement(Button, { className: "btn_tur sm", text: isAnswer ? 'supportRoom.regist' : 'common.regist', styles: {
                            buttonWrap: {
                                width: '60px',
                                height: '30px',
                            },
                        } })))),
            isRegist && (React.createElement(FileInput, { ref: fileRef, id: "attach_file", onChange: onChangeFile },
                React.createElement(Button, { className: "btn_tur sm", text: "common.regist", styles: {
                        buttonWrap: {
                            height: !isTeacher && '45px',
                        },
                    } })))));
    };
    return (React.createElement(AttachFileRegistStyled, null,
        React.createElement("div", { className: "field-global-001", style: isRequestion ? { gap: '48px' } : { alignItems: 'flex-start' } },
            React.createElement("div", { className: "task-label" }, t('assignment.attachFile')),
            isRegist ? (React.createElement("div", { className: "attach-field" }, attachFilesBox())) : (attachFilesBox()))));
});

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getClassesList } from '../../../server/classes';
import { DSSL007_List } from '../../DSS/DSSL007/DSSL007_List';
import { setClassesData } from '../../base/classes';
import { KlassInfo } from './KlassInfo';
import { UserInfo } from './UserInfo';
export const StatisticsItem = React.memo(({ id }) => {
    const dispatch = useDispatch();
    const [classId, setClassId] = React.useState(undefined);
    useEffect(() => {
        return () => {
            dispatch(setClassesData(undefined));
        };
    }, []);
    const handlerUpdateClassId = async (classId) => {
        const classData = await getClassesList({ id: classId });
        const { total_count, items } = classData.result;
        if (total_count > 0) {
            const classes_data = items.find(i => i.id === classId);
            dispatch(setClassesData(classes_data));
            setClassId(classId);
        }
        else {
            dispatch(setClassesData(undefined));
            setClassId(null);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "admin-main", style: { gap: 48 } },
            React.createElement(UserInfo, { id: id }),
            React.createElement(KlassInfo, { id: id, classId: classId, setClassId: handlerUpdateClassId }),
            classId ? (React.createElement(DSSL007_List, { isAdmin: true, classId: classId, selectUserId: id })) : null)));
});

import React, { useState } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { GradeDropDown, StatusTable } from './DSSL007_List_Child';
import { GradeCategoryDropDown } from './DSSL007_List_Child/GradeCategoryDropDown';
const DSSL007ListStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	position: relative;
	overflow-y: ${props => (props.$isAdmin ? '' : 'auto')};
	width: 100%;
	height: 100%;

	.select-bar {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		padding: ${props => (props.$isDesktop ? ' 0px 0px 32px 0px' : ' 0px 0px 20px 0px')};
		display: flex;
		flex-direction: row;
		gap: 24px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.field-short {
			justify-content: center;
			align-items: center;
			${props => props.$isMobile && `flex-direction: column;`}

			.field-global-001 {
				flex: 0;
				justify-content: center;

				${props => props.$isMobile
    ? `width: 100%; flex-direction: column; align-items: flex-start;`
    : ``}

				.task-label {
					white-space: break-spaces;
					${props => (props.$isDesktop ? `width: 130px;` : `width: 100px`)}
				}

				.fext-field {
					${props => (props.$isMobile ? `width: 100%;` : `width: auto; flex: 0;`)}
				}
			}
		}
	}

	.attend-container {
		display: flex;
		${props => props.$isMobile
    ? `
            flex-direction: column;
            gap: 24px;
            align-items: center;
            justify-content: flex-start;
			flex: 1

        `
    : props.$isTablet
        ? `
            flex-direction: row;
			gap: 30px;
            align-items: flex-start;
            justify-content: flex-start;
			overflow: hidden;

        `
        : `
            flex-direction: row;
            gap: 47px;
            align-items: flex-start;
            justify-content: flex-start;
			overflow: hidden;


        `}

		flex-shrink: 0;
		position: relative;
		width: 100%;
	}
`;
const LIST = [
    {
        title: 'attend',
        color: '#f46790',
    },
    { title: 'test', color: '#3ab2bf' },
    { title: 'task', color: '#458c94' },
    { title: 'reply', color: '#458c94' },
];
export const DSSL007_List = React.memo(({ isAdmin = false, classId, selectUserId, }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [selectMenu, setSelectMenu] = useState('');
    const [selectDay, setSelectDay] = useState('');
    return (React.createElement(DSSL007ListStyled, { "$isAdmin": isAdmin, "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
        React.createElement("div", { className: "select-bar" },
            React.createElement("div", { className: "field-short", style: { justifyContent: 'center' } },
                React.createElement(GradeDropDown, { setDropValue: setSelectDay }),
                (isMobile || isTablet) && (React.createElement(GradeCategoryDropDown, { defaultValue: 'attend', handlerUpdateValue: setSelectMenu })))),
        LIST.map((item, index) => !isAdmin && item.title === 'reply' ? null : !isDesktop &&
            selectMenu !== item.title ? null : (React.createElement(StatusTable, { key: index, ...item, classId: classId, selectUserId: selectUserId, selectDay: selectDay })))));
});

import { throttle } from 'lodash';
import React, { useEffect } from 'react';
import { useAnswerList, useAssignmentList } from '../../../../server';
import { getClassesId } from '../../../base/classes';
import { ErrataTable } from '../../../DST/DSTL001/component/DSTL001_View_Child';
import { AttachmentQuestionTable } from './AttachmentQuestionTable';
import { DetailQuestionTable } from './DetailQuestionTable';
import { SurveyQuestionTable } from './SurveyQuestionTable';
export const SubmissionPopup = React.memo(({ test_id, member_id, props }) => {
    const classesId = getClassesId();
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [item, setItem] = React.useState(null);
    const { data, refetch } = useAssignmentList({ id: test_id, class_id: classesId });
    const { data: answerData, refetch: answerRefetch } = useAnswerList({
        test_id,
        from: member_id,
        orderby: 'value',
    });
    const refetchDataList = throttle(refetch, 1000, { leading: true, trailing: false });
    const refetchList = throttle(answerRefetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        APP.eventManager.subscribe('ANSWER_REFETCH', refetchList);
        return () => {
            APP.eventManager.unsubscribe('ANSWER_REFETCH', refetchList);
        };
    }, []);
    useEffect(() => {
        test_id && refetchDataList();
    }, [test_id]);
    useEffect(() => {
        member_id && test_id && refetchList();
    }, [member_id, test_id, isSubmit]);
    useEffect(() => {
        if (!data)
            return;
        if (data.found_count > 0) {
            const item = data.items[0];
            setItem(item);
        }
    }, [data]);
    useEffect(() => {
        if (!answerData)
            return;
        if (answerData.found_count > 0) {
            setIsSubmit(true);
        }
        else {
            setIsSubmit(false);
        }
    }, [answerData]);
    const type = React.useMemo(() => item?.type, [item?.type]);
    if (!item)
        return null;
    if (type === '설문형') {
        return (React.createElement(SurveyQuestionTable, { ...props, item: item, isOpen: item?.status === 'open' ? true : false, answerData: answerData, isSubmit: isSubmit }));
    }
    if (type === '파일첨부형') {
        return (React.createElement(AttachmentQuestionTable, { ...props, item: item, isOpen: item?.status === 'open' ? true : false, answerData: answerData, isSubmit: isSubmit }));
    }
    // 제출 완료 및 종료된 테스트는 정오표 표출 - OMR에만 해당
    if (isSubmit || item.status === 'closed') {
        return (React.createElement(ErrataTable, { ...props, item: item, isOpen: item?.status === 'open' ? true : false, isSubmit: isSubmit }));
    }
    return (React.createElement(DetailQuestionTable, { ...props, item: item, isOpen: item?.status === 'open' ? true : false, subject_name: props.subject_name, isSubmit: isSubmit }));
});

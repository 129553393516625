import { toState } from '../../redux/regist';
import { DEFAULT_MENU_S_LIST, DEFAULT_MENU_T_LIST } from '../DST';
export const getMenuData = (statful) => {
    const state = toState(statful);
    return {
        depths: state['base/menu'].depths,
        menu: state['base/menu'].menu,
        id: state['base/menu'].id,
        props: state['base/menu'].props,
    };
};
export const getDepths = (statful) => {
    const state = toState(statful);
    return state['base/menu'].depths;
};
// 각 메뉴의 세부 항목 정의
export const menuItems = {
    learningManagement: ['assignment', 'test', 'attendanceStatus', 'sendSMS'],
    myRoom: ['assignment', 'test', 'attendanceStatus', 'gradeCard'],
    liveRoom: ['reservation', 'previous-conference', 'reply'],
    learningSupportRoom: ['notice', 'qna', 'resource', 'content'],
    learningSupportRoomTeacher: ['notice', 'qna', 'resource', 'content'],
    learningSupportRoomStudent: ['notice', 'qna', 'resource'],
};
export const getFilterMenuList = (isTeacher, hiddenMenus = []) => {
    const defaultMenuList = isTeacher ? DEFAULT_MENU_T_LIST : DEFAULT_MENU_S_LIST;
    return defaultMenuList.filter(menu => {
        switch (menu) {
            case 'learningManagement':
                // 'learningManagement'에 필요한 일부 항목이 classesMenu에 있는지 확인
                return menuItems.learningManagement.some(item => !hiddenMenus.includes(item));
            case 'myRoom':
                // 'myRoom'에 필요한 일부 항목이 classesMenu에 있는지 확인
                return menuItems.myRoom.some(item => !hiddenMenus.includes(item));
            case 'liveRoom':
                // 'liveRoom'에 필요한 일부 항목이 classesMenu에 있는지 확인
                return menuItems.liveRoom.some(item => !hiddenMenus.includes(item));
            case 'learningSupportRoom':
                // 강사와 학생에 따라 다른 메뉴 항목을 검사
                const supportRoomItems = isTeacher
                    ? menuItems.learningSupportRoomTeacher
                    : menuItems.learningSupportRoomStudent;
                return supportRoomItems.some(item => !hiddenMenus.includes(item));
            default:
                // 기본 메뉴 ('home' 등)는 항상 포함
                return true;
        }
    });
};

import _, { throttle } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Table } from '../../../../../common';
import { useMemberInfo } from '../../../../../server';
import { getClassesId, getClassesMember } from '../../../../base/classes';
import { MemberHeaader } from './MemberHeaader';
import { MemberRoleDropDown } from './MemberRoleDropDown';
const MemberTableStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;

	.name {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.dropdown {
		width: 100%;
	}

	.body-item {
		position: relative;
	}

	.dropdown-list {
		top: 48px;
	}

	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.table-item-wrap {
		.body-item {
			width: unset;
		}
	}

	.live-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		flex: 1;

		.item {
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px 0px 0px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			${props => props.$isDetail
    ? css `
							flex-direction: column;
							align-items: flex-start;
					  `
    : css `
							flex-direction: row;
							align-items: center;
					  `}

			justify-content: flex-start;
			flex-shrink: 0;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			.grey {
				color: var(--color-grey-l, #83868a);
				font-size: var(--b4-font-size, 12px);
			}
		}
	}
`;
const desktopStyles = css `
	min-width: 428px;

	.live-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		flex: 1;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			align-self: stretch;

			color: var(--color-grey, #666666);
			text-align: left;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.item {
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}
`;
export const MembersTable = React.memo(({ isDetail, isModify, allChecked, initMember = [], setItem, id, entryData, setExclude, }) => {
    const classesId = getClassesId();
    const memberList = useSelector((state) => getClassesMember(state), shallowEqual);
    const { t } = useTranslation();
    // 페이징
    const [page, setPage] = useState(0);
    const [previousPage, setPreviousPage] = useState(0);
    // 테스트 데이터
    const [testData, setTestData] = useState([]);
    // 총 데이터 갯수
    const [totalCount, setTotalCount] = useState(0);
    // 체크박스 리스트
    const [checkList, setCheckList] = useState([]);
    const [excludeMembers, setExcludeMembers] = useState({});
    const [postExcludeMembers, setPostExcludeMembers] = useState([]);
    const [searchOption, setSearchOption] = useState({
        type: undefined,
        status: undefined,
        name: undefined,
    });
    // 참가자 목록 가져오기
    const { data, refetch } = useMemberInfo({
        ...searchOption,
        class_id: classesId,
        offset: page === 0 ? 0 : page * 10,
        limit: 10,
    });
    // const existMemberIds = useMemo(() => {
    // 	return allChecked ? memberList : initMember.map(i => i.member_id); // 해당 조건을 만족하는 경우에만 user_id 반환
    // }, [initMember, localID, allChecked]);
    const updateList = throttle(() => {
        if (data) {
            const { total_count, found_count, items } = data;
            if (found_count === 0 && page > 0) {
                setPage(Math.max(page - 1, 0));
                return;
            }
            let list = [];
            if (isModify) {
                list = items.map(i => {
                    const member_data = initMember.find(j => typeof j === 'string' ? j === i.id : j.member_id === i.id);
                    if (!member_data) {
                        return {
                            id: i.id,
                            user_name: i.nickname,
                            role_name: i.level_name === 'member' ? 'participant' : i.level_name,
                            enter_time: undefined,
                            leave_time: undefined,
                            total_time: 0,
                        };
                    }
                    else {
                        return {
                            id: i.id,
                            user_name: member_data.nickname,
                            role_name: member_data.role_name,
                            enter_time: undefined,
                            leave_time: undefined,
                            total_time: 0,
                        };
                    }
                });
            }
            else if (isDetail) {
                if (items) {
                    const detailMembers = initMember.filter(member1 => items.map(member2 => member2.id).includes(member1.member_id));
                    list = detailMembers.map((i, index) => {
                        const memberData = entryData.filter(data => data.member_id === i.member_id);
                        // memberData가 비어있거나 entry_logs가 없는 경우 처리
                        if (!memberData.length || memberData[0]?.entry_logs === null) {
                            return {
                                id: i.member_id,
                                user_name: i.nickname,
                                role_name: i.role_name,
                                enter_time: '-',
                                leave_time: '-',
                                total_time: 0,
                            };
                        }
                        else {
                            const entryLogs = memberData[0].entry_logs;
                            let earliestEnterTime = entryLogs[0].enter_time
                                ? moment(entryLogs[0].enter_time)
                                : null;
                            let latestLeaveTime = entryLogs[0].leave_time
                                ? moment(entryLogs[0].leave_time)
                                : null;
                            let totalTime = 0;
                            // 각 EntryLog의 입장 시간과 퇴장 시간을 비교하여 최솟값과 최댓값을 갱신합니다.
                            entryLogs.forEach(log => {
                                const enterTime = moment(log.enter_time);
                                const leaveTime = moment(log.leave_time);
                                if (earliestEnterTime === null ||
                                    enterTime.isBefore(earliestEnterTime)) {
                                    earliestEnterTime = enterTime;
                                }
                                if (latestLeaveTime === null ||
                                    leaveTime.isAfter(latestLeaveTime)) {
                                    latestLeaveTime = leaveTime;
                                }
                                totalTime += leaveTime.diff(enterTime, 'minutes');
                            });
                            return {
                                id: i.member_id,
                                user_name: i.nickname,
                                role_name: i.role_name,
                                enter_time: earliestEnterTime.isValid()
                                    ? earliestEnterTime.format('HH:mm')
                                    : '-',
                                leave_time: latestLeaveTime.isValid()
                                    ? latestLeaveTime.format('HH:mm')
                                    : '-',
                                total_time: totalTime,
                            };
                        }
                    });
                }
            }
            else {
                list = items.map(i => {
                    return {
                        id: i.id,
                        user_name: i.nickname,
                        role_name: i.level_name === 'member' ? 'participant' : i.level_name,
                        enter_time: undefined,
                        leave_time: undefined,
                        total_time: 0,
                    };
                });
            }
            setTotalCount(total_count);
            if (_.isEqual(testData, list))
                return;
            setTestData(list);
        }
    }, 1000, { leading: true, trailing: false });
    // useEffect(() => {
    // 	if (!isDetail) setCheckList(excludeMemberIds);
    // }, [excludeMemberIds]);
    useEffect(() => {
        setPreviousPage(page);
        if (checkList.length > 0 && previousPage === page) {
            setExcludeMembers(prevState => ({
                ...prevState,
                [page]: checkList,
            }));
        }
    }, [checkList, page]);
    // useEffect(() => {
    // 	setExclude(Object.values(excludeMembers).flat());
    // }, [excludeMembers]);
    // useEffect(() => {
    // 	setExclude(postExcludeMembers);
    // }, [postExcludeMembers]);
    const refetchList = throttle(refetch, 1000, {
        leading: true,
        trailing: false,
    });
    useEffect(() => {
        if (!memberList || memberList.length <= 0)
            return;
        refetchList();
    }, [memberList, searchOption, classesId, page]);
    useEffect(() => {
        updateList();
    }, [data, entryData, initMember, testData]);
    useEffect(() => {
        const list = checkList && checkList.length < 0
            ? testData
            : testData.filter(item => checkList.includes(item.id));
        setItem(list);
    }, [testData, checkList]);
    const handlerMoreData = (page) => {
        setPage(page);
    };
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = [
            { title: 'name', space: 2, sort: false },
            { title: 'participant', space: 1, sort: false },
            ...(isDetail
                ? [
                    { title: 'eneter_time', space: 1, sort: false },
                    { title: 'exit_time', space: 1, sort: false },
                    { title: 'progress_time', space: 1, sort: false },
                ]
                : []),
        ];
        const tableReadDataKeys = [
            { title: 'user_name', space: 2, sort: false },
            { title: 'role_name', space: 1, sort: false },
            ...(isDetail
                ? [
                    { title: 'enter_time', space: 1, sort: false },
                    { title: 'leave_time', space: 1, sort: false },
                    { title: 'total_time', space: 1, sort: false },
                ]
                : []),
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [isDetail, isModify]);
    // // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => setSearchOption(filter);
    const renderTitle = useMemo(() => (React.createElement(MemberHeaader, { handlerFilterList: handlerFilterList, isDetail: isDetail, totalTableList: totalCount })), [searchOption, totalCount]);
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map(headItem => isMobile &&
            (headItem.title === 'enter_time' ||
                headItem.title === 'leave_time' ||
                headItem.title === 'total_time') ? null : (React.createElement("div", { key: headItem.title, className: "body-item", style: {
                flex: (!isMobile || (isMobile && !isDetail)) && headItem.space,
                ...styles?.bodyItem,
            } }, headItem.title === 'role_name' ? (isDetail ? (React.createElement("p", { className: isMobile ? 'grey' : 'role-name' }, t(`conference.${item[headItem.title]}`))) : (React.createElement(MemberRoleDropDown, { isDisable: true, defaultRole: item.role_name, handlerUpdateOption: (dropValue) => {
                // if (!dropValue) return; // dropValue가 없을 경우 반환
                // if (dropValue.id === item.role_name) return; // 변경 사항이 없을 경우 반환
                // setTestData(prevData =>
                // 	prevData.map(i =>
                // 		i.id === item.id
                // 			? { ...i, role_name: dropValue.id }
                // 			: i
                // 	)
                // );
            } }))) : headItem.title === 'total_time' ? (React.createElement("p", { className: "overText" }, item[headItem.title] === undefined
            ? '-'
            : t('conference.minutes', { minute: item[headItem.title] }))) : (React.createElement("p", { className: "overText" }, item[headItem.title] || '-')))));
    };
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    return (React.createElement(MemberTableStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDetail": isDetail, className: "user-list", style: { width: `${isDetail && isDesktop ? '45%' : 'auto'}` } },
        React.createElement(Table, { className: "live-list-002", forceShowheader: isMobile ? false : true, 
            // showCheckBox={isDetail ? false : true}
            // showIndex={isDetail && !isMobile ? true : false}
            showIndex: true, defaultCheckList: excludeMembers[page] ? excludeMembers[page] : [], 
            // isInifinite={isDetail ? false : true}
            isInifinite: false, data: testData, totalCount: totalCount, page: page, sortDefault: "id", headTitleList: headTitleList, renderTitle: renderTitle, renderItem: renderItem, handlerCheckList: setCheckList, handlerMoreData: handlerMoreData })));
});

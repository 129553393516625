import moment from 'moment';
import { getAccessToken } from '../../features/base/account';
import { getClassesId } from '../../features/base/classes';
import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
const getToken = () => getAccessToken(APP.store.getState);
// (테스트 및 과제) 리스트 조회
const getAssignmentList = async (queryString) => {
    const url = `/api/mimac/v1/tests?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 질문 조회
const getQuestionList = async (queryString) => {
    const url = `/api/mimac/v1/questions?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 풀이 조회
const getAnswerList = async (queryString) => {
    const url = `/api/mimac/v1/answers?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 피드백 조회
const getFeedbackList = async (queryString) => {
    const url = `/api/mimac/v1/feedbacks?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 설문 빈도 조회
const getSurveyFrequency = async (test_id) => {
    const url = `/api/mimac/v1/tests/${test_id}/frequencies`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 점수 현황 조회
export const getScoreStatus = async ({ id, queryString }) => {
    const url = `/api/mimac/v1/tests/${id}/score-status?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 제출 현황 조회
export const getSubmitStatus = async ({ id, queryString }) => {
    const url = `/api/mimac/v1/tests/${id}/submit-status?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 성적 조회
export const getGrades = async (queryString) => {
    const url = `/api/mimac/v1/grades?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 성적 조회
export const getStatistics = async ({ test_id, queryString, }) => {
    const url = `/api/mimac/v1/tests/${test_id}/statistics?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (테스트 및 과제) 질문 출제 등록
export const postQuestion = async (data) => {
    const url = `/api/mimac/v1/tests/${data.id}/set-question`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ questions: data.props }),
    });
};
// (테스트 및 과제) 설문 질문 등록
export const postSurvey = async (data) => {
    const url = `/api/mimac/v1/tests/${data.id}/set-question`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ questions: data.props }),
    });
};
// (테스트 및 과제) 질문 출제 등록
export const postUpdateAssignmentList = async (data) => {
    const url = `/api/mimac/v1/tests/${data.id}/update`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data.props),
    });
};
// (테스트 및 과제) 질문 출제 등록
export const postSubmitAnswer = async (data) => {
    const url = `/api/mimac/v1/tests/${data.id}/submit-answer`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data.props),
    });
};
// (테스트 및 과제) 피드백 등록
export const postFeedback = async (data) => {
    const url = `/api/mimac/v1/tests/${data.id}/feedback-answer`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data.props),
    });
};
// (테스트 및 과제) 리스트 등록
export const postAssignmentList = async (data) => {
    const url = `/api/mimac/v1/tests`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
    });
};
// (테스트 및 과제) 리스트 삭제
export const deleteAssignmentList = async (id) => {
    const url = `/api/mimac/v1/tests/${id}/delete`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// (테스트 및 과제) 리스트 가져오기
export const useAssignmentList = (data) => {
    const queryString = objectToQueryString(data); // props를 쿼리 문자열로 변환
    return useCustomQuery([`questions_${queryString}`, data], () => getAssignmentList(queryString));
};
// (테스트 및 과제) 질문 가져오기
export const useQuestionList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery(`questions_${moment().unix()}`, () => getQuestionList(queryString));
};
// (테스트 및 과제) 답변 가져오기
export const useAnswerList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery(`answer_${data?.from || data?.test_id}`, () => getAnswerList(queryString));
};
// (테스트 및 과제) 피드백 가져오기
export const useFeedbackList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery(['feedback', data], () => getFeedbackList(queryString));
};
// (테스트 및 과제) 설문 빈도 조회
export const useSurveyFrequency = (test_id) => {
    return useCustomQuery(`frequencies_${test_id}`, () => getSurveyFrequency(test_id));
};
// (테스트 및 과제) 성적 가져오기
export const useGradesList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery(`grades_${data.test_id}`, () => getGrades(queryString));
};
// (테스트 및 과제) 통계 가져오기
export const useStatistics = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery(`statistics_${queryString}`, () => getStatistics({ test_id: data.test_id, queryString }));
};
// (테스트 및 과제) 점수 현황 가져오기
export const useSubmitStatus = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`submit_${queryString}`, data], () => getSubmitStatus({ id: data.id, queryString }));
};
// (테스트 및 과제) 점수 현황 가져오기
export const useScoreStatus = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`score_${data}`, data], () => getScoreStatus({ id: data.id, queryString }));
};
// 성적표 API 조회
export const getGradeCard = async (id, queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/members/${id}/test-report?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성`; // API 엔드포인트 생성
    return fetchData(url);
};
export const useGradeCardList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`gradeCard_${data}`, data], () => getGradeCard(data.id, queryString));
};

import styled, { css } from 'styled-components';
export const DSTL001DetailStyled = styled.div `
	input:disabled {
		background: var(--color-white, #ffffff);
	}

	.box {
		min-height: 92px;
	}

	.dst001_detail_title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
	}

	.warp-001 {
		display: flex;
		flex-direction: row;
		gap: 47px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		width: 100%;

		.test-info-container {
			border-radius: 10px;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px;
			padding: 24px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			align-items: flex-end;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}

		.value-container {
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			flex: 1;

			._001,
			._001 * {
				box-sizing: border-box;
			}
			._001 {
				display: flex;
				flex-direction: row;
				gap: 16px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
			}

			.submission_status_box {
				padding: 20px;
				display: flex;
				flex-direction: column;
				gap: 16px;
				box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.02);
				width: max-content;
				.submission_txt_wrap {
					display: flex;
					gap: 8px;
				}
			}
		}
	}

	.submission-name {
		-webkit-line-clamp: 1;
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	padding: 0px 0px 20px 0px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	height: 100%;

	.wrong-rate-list {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 100%;
	}

	.item {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 1px 0px 0px 0px;
		padding: 12px 0px 12px 0px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 12px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.tab-item {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 100%;
		height: 100%;
		overflow: auto;

		.submission-title {
			text-align: left;
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: 18px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
			position: relative;
			align-self: stretch;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.task-label {
			.overText {
				text-align: left;
				font-family: 'NotoSansKr-Bold', sans-serif;
				font-size: 18px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 700;
				position: relative;
				align-self: stretch;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.more_txt,
		.overText {
		}

		.bold {
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
		}

		.contents {
			gap: 12px;
			.answer {
				width: 48%;
				overflow: hidden;
			}
		}
	}

	.dst001_detail_title {
		font-size: var(--h3-font-size, 20px);
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: var(--h3-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.box {
		min-height: 48px;
	}

	.errorate-box {
		overflow: auto;
	}

	.score-wrap {
		display: flex;
		gap: 4px;
	}

	.divider {
		background: var(--color-silver, #f3f4f8);
		width: 1px;
		position: relative;
	}

	.mobile-tab-layout {
		.task-label {
			min-width: 65px;
		}
	}
`;
const tabletStyles = css `
	display: flex;
	gap: 20px;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.wrong-rate-list {
		padding: 12px 0px 0px 0px;
		display: flex;
		flex-direction: row;
		gap: 39px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		flex: 1;
	}

	.no-rate-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		flex: 1;
	}

	.dst001_detail_title {
		font-size: var(--h3-font-size, 20px);
		line-height: 150%;
		font-weight: var(--h3-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.task-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.box {
			min-height: 60px;
			button {
				p {
					font-size: 12px;
				}
			}
			svg {
				width: 14px;
				height: 14px;
			}
		}
	}

	.date-input-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		/* 
		.field-global-001 {
			.task-label {
				min-width: unset;
			}
		} */
	}

	.assignment-info {
		width: 100%;
	}
`;
const desktopStyles = css `
	display: flex;
	gap: 20px;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.wrong-rate-list {
		padding: 12px 0px 0px 0px;
		display: flex;
		flex-direction: row;
		gap: 39px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		flex: 1;
	}

	.no-rate-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
		flex: 1;
	}

	.dst001_detail_title {
		font-size: var(--h3-font-size, 24px);
		line-height: var(--h3-line-height, 36px);
		font-weight: var(--h3-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.error-rate-box {
		display: flex;
		flex-direction: row;
		gap: 39px;
		padding: 0px 0px 20px 0px;
	}

	.test-info-container {
		width: 100%;
		max-width: 40%;
		min-width: 600px;
	}
`;
export const DSTL001DetailTableStyled = styled.div `
	flex: 1; //PC 테스트 제출 상세보기 - 제출내역 없을 때

	.detail_txt {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}

	.more_txt {
		color: var(--color-black, #101010);
		text-decoration: underline;
		cursor: pointer;
	}

	.detail_more_txt {
		color: var(--color-pink, #f46790);
		text-decoration: underline;
		cursor: pointer;

		&_bold {
			color: var(--color-pink, #f46790);
			text-decoration: underline;
			cursor: pointer;
			font-weight: 700;
			font-family: 'NotoSansKr-Bold', sans-serif;
		}
	}

	.task-list-popup,
	.task-list-002 {
		width: 100%;
	}

	.underline {
		color: #3ab2bf;
		text-decoration: underline;
	}

	${props => props.$isMobile
    ? mobileTablestyles
    : props.$isTablet
        ? tabletTableStyles
        : desktopTableStyles}
`;
const mobileTablestyles = css `
	flex: 1;
	&.detail_table:not(&.popup-table) {
		flex: 1;
	}

	.detail_txt {
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
	}

	.task-label {
		flex-direction: row;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.task-list-mobile {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 1px 0px 1px 0px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		min-height: 240px;
		position: relative;

		.item {
			padding: 0px 0px 10px 0px;
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			.body-item {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				align-items: flex-start;
				justify-content: flex-start;
				width: 100%;
			}
		}

		.error-info-wrap {
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}

		.rankings {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
			position: relative;
			width: 30px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.error-frame {
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: flex-start;
			flex: 1;
			position: relative;
		}

		.error-rate-text {
			color: var(--color-grey-l, #83868a);
			text-align: left;
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.ratio {
			background: var(--color-silver, #f3f4f8);
			border-radius: 8px;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;
			padding: 12px 10px 12px 10px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}

		.choice-ratio-text {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 10px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			width: 42px;
		}

		.choice-ratio-index {
			color: var(--color-grey-l, #83868a);
			text-align: left;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	${props => props.$isDetail
    ? css `
					&.detail_table:not(&.popup-table) {
						flex: 1;
						height: 100%;
					}
					.task-label {
						flex-direction: row;
						display: flex;
						justify-content: space-between;
						align-items: center;

						color: var(--color-black, #101010);
						text-align: left;
						font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
						font-size: var(--h4-font-size, 18px);
						line-height: var(--h4-line-height, 32px);
						letter-spacing: var(--h4-letter-spacing, -0.02em);
						font-weight: var(--h4-font-weight, 700);
						position: relative;
					}
					.task-list-popup,
					.task-list-002 {
						display: flex;
						flex-direction: column;
						gap: 0px;
						align-items: flex-start;
						justify-content: flex-start;
						flex: 1;
						min-height: 240px;
						position: relative;
						overflow: hidden;

						.scroll-content {
							border-style: solid;
							border-color: var(--color-grey-w, #b6bac0);
							border-width: 1px 0px 0px 0px;
							display: flex;
							flex-direction: column;
							gap: 0px;
							align-items: flex-start;
							justify-content: flex-start;
							align-self: stretch;
							flex: 1;
							position: relative;
						}
						.item {
							border-style: solid;
							border-color: var(--color-silver, #f3f4f8);
							border-width: 0px 0px 1px 0px;
							padding: 12px 0px 12px 0px;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: space-between;
							align-self: stretch;
							flex-shrink: 0;
							position: relative;

							.body-item {
								text-align: left;
								font-family: 'NotoSansKr-Regular', sans-serif;
								font-size: 12px;
								line-height: 150%;
								letter-spacing: -0.02em;
								font-weight: 400;
								position: relative;
								display: flex;
								overflow: hidden;
								white-space: nowrap;
								align-items: flex-end;
								justify-content: flex-start;
								text-overflow: ellipsis;
								gap: 4px;
								flex-direction: column;
							}
						}
					}

					.student-info-wrap {
						display: flex;
						flex-direction: row;
						gap: 4px;
						align-items: center;
						justify-content: flex-start;
						align-self: stretch;
						flex-shrink: 0;
						position: relative;
					}

					.score-info-wrap {
						display: flex;
						flex-direction: row;
						gap: 4px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
						width: 100%;
					}

					.tur {
						color: var(--tur, #3ab2bf);
						text-align: left;
						font-family: 'NotoSansKr-Bold', sans-serif;
						font-size: 12px;
						line-height: 150%;
						letter-spacing: -0.02em;
						font-weight: 700;
						text-decoration: underline;
					}

					.score-bold {
						font-weight: 700;
					}
			  `
    : css ``}
`;
const tabletTableStyles = css `
	.detail_txt {
		font-size: 14px;
		font-weight: 400;
	}

	&.detail_table:not(&.popup-table) {
		flex: 1;
		height: 100%;
	}

	.task-list-popup,
	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		min-height: 240px;
		position: relative;
		overflow: hidden;

		.scroll-content {
			overflow: unset;
			gap: unset;
		}
	}

	.task-label {
		flex-direction: row;
		display: flex;
		justify-content: space-between;
		align-items: center;

		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 18px);
		line-height: var(--h4-line-height, 32px);
		letter-spacing: var(--h4-letter-spacing, -0.02em);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
	}

	${props => props.$isDetail
    ? css `
					.task-list-popup,
					.task-list-002 {
						.scroll-content {
							border-style: solid;
							border-color: var(--color-grey-w, #b6bac0);
							border-width: 1px 0px 0px 0px;
							display: flex;
							flex-direction: column;
							gap: 0px;
							align-items: flex-start;
							justify-content: flex-start;
							align-self: stretch;
							flex: 1;
							position: relative;
						}
						.item {
							border-style: solid;
							border-color: var(--color-silver, #f3f4f8);
							border-width: 0px 0px 1px 0px;
							padding: 12px 0px 12px 0px;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: space-between;
							align-self: stretch;
							flex-shrink: 0;
							position: relative;

							.body-item {
								text-align: left;
								font-family: 'NotoSansKr-Regular', sans-serif;
								font-size: 12px;
								line-height: 150%;
								letter-spacing: -0.02em;
								font-weight: 400;
								position: relative;
								display: flex;
								overflow: hidden;
								white-space: nowrap;
								align-items: flex-end;
								justify-content: flex-start;
								text-overflow: ellipsis;
								gap: 4px;
								flex-direction: column;
							}
						}
					}

					.student-info-wrap {
						display: flex;
						flex-direction: row;
						gap: 4px;
						align-items: center;
						justify-content: flex-start;
						align-self: stretch;
						flex-shrink: 0;
						position: relative;
						width: 100%;
					}

					.score-info-wrap {
						display: flex;
						flex-direction: row;
						gap: 4px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
						width: 100%;
					}

					.tur {
						color: var(--tur, #3ab2bf);
						text-align: left;
						font-family: 'NotoSansKr-Bold', sans-serif;
						font-size: 12px;
						line-height: 150%;
						letter-spacing: -0.02em;
						font-weight: 700;
						text-decoration: underline;
					}

					.score-bold {
						font-weight: 700;
					}
			  `
    : css `
					&.detail_table:not(&.popup-table) {
						min-height: 300px;
						min-width: 572px;
					}
					.task-list-popup,
					.task-list-002 {
						.table-header {
							background: var(--color-silver, #f3f4f8);
							border-style: solid;
							border-color: var(--color-grey-w, #b6bac0);
							border-width: 1px 0px 0px 0px;
							padding: 12px 16px 12px 16px;
							display: flex;
							flex-direction: row;
							gap: 12px;
							align-items: flex-start;
							justify-content: flex-start;
							align-self: stretch;
							flex-shrink: 0;
							position: relative;
							width: 100%;

							color: var(--color-black, #101010);
							text-align: left;
							font-family: 'NotoSansKr-Regular', sans-serif;
							font-size: 12px;
							line-height: 150%;
							letter-spacing: -0.02em;
							font-weight: 400;
						}

						.item {
							border-style: solid;
							border-color: var(--color-silver, #f3f4f8);
							border-width: 0px 0px 1px 0px;
							padding: 12px 16px 12px 16px;
							display: flex;
							flex-direction: row;
							gap: 12px;
							align-items: center;
							justify-content: flex-start;
							flex-shrink: 0;
							position: relative;

							.body-item {
								color: var(--color-black, #101010);
								text-align: left;
								font-family: 'NotoSansKr-Regular', sans-serif;
								font-size: 12px;
								line-height: 150%;
								letter-spacing: -0.02em;
								font-weight: 400;
								position: relative;
								display: flex;
								align-items: center;
								justify-content: flex-start;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
						}
					}
			  `}

	&.popup-table {
		.paging {
			margin-top: 20px;
			gap: 10px;

			button {
				width: 18px;
				height: 18px;

				p {
					min-width: unset;
				}
			}
		}
	}
`;
const desktopTableStyles = css `
	&.detail_table:not(&.popup-table) {
		flex: 1;
		height: 100%;
		min-height: 300px;
		min-width: 572px;
	}

	.detail_txt {
		font-size: 16px;
		font-weight: 400;
	}

	.task-label {
		flex-direction: row;
		display: flex;
		justify-content: space-between;
		align-items: center;

		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 18px);
		line-height: var(--h4-line-height, 32px);
		letter-spacing: var(--h4-letter-spacing, -0.02em);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
	}

	.task-list-popup,
	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		min-height: 240px;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.body-item {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
				width: 20px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}
`;

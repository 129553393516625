import moment from 'moment';
import React, { useEffect } from 'react';
import SurveyResultItem from './SurveyResultItem';
import { SURVEY_TYPE } from './constant';
// SurveyLayout 컴포넌트
export const SurveyResultLayout = React.memo(({ surveyData, setSurveyData, isRegist, isSubmit, frequencies, }) => {
    const [defaultData, setDefaultData] = React.useState(surveyData);
    const [list, setList] = React.useState(defaultData);
    // 데이터 업데이트 함수
    const updateData = ({ id, data }) => {
        if (list) {
            setList(list.map(item => (item.id === id ? { ...item, ...data } : { ...item })));
        }
    };
    const addSurveyItem = () => {
        // surveyData 배열의 길이 확인
        const length = list.length;
        // id 생성: -length를 사용하여 고유한 id 생성
        const newItemId = `survey-${moment().unix()}-${length}`;
        // 새로운 설문 항목 객체 생성
        const newItem = {
            id: newItemId,
            title: '',
            type: SURVEY_TYPE.OBJECTIVE,
            data: [],
        };
        // 기존 surveyData 배열에 새로운 항목 추가
        const updatedList = [...list, newItem];
        // 상태 업데이트
        setList(updatedList);
        setDefaultData(updatedList);
    };
    const deleteSurveyItem = ({ id }) => {
        if (list && list.length > 1) {
            const newList = list.filter(item => item.id !== id);
            setList(newList);
            setDefaultData(newList);
        }
        else {
            alert('최소 1개 이상의 설문 항목이 필요합니다.');
        }
    };
    useEffect(() => {
        if (surveyData.length == 0)
            return;
        if (defaultData.length > 0)
            return;
        if (defaultData == surveyData)
            return;
        setDefaultData(surveyData);
    }, [surveyData]);
    useEffect(() => {
        if (defaultData.length == 0)
            return;
        if (list.length > 0)
            return;
        if (list == defaultData)
            return;
        setList(defaultData);
    }, [defaultData]);
    // 데이터 변경 시 콜백 함수
    React.useEffect(() => {
        if (list.length == 0)
            return;
        setSurveyData && setSurveyData(list);
    }, [list]);
    React.useEffect(() => {
        setDefaultData(surveyData);
    }, [surveyData]);
    React.useEffect(() => {
        APP.eventManager.subscribe('UPDATE_SURVEY_ITEM', updateData);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_SURVEY_ITEM', updateData);
        };
    }, [list]);
    React.useEffect(() => {
        if (!isRegist)
            return;
        APP.eventManager.subscribe('ADD_SURVEY_ITEM', addSurveyItem);
        APP.eventManager.subscribe('DELETE_SURVEY_ITEM', deleteSurveyItem);
        return () => {
            APP.eventManager.unsubscribe('ADD_SURVEY_ITEM', addSurveyItem);
            APP.eventManager.unsubscribe('DELETE_SURVEY_ITEM', deleteSurveyItem);
        };
    }, [list]);
    const component = React.useMemo(() => {
        return (React.createElement(React.Fragment, null, defaultData.length > 0 &&
            defaultData.map((item, index) => (React.createElement(SurveyResultItem, { key: index, index: index, item: item, isSubmit: isSubmit, isRegist: isRegist, frequencies: frequencies })))));
    }, [defaultData, frequencies]);
    return React.createElement(React.Fragment, null, component);
});

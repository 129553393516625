import { getAccessToken } from '../../features/base/account';
import { getClassesId } from '../../features/base/classes';
import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
const getToken = () => getAccessToken(APP.store.getState);
// (다시보기) 다시보기 리스트 조회
const getReply = async (queryString) => {
    const class_id = getClassesId();
    const url = class_id
        ? `/api/mimac/v1/replays?${queryString}&class_id=${class_id}`
        : `/api/mimac/v1/replays?${queryString}`; // API 엔드포인트 생성
    return fetchData(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// (다시보기) 시청 기록 조회
const getWatchHistory = async (id, queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/replays/${id}/play-histories?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성
    // return fetchData(url);
    return fetchData(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// (다시보기) 시청 기록 조회
const getReplyReport = async (id, queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/members/${id}/replay-report?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성
    // return fetchData(url);
    return fetchData(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// (다시보기) 다시보기 등록
export const postRegistReplay = async (data) => {
    const url = `/api/mimac/v1/replays`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
    });
};
// (다시보기) 다시보기 수정
export const postModifyReplay = async (props) => {
    const url = `/api/mimac/v1/replays/${props.id}/update`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(props.data),
    });
};
// 다시보기 삭제
export const deleteReplay = async (id) => {
    const url = `/api/mimac/v1/replays/${id}/delete`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// 강의 다시보기 리스트 조회
export const useReplyList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`reply_${data}`, data], () => getReply(queryString));
};
// 시청 이력보기 리스트 조회
export const useWatchHistoryList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`watch_history_${data}`, data], () => getWatchHistory(data.id, queryString));
};
// e다시 보기 시청 현황 조회
export const useReplyReportList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`replay_${data}`], () => getReplyReport(data.id, queryString));
};

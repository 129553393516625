import i18next from 'i18next';
import { englishScores, koreanScores, mathScores, selectChatacterScore, selectEnglishScores, selectHistorytScore, selectKoreanScores, selectMathScores, } from './constant';
// 해당 과목에 대한 점수와 배점 배열 생성
export const createEvalArray = (scores, scoreBlank) => {
    const scoresArray = [];
    scores.forEach(score => {
        const range = score.number.split('~');
        const start = parseInt(range[0]);
        const end = parseInt(range[1]) || start;
        for (let i = start; i <= end; i++) {
            scoresArray.push({
                id: i,
                type: score.type,
                score: scoreBlank ? 0 : score.score,
                answer: '',
                examples: 5,
            });
        }
    });
    return scoresArray;
};
// 해당 과목에 대한 점수와 배점 배열 생성
export const createFreeEvalArray = (objectiveNum, subjectiveNum) => {
    const scoresArray = [];
    for (let i = 1; i <= objectiveNum + subjectiveNum; i++) {
        const type = i <= objectiveNum ? 'objective' : 'subjective'; // objectiveNum 만큼 객관식, 나머지는 주관식
        const score = 2; // 기본값 2로 설정
        scoresArray.push({ id: i, type, score, answer: '', examples: 5 });
    }
    return scoresArray;
};
export const getQuestionType = (text) => {
    switch (text) {
        case 'OMR(기본형)':
        case 'OMR (기본형)':
            return 'OMR';
        case 'OMR(자유형)':
        case 'OMR (자유형)':
            return 'OMR-free';
        case '파일첨부형':
            return 'attachment';
        case '설문형':
            return 'survey';
    }
};
export const checkSurveyData = async (surveyData) => {
    if (surveyData.length === 0) {
        throw new Error('설문 항목을 추가해주세요');
    }
    let test = surveyData &&
        (await Promise.all(surveyData.map(async (item, index) => {
            if (!item)
                return;
            // 설문 제목 확인
            if (!item.title || item.title.trim() === '') {
                throw new Error('설문 제목을 입력해주세요');
            }
            // 체크박스나 선택형일 경우 설문 항목 확인
            if (item.type === 'choice' || item.type === 'checkbox') {
                if (item.data.length === 0) {
                    throw new Error('설문 항목을 추가해주세요');
                }
                // 설문 항목 내 제목 확인
                // title별 개수 집계와 빈 title 검사
                const titleCount = item.data?.reduce((acc, item) => {
                    if (!item.title || item.title.trim() === '') {
                        throw new Error('설문 항목을 입력해주세요');
                    }
                    acc[item.title] = (acc[item.title] || 0) + 1;
                    return acc;
                }, {});
                // 중복된 title이 있는지 확인
                const hasDuplicateTitle = Object.values(titleCount).some(count => count > 1);
                if (hasDuplicateTitle) {
                    throw new Error('중복된 설문 항목이 있습니다');
                }
            }
            return {
                number: index + 1,
                subject_item: undefined,
                type: item.type,
                title: item.title,
                options: item.data ? item.data.map(i => i.title) : [],
            };
        })));
    return test;
};
// 파일 첨부형 오류 확인
export const checkAttachmentData = (assignmentName, attachFileList) => {
    if (attachFileList.length === 0) {
        throw new Error('첨부파일을 등록해주세요');
    }
    let test = [];
    test.push({
        title: assignmentName,
    });
    return test;
};
// OMR 형식 오류 확인
export const checkOMRData = (omrData, subject) => {
    const test = [];
    const t = i18next.t.bind(i18next);
    // OMR 데이터 검증 함수
    const validateEvalData = i => {
        // 답변이 비어있거나 점수 또는 예시가 없는 경우 에러
        if (!i.answer || !i.examples || !i.score) {
            throw new Error(t('error.checkEvalData'));
        }
        // if (!i.score && i?.score?.toString().trim() === '') {
        // 	throw new Error(t('error.checkEvalData'));
        // }
        // 선택형일 때 답변이 범위를 벗어나는 경우 에러
        if (i.type !== 'subjective' && i.type !== 'short') {
            const answer = Number(i.answer);
            if (answer > i.examples || answer <= 0) {
                throw new Error(t('error.checkEvalData'));
            }
        }
        // 주관식일 때 점수가 음수인 경우 에러
        if (i.type === 'subjective' || i.type === 'short') {
            if (Number(i.score) < 0) {
                throw new Error(t('error.checkEvalData'));
            }
            if (i.answer.length > 500) {
                throw new Error(t('error.limit500'));
            }
        }
        // 예시가 범위를 벗어나는 경우 에러
        const examples = Number(i.examples);
        if (examples < 0 || examples > 10) {
            throw new Error(t('error.checkEvalData'));
        }
    };
    // OMR 데이터 반복 처리
    omrData.forEach(item => {
        item.data.forEach(i => {
            // 검증 로직 호출
            validateEvalData(i);
            // 데이터 저장
            test.push({
                answer: i.answer.toString(),
                number: i.id,
                point: i.score,
                subject_item: ['history', 'charater'].includes(subject.id)
                    ? t(subject.title)
                    : item.key,
                type: i.type === 'subjective' || i.type === 'short' ? 'short' : 'choice',
                examples: Number(i.examples) || 5,
            });
        });
    });
    return test;
};
// 기본 점수를 반환하는 함수
export const getDefaultScore = (subjectId, key) => {
    let scoreTest = koreanScores;
    if (subjectId === 'korean') {
        if (key.includes('선택'))
            return selectKoreanScores;
    }
    else if (subjectId === 'math') {
        if (key.includes('공통'))
            return mathScores;
        if (key.includes('선택'))
            return selectMathScores;
    }
    else if (subjectId === 'english') {
        if (key.includes('듣기'))
            return englishScores;
        if (key.includes('읽기'))
            return selectEnglishScores;
    }
    else if (subjectId === 'history') {
        return selectHistorytScore;
    }
    else if (subjectId === 'character') {
        return selectChatacterScore;
    }
    return scoreTest;
};
export function ensureUniqueKeys(data) {
    const seenKeys = new Set();
    const uniqueData = [];
    data.forEach(item => {
        if (!seenKeys.has(item.key)) {
            seenKeys.add(item.key);
            uniqueData.push(item);
        }
    });
    return uniqueData;
}

import React, { useEffect, useMemo } from 'react';
import { DSTL001_Detail, DSTL001_List } from '../DST/DSTL001';
import { CompanyItem, CompanyList, CompanyListTitle } from './company';
import { KlassItem, KlassList, KlassListTitle } from './klass';
import { StatisticsItem, StatisticsList, StatisticsListTitle } from './statistics';
import { AdminTitle } from './ui/AdminTitle';
import { AssignmentTitle } from '../DST/DSTL001/component/DSTL001_List_Child/AssignmentTitle';
export const AdminContent = React.memo(({ menu, menuDetail, showDepth, props, handlerPreviousMenu }) => {
    const [searchOption, setSearchOption] = React.useState();
    const [page, setPage] = React.useState(0);
    useEffect(() => {
        setPage(0);
        setSearchOption('');
    }, [menu, menuDetail]);
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_PAGE', setPage);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_PAGE', setPage);
        };
    }, []);
    const handlerUpdateSearch = (option) => {
        setSearchOption(option);
        setPage(0);
    };
    const renderMainContent = useMemo(() => {
        switch (menu) {
            case 'serviceManagment':
                if (menuDetail === 'company') {
                    switch (showDepth) {
                        case 'list':
                            return React.createElement(CompanyList, { initPage: page, searchOption: searchOption });
                        case 'regist':
                            return React.createElement(CompanyItem, { isModify: true });
                        case 'modify':
                            return React.createElement(CompanyItem, { ...props, isModify: true });
                        case 'detail':
                            return React.createElement(CompanyItem, { ...props, isModify: false });
                    }
                }
            case 'classManagment':
                if (menuDetail === 'classManagment') {
                    switch (showDepth) {
                        case 'list':
                            return React.createElement(KlassList, { initPage: page, searchOption: searchOption });
                        case 'regist':
                            return React.createElement(KlassItem, { isModify: true });
                        case 'modify':
                            return React.createElement(KlassItem, { ...props, isModify: true });
                        case 'detail':
                            return React.createElement(KlassItem, { ...props, isModify: false });
                    }
                }
            case 'backOffice':
                const isTest = menuDetail === 'test' ? true : false;
                switch (showDepth) {
                    case 'list':
                        return (React.createElement("div", { className: "dst" },
                            React.createElement(DSTL001_List, { isTeacher: true, isTest: isTest, isBackOffice: true })));
                    case 'detail':
                        return (React.createElement("div", { className: "dst" },
                            React.createElement(DSTL001_Detail, { isTest: isTest, ...props })));
                }
            case 'statistics':
                if (menuDetail === 'statistics') {
                    switch (showDepth) {
                        case 'list':
                            return (React.createElement(StatisticsList, { initPage: page, searchOption: searchOption }));
                        case 'detail':
                            return React.createElement(StatisticsItem, { ...props });
                    }
                }
            default:
                return null;
        }
    }, [menu, menuDetail, showDepth, page, searchOption]);
    const renderTitleProps = useMemo(() => {
        switch (menu) {
            case 'serviceManagment':
                if (menuDetail === 'company') {
                    switch (showDepth) {
                        case 'list':
                            return (React.createElement(CompanyListTitle, { searchOption: searchOption, setSearchOption: handlerUpdateSearch }));
                    }
                }
                if (menuDetail === 'user')
                    return '관리자 목록';
            case 'classManagment':
                if (menuDetail === 'classManagment') {
                    switch (showDepth) {
                        case 'list':
                            return (React.createElement(KlassListTitle, { searchOption: searchOption, setSearchOption: handlerUpdateSearch }));
                    }
                }
            case 'backOffice':
                switch (showDepth) {
                    case 'list':
                        const isTest = menuDetail === 'test' ? true : false;
                        return (React.createElement(AssignmentTitle, { isHideSelectDelBtn: true, isBackOffice: false, isBackOfficeTitle: true, isTest: isTest, isTeacher: true, handlerFilterList: (search) => {
                                console.log(search);
                                APP.eventManager.publish('UPDATE_SERCH_OPTION', search);
                            } }));
                }
            case 'statistics':
                if (menuDetail === 'statistics') {
                    switch (showDepth) {
                        case 'list':
                            return (React.createElement(StatisticsListTitle, { searchOption: searchOption, setSearchOption: handlerUpdateSearch }));
                    }
                }
            default:
                return null;
        }
    }, [menu, menuDetail, showDepth]);
    const oneDepthMenu = useMemo(() => {
        switch (menu) {
            case 'serviceManagment':
                if (menuDetail === 'company')
                    return '회사 목록';
                if (menuDetail === 'user')
                    return '관리자 목록';
                if (menuDetail === 'version')
                    return '버전 관리';
                break;
            case 'classManagment':
                if (menuDetail === 'classManagment')
                    return '클래스 관리';
                break;
            case 'backOffice':
                if (menuDetail === 'assignment')
                    return '과제 목록';
                if (menuDetail === 'test')
                    return '테스트 관리';
            case 'statistics':
                if (menuDetail === 'statistics')
                    return '사용자 통계';
            default:
                return null;
        }
    }, [menu, menuDetail]);
    const twoDepthMenu = useMemo(() => {
        if (showDepth === 'detail')
            return '상세 페이지';
        if (showDepth === 'regist')
            return '등록';
        if (showDepth === 'modify')
            return '수정';
        return '';
    }, [showDepth]);
    return (React.createElement("div", { className: `admin-main` },
        React.createElement(AdminTitle, { oneDepthMenu: oneDepthMenu, twoDepthMenu: twoDepthMenu, handlerPreviousMenu: handlerPreviousMenu }, renderTitleProps),
        React.createElement("div", { className: "admin-main-content" }, renderMainContent)));
});

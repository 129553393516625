import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import { Button } from '../../../../common';
const DupliactionErrorPageStyled = styled.div `
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background-color: var(--color-tur, #3ab2bf);

	.back_home {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 590px;
	}

	.btn_white {
		z-index: 1;
		border: none;
		padding: 50px;
		background-color: var(--color-white, #ffffff);
		p {
			font-size: var(--b-l-medium-font-size, 16px);
		}
	}
`;
export const ErrorPage = React.memo(({ message }) => {
    React.useEffect(() => {
        APP.eventManager.publish('SHOW_LOADING', false);
    }, []);
    return (React.createElement(DupliactionErrorPageStyled, { className: "empty_data" },
        React.createElement(Button, { className: "btn_white lg", text: message })));
});

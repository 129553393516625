import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button, DoughnutChart } from '../../../../common';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../../../GlobalStateProvider';
const StatusBoxStyled = styled.div `
	${props => props.$isDesktop &&
    `
			margin-top: 40px; 
            padding: 28px 46px 28px 46px;
            border-radius: 10px;
            border-style: solid;
            border-color: var(--color-grey-c, #e6e8ed);
	        border-width: 1px;
			`}
	${props => props.$isTablet && { flex: 1 }}

	background: var(--color-white, #ffffff);
	display: flex;
	flex-direction: column;
	gap: 18px;
	align-items: flex-start;
	justify-content: center;
	flex-shrink: 0;
	position: relative;

	.status-box-title {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		p {
			color: #000000;
			text-align: left;
			font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
			${props => props.$isDesktop
    ? `font-size: var(--h3-font-size, 24px);`
    : `font-size: var(--h3-font-size, 20px);`}
			line-height: var(--h3-line-height, 36px);
			letter-spacing: var(--h3-letter-spacing, -0.02em);
			font-weight: var(--h3-font-weight, 700);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.status {
		display: flex;
		flex-direction: row;
		gap: ${props => (props.$isMobile ? '24px' : '40px')};
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		${props => props.$isTablet &&
    `
                border-radius: 10px;
                border-style: solid;
                border-color: var(--color-grey-c, #e6e8ed);
                border-width: 1px;
                padding: 24px 30px 24px 30px;
                flex-direction: column;
                gap: 24px;
                align-items: center;
                justify-content: center;              
                `}

		font-size: ${props => props.$isMobile ? 'var(--b2-font-size, 14px)' : 'var(--b2-font-size, 18px)'};
		line-height: var(--b2-line-height, 36px);
		letter-spacing: var(--b2-letter-spacing, -0.02em);
		font-weight: var(--b2-font-weight, 400);

		.info,
		.info * {
			box-sizing: border-box;
		}
		.info {
			display: flex;
			flex-direction: column;
			gap: 2px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
		.text_line {
			color: #000000;
			display: flex;
			flex-direction: row;
			gap: ${props => (props.$isMobile ? '4px' : '8px')};
			align-items: flex-start;
			justify-content: space-between;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			&.tur {
				color: var(--tur-p, #2d39aa);
			}

			&.accent {
				color: var(--color-accent, #e7424c);
			}
		}

		svg {
			text {
				fill: var(--tur-p, #2d39aa);
			}
		}
	}
`;
export const StatusBox = React.memo(({ title, color, percentage, totalCount, done, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const renderDoughnutChart = useMemo(() => {
        return (React.createElement(DoughnutChart, { percentage: percentage, size: isMobile ? 130 : 156, fill: color, isHome: false }));
    }, [percentage, color]);
    return (React.createElement(StatusBoxStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
        React.createElement("div", null,
            React.createElement(Button, { className: "status-box-title", text: t(`assignment.${title}Title`), option: {
                    buttonBeforeIcon: {
                        show: true,
                        name: `${title}-title`,
                        size: 30,
                    },
                } })),
        React.createElement("div", { className: "status" },
            renderDoughnutChart,
            React.createElement("div", { className: "info" },
                React.createElement("div", { className: "text_line" },
                    React.createElement("div", null, "\uCD1D \uD68C\uCC28 :"),
                    React.createElement("div", null,
                        totalCount,
                        " \uD68C")),
                React.createElement("div", { className: "text_line tur" },
                    React.createElement("div", null,
                        title === 'reply' ? '시청' : title === 'attend' ? '출석' : '응시',
                        ' ',
                        ":"),
                    React.createElement("div", null,
                        done,
                        " \uD68C")),
                React.createElement("div", { className: "text_line accent" },
                    React.createElement("div", null,
                        title === 'reply'
                            ? '미시청'
                            : title === 'attend'
                                ? '미출석'
                                : '미응시',
                        ' ',
                        ":"),
                    React.createElement("div", null,
                        totalCount - done,
                        " \uD68C"))))));
});

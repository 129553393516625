import React, { useEffect } from 'react';
import { useAnswerList, useQuestionList, useSurveyFrequency } from '../../../../server';
import { SurveyLayout } from './SurveyLayout';
import { useSelector } from 'react-redux';
import { getIsTeacher } from '../../account';
import { SurveyResultLayout } from './SurveyResultLayout';
import { SURVEY_TYPE } from './constant';
const default_survey = [
    {
        id: 'id-0',
        title: '',
        type: SURVEY_TYPE.OBJECTIVE,
        data: [],
    },
];
const getSurveyData = (testItem, questionList, submittedAnswers) => {
    const combinedArray = questionList.map((i) => {
        if (!testItem.survey_questions)
            return;
        const question = testItem.survey_questions[i.id];
        const questionAnswers = submittedAnswers?.filter(answer => answer.question_id === i.id);
        return {
            id: i.id,
            type: i.type,
            title: question ? question.title : null,
            data: i.type === 'choice' || i.type === 'checkbox'
                ? question?.options.map((option, optionIndex) => ({
                    id: `${i.id}-${option}-${optionIndex}`,
                    title: option,
                    checked: questionAnswers.some(answer => answer.value === option),
                })) || []
                : questionAnswers.length > 0
                    ? questionAnswers[0].value
                    : '',
        };
    });
    return combinedArray.filter(i => i);
};
export const SurveyContainer = React.memo(({ member_id, test_id, item, isPreview, submitted, questionId, handlerUpdateSurveyData, isRegist, }) => {
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const [surveyData, setSurveyData] = React.useState([]);
    const [questionList, setQuestionList] = React.useState([]);
    const [answerList, setAnswerList] = React.useState([]);
    const { data: questionData, refetch: refetchQuestionData } = useQuestionList({
        test_id,
        orderby: ["IF(subject_item='공통',0,1)", 'subject_item', 'number'],
    });
    const { data: submittedAnswers, refetch: refetchAnswerData } = useAnswerList({
        test_id,
        from: member_id || '',
        order: 'value',
    });
    const { data: frequencyData, refetch: refetchFrequencyData } = useSurveyFrequency(test_id);
    useEffect(() => {
        if (test_id) {
            refetchQuestionData();
            member_id !== '' && refetchAnswerData();
            refetchFrequencyData();
        }
    }, [isPreview, isTeacher, test_id, member_id, submitted]);
    useEffect(() => {
        if (test_id)
            return;
        if (questionId === 'survey') {
            setSurveyData(default_survey);
        }
    }, [questionId, test_id]);
    useEffect(() => {
        if (questionData) {
            setQuestionList(questionData.items);
        }
        else {
            setQuestionList([]);
        }
    }, [questionData]);
    useEffect(() => {
        if (member_id === '') {
            setAnswerList([]);
            return;
        }
        if (submittedAnswers) {
            setAnswerList(submittedAnswers.items);
        }
        else {
            setAnswerList([]);
        }
    }, [member_id, submittedAnswers]);
    useEffect(() => {
        if (test_id && questionList && answerList) {
            const survey = getSurveyData(item, questionList, answerList);
            setSurveyData(survey && survey.length === 0 ? default_survey : survey);
        }
    }, [test_id, questionList, answerList]);
    if (member_id && item.status !== 'created' && !submitted) {
        return (React.createElement("div", { className: "submit-false" },
            React.createElement("p", null, "\uBBF8\uC81C\uCD9C \uC0AC\uC6A9\uC790 \uC785\uB2C8\uB2E4.")));
    }
    if (isRegist) {
        return (React.createElement("div", { className: "flex column survey_wrapper" },
            React.createElement(SurveyLayout, { surveyData: surveyData, isRegist: true, setSurveyData: handlerUpdateSurveyData })));
    }
    else {
        if (isPreview) {
            return (React.createElement("div", { className: "survey-submit-view" },
                React.createElement(SurveyLayout, { surveyData: surveyData, isSubmit: true, isRegist: false })));
        }
        else {
            if (member_id !== '') {
                return (React.createElement("div", { className: "survey-submit-view" },
                    React.createElement(SurveyLayout, { surveyData: surveyData, isSubmit: member_id === '' ? true : submitted, isRegist: false })));
            }
            return (React.createElement("div", { className: "survey-submit-view" },
                React.createElement(SurveyResultLayout, { surveyData: surveyData, isSubmit: true, isRegist: false, frequencies: frequencyData || {} })));
        }
    }
});

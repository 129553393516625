import { throttle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Button, Table } from '../../../common';
import { useMemberInfo } from '../../../server';
import { deleteKlassMember, modifyKlassMember } from '../../../server/classes';
import { TOGGLE_POPUP } from '../../base/popup';
import { SearchInput } from '../ui';
import { MemberRoleFilter } from './MemberRoleFilter';
export const MembersTable = React.memo(({ id, isPopup = false, isModify, defaultValues, handlerUpdateMemberIds, }) => {
    const { t } = useTranslation();
    const allChoiceMemberIds = useRef(defaultValues);
    const countRef = useRef(10);
    const pageCountRef = useRef(10);
    const [filterOption, setFilterOption] = useState(['all']);
    const [searchOption, setSearchOption] = useState('');
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [testData, setTestData] = useState([]);
    const [choiceMemberIds, setChoiceMemberIds] = useState(defaultValues);
    const modifyKlassMemberMutation = useMutation(modifyKlassMember);
    const deleteKlassMemberMutation = useMutation(deleteKlassMember);
    // 해당 클래스에 해당하는 사용자 가져오기
    const { data, refetch } = useMemberInfo({
        ...(!isPopup ? { class_id: id } : {}),
        ...(filterOption.includes('all') ? { level: ['02', '03'] } : { level: filterOption }),
        ...(searchOption ? { nickname_like: searchOption } : {}),
        offset: page * countRef.current,
        limit: countRef.current,
        orderby: ['level ASC', 'id'],
    });
    const refetchList = throttle(() => refetch(), 1000);
    const { headTitleList, tableReadDataKeys } = useMemo(() => {
        const headTitleList = [
            // ...(isModify && !isPopup ? [{ title: 'delete', space: 0.5, maxWidth: 40 }] : []),
            { title: 'name', space: 2 },
            { title: 'participant', space: 1 },
            ...(isPopup ? [{ title: 'status', space: 1 }] : []),
        ];
        const tableReadDataKeys = [
            // ...(isModify && !isPopup ? [{ title: 'delete', space: 0.5, maxWidth: 40 }] : []),
            { title: 'nickname', space: 2 },
            { title: 'level_name', space: 1 },
            ...(isPopup ? [{ title: 'status', space: 1 }] : []),
        ];
        return { headTitleList, tableReadDataKeys };
    }, [isModify, isPopup]);
    useEffect(() => {
        setSearchOption('');
        setPage(0);
    }, [isModify]);
    useEffect(() => {
        setChoiceMemberIds(defaultValues);
        allChoiceMemberIds.current = defaultValues;
    }, [isPopup, defaultValues]);
    React.useEffect(() => {
        setPage(0);
    }, [filterOption, searchOption]);
    React.useEffect(() => {
        refetchList();
    }, [isPopup, isModify, filterOption, searchOption, page, countRef.current]);
    React.useEffect(() => {
        if (data) {
            const { total_count, items, found_count } = data;
            if (total_count > 0 && found_count === 0) {
            }
            else {
                if (items.length > 0) {
                    setTestData(items);
                    setTotalCount(total_count);
                    return;
                }
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    const handlerMoreData = useCallback((newPage) => {
        setPage(newPage);
    }, []);
    const handlerCheckList = useCallback((selectedMemberIds) => {
        const defaultCheckList = testData
            .filter(item => allChoiceMemberIds.current.includes(item.id))
            .map(item => item.id);
        const deselectedIds = defaultCheckList.filter(id => !selectedMemberIds.includes(id));
        allChoiceMemberIds.current = [
            ...allChoiceMemberIds.current.filter(id => !deselectedIds.includes(id)),
            ...selectedMemberIds.filter(id => !allChoiceMemberIds.current.includes(id)),
        ];
    }, [testData, isPopup, allChoiceMemberIds.current]);
    const handleSearch = useCallback((searchValue) => {
        setSearchOption(searchValue);
        setPage(0);
    }, []);
    const openDialog = useCallback(() => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: MembersTable,
            componentProps: {
                id,
                isPopup: true,
                isModify,
                defaultValues: allChoiceMemberIds.current,
                handlerUpdateMemberIds: (members) => {
                    refetchList();
                    allChoiceMemberIds.current = members;
                },
            },
            title: '참여자 추가',
            width: 800,
            isFit: true,
        });
    }, [isModify, allChoiceMemberIds.current]);
    const renderParticipantCount = useMemo(() => (React.createElement("p", { className: "title" },
        "\uCC38\uC5EC\uC790",
        isPopup ? (React.createElement(React.Fragment, null,
            "(",
            allChoiceMemberIds.current.length.toString().padStart(2, '0'),
            "\uBA85/",
            totalCount.toString().padStart(2, '0'),
            "\uBA85)")) : ('(' + allChoiceMemberIds.current.length.toString().padStart(2, '0') + '명)'))), [isPopup, totalCount, allChoiceMemberIds.current.length]);
    const renderSearchBar = useMemo(() => React.createElement(SearchInput, { defaultValue: searchOption, setSearchOption: handleSearch }), [searchOption, handleSearch]);
    const renderEmpty = useMemo(() => totalCount <= 0 && (React.createElement("div", { className: "empty_data" },
        React.createElement("p", null, "\uB4F1\uB85D\uB41C \uCC38\uAC00\uC790\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4."))), [totalCount]);
    const renderTitle = useMemo(() => {
        if (isPopup) {
            return (React.createElement("div", { className: "table-title", style: { alignItems: 'flex-end' } },
                React.createElement("div", { className: "left-wrap" },
                    renderParticipantCount,
                    React.createElement(MemberRoleFilter, { isModify: isModify, defaultValues: filterOption, handlerUpdateState: setFilterOption })),
                React.createElement("div", { className: "right-wrap" }, renderSearchBar)));
        }
        else {
            return (React.createElement("div", { className: "table-title" },
                React.createElement("div", { className: "left-wrap" }, renderParticipantCount),
                React.createElement("div", { className: "right-wrap" },
                    React.createElement(Button, { className: "btn-semi-round btn-blue btn-xmd", text: "\uCD94\uAC00 \uBC0F \uC0AD\uC81C", onClick: openDialog }),
                    renderSearchBar)));
        }
    }, [isPopup, renderParticipantCount, filterOption, renderSearchBar, openDialog]);
    const renderItem = useCallback((item) => {
        if (!item)
            return null;
        return tableReadDataKeys.map((headItem, idx) => (React.createElement("div", { key: `${headItem.title}-${idx}`, className: "table-item member-item", style: {
                flex: headItem.space,
                maxWidth: headItem.maxWidth,
                justifyContent: 'flex-start',
                alignItems: 'center',
                display: 'flex',
                overflow: 'hidden',
            } }, (() => {
            switch (headItem.title) {
                case 'delete':
                    return (React.createElement(Button, { className: "btn-semi-round btn-line-peach btn-xsm", text: "\uC0AD\uC81C", onClick: () => {
                            const updatedIds = choiceMemberIds.filter(id => id !== item.id);
                            handlerCheckList(updatedIds);
                            setTestData(prevData => prevData.filter(data => data.id !== item.id));
                            setChoiceMemberIds(updatedIds);
                        } }));
                case 'nickname':
                    return (React.createElement("p", { className: "overText2" },
                        item[headItem.title],
                        " (",
                        item.identifier,
                        ")"));
                case 'level_name':
                    return (React.createElement("p", { className: "overText2" }, t(`common.${item[headItem.title]}`)));
                case 'status':
                    return (React.createElement("p", { className: "overText" }, item[headItem.title] === 'inactive'
                        ? t(`status.${item[headItem.title]}`)
                        : t(`status.active`)));
                default:
                    return React.createElement("p", { className: "overText2" }, item[headItem.title]);
            }
        })())));
    }, [tableReadDataKeys, choiceMemberIds, setTestData, handlerCheckList]);
    const defaultCheckList = useMemo(() => {
        return testData
            .filter(item => allChoiceMemberIds.current.includes(item.id))
            .map(item => item.id);
    }, [testData, allChoiceMemberIds.current]);
    const renderTable = useMemo(() => {
        return (React.createElement(Table, { className: "table-grey", showCheckBox: isPopup, defaultCheckList: defaultCheckList, isInnerPage: true, isInifinite: false, page: page, pagingCount: pageCountRef.current, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderTitle: renderTitle, renderItem: renderItem, renderEmpty: renderEmpty, handlerMoreData: handlerMoreData, handlerCheckList: handlerCheckList }));
    }, [
        isPopup,
        page,
        pageCountRef,
        testData,
        totalCount,
        headTitleList,
        renderTitle,
        renderItem,
        renderEmpty,
        handlerMoreData,
        handlerCheckList,
    ]);
    if (isPopup) {
        return (React.createElement("div", { className: "admin-container admin-container-popup" },
            React.createElement("div", { className: "members-popup" },
                React.createElement("div", { className: "members-table-wrap" }, renderTable),
                React.createElement("div", { className: "btn-container" },
                    React.createElement(Button, { text: "\uCDE8\uC18C", className: "btn-default-round btn-grey btn-xmd", onClick: () => APP.eventManager.publish(TOGGLE_POPUP) }),
                    React.createElement(Button, { text: "\uD655\uC778", className: "btn-default-round btn-blue btn-xmd", onClick: async () => {
                            console.log({
                                test: allChoiceMemberIds.current,
                                defaultValues,
                            });
                            const enrollMember = allChoiceMemberIds.current.filter(id => !defaultValues.includes(id));
                            const leaveMember = defaultValues.filter(id => !allChoiceMemberIds.current.includes(id));
                            try {
                                // 새로운 멤버 추가 처리
                                const enrollPromises = modifyKlassMemberMutation
                                    .mutateAsync({
                                    class_id: id,
                                    props: {
                                        members: enrollMember,
                                    },
                                })
                                    .then(() => ({ status: 'fulfilled', id: enrollMember }))
                                    .catch(() => ({
                                    status: 'rejected',
                                    id: enrollMember,
                                }));
                                // 기존 멤버 삭제 처리
                                const leavePromises = leaveMember && leaveMember.length <= 0
                                    ? true
                                    : deleteKlassMemberMutation
                                        .mutateAsync({
                                        class_id: id,
                                        props: {
                                            members: leaveMember,
                                        },
                                    })
                                        .then(() => ({
                                        status: 'fulfilled',
                                        id: leaveMember,
                                    }))
                                        .catch(() => ({
                                        status: 'rejected',
                                        id: leaveMember,
                                    }));
                                // Promise.allSettled을 사용하여 모든 작업의 결과를 수집
                                const results = await Promise.allSettled([
                                    enrollPromises,
                                    leavePromises,
                                ]);
                                // 처리 결과에 따라 성공/실패 분류
                                const successIds = [];
                                const failedIds = [];
                                results.forEach((result) => {
                                    const resultValue = result.value;
                                    if (resultValue.status === 'fulfilled') {
                                        successIds.push(resultValue.id);
                                    }
                                    else {
                                        failedIds.push(resultValue.id);
                                    }
                                });
                                // 멤버 업데이트 후 팝업 닫기
                                handlerUpdateMemberIds &&
                                    handlerUpdateMemberIds(allChoiceMemberIds.current);
                                refetchList();
                                APP.eventManager.publish('UPDATE_KLASS_LIST');
                                APP.eventManager.publish(TOGGLE_POPUP);
                            }
                            catch (error) {
                                console.error('전체 에러:', error);
                            }
                        } })))));
    }
    else {
        return renderTable;
    }
});

import { MiddlewareRegistry } from '../../../redux/regist';
import { postRefreshToken, postVerifyToken } from '../../../server';
import { getClassesList } from '../../../server/classes';
import { setManager, setToken, setUserInfo } from '../account';
import { getClassesId, setClassesData } from '../classes';
import { APP_WILL_LOAD, SET_CHANGE_CLASSES } from './actionTypes';
function getCookie(name) {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName === name) {
            return cookieValue;
        }
    }
    return null;
}
const initRefreshToken = async (store) => {
    const refresh_token = getCookie('refresh_token');
    const classesId = getClassesId();
    try {
        if (classesId || location.pathname.includes('admin')) {
            try {
                const classData = await getClassesList({ id: classesId });
                const { total_count, items } = classData.result;
                if (total_count > 0 || location.pathname.includes('admin')) {
                    const classes_data = items.find(i => i.id === classesId);
                    store.dispatch(setClassesData(classes_data));
                    if (refresh_token) {
                        const response = await postRefreshToken({ refresh_token });
                        const access_token = response.result.access_token;
                        if (access_token) {
                            store.dispatch(setToken({ access_token, refresh_token }));
                            const verifyResponse = await postVerifyToken({ access_token });
                            const user = verifyResponse.result.user;
                            if (user) {
                                if (location.pathname.includes('admin') &&
                                    user.role_name !== 'admin') {
                                    APP.eventManager.publish('updateMenu', 'accept');
                                    return;
                                }
                                if (user.role_name !== 'admin') {
                                    const verifyClasses = await getClassesList({
                                        id: classesId,
                                        belong_any: user.id,
                                    });
                                    if (verifyClasses.result.total_count < 1) {
                                        throw Error('해당 클래스에 접근할 수 없습니다.');
                                    }
                                }
                                else {
                                    if (classes_data &&
                                        user?.tenant_id !== 'default' &&
                                        user?.tenant_id !== classes_data.tenant_id) {
                                        throw Error('해당 클래스에 접근할 수 없습니다.');
                                    }
                                }
                                if (user) {
                                    classes_data &&
                                        store.dispatch(setManager(classes_data.managers));
                                    const isStudent = user.role_name === 'admin'
                                        ? false
                                        : classes_data.members.includes(user.id);
                                    store.dispatch(setUserInfo(user, !isStudent));
                                }
                                else {
                                    throw Error('로그인 오류입니다. 다시 로그인하세요.');
                                }
                            }
                        }
                    }
                    else {
                        if (location.pathname.includes('admin')) {
                            APP.eventManager.publish('updateMenu', 'accept');
                            return;
                        }
                        throw Error('로그인 오류입니다. 다시 로그인하세요.');
                    }
                }
            }
            catch (error) {
                //alert('로그인 오류입니다. 다시 로그인하세요. ' + error);
                alert(error.message);
                console.error('Error in initRefreshToken:', error);
                window.close();
            }
        }
        else {
            window.close();
        }
    }
    catch (err) {
        console.error('Error in initRefreshToken:', err);
    }
};
const changeClassesData = async (store) => {
    const classesId = getClassesId();
    try {
        if (classesId) {
            try {
                const classData = await getClassesList({ id: classesId });
                const { total_count, items } = classData.result;
                if (total_count > 0) {
                    const classes_data = items.find(i => i.id === classesId);
                    store.dispatch(setClassesData(classes_data));
                }
            }
            catch (error) {
                //alert('로그인 오류입니다. 다시 로그인하세요. ' + error);
                alert(error.message);
                console.error('Error in initRefreshToken:', error);
                window?.close();
            }
        }
        else {
            window?.close();
        }
    }
    catch (err) {
        console.error('Error in initRefreshToken:', err);
    }
};
MiddlewareRegistry.register(store => next => async (action) => {
    switch (action.type) {
        case APP_WILL_LOAD:
            if (location.pathname.includes('player')) {
                const access_token = localStorage.getItem('access_token');
                if (access_token) {
                    store.dispatch(setToken({ access_token, refresh_token: null }));
                }
                return next(action);
            }
            try {
                await initRefreshToken(store);
                setInterval(async () => {
                    initRefreshToken(store);
                }, 24 * 60 * 60 * 1000); // Repeat every 15 minutes
            }
            catch (error) {
                console.error('Error fetching user info:', error);
                if (!location.pathname.includes('login')) {
                    alert('잘못된 사용자 정보입니다. 다시 로그인하세요' + error);
                }
                // 예외 발생 시 로직 처리
            }
            // if (location.pathname.includes('login')) return;
            // window.location.assign(`/login`);
            break;
        case SET_CHANGE_CLASSES:
            await changeClassesData(store);
            break;
    }
    return next(action);
});

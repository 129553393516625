import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalState } from '../../GlobalStateProvider';
import { Button } from '../../common';
import { MyPageDropDown } from '../base/account';
import { appWillLoad, appWillUnmount } from '../base/app';
import { AdminContent } from './AdminContent';
import { AdminMenu } from './AdminMenu';
import { AdminMenuDetail } from './AdminMenuDetail';
import { DEFAULT_MENU } from './constant';
import { getMenuDetailData } from './functions';
export const AdminMain = React.memo(() => {
    const dispatch = useDispatch();
    const { isMobile, isTablet } = useGlobalState();
    const load = useSelector((state) => state['base/app'].isLoad);
    const previousDepthRef = useRef({
        menu: DEFAULT_MENU,
        menuDetail: '',
        showDepth: 'list',
        props: {},
    });
    const [initLoad, setInitLoad] = useState(false);
    const [menu, setMenu] = useState(DEFAULT_MENU);
    const [menuDetail, setMenuDetail] = useState('');
    const [showDepth, setShowDepth] = useState();
    const [props, setProps] = useState({});
    useEffect(() => {
        if (!load)
            dispatch(appWillLoad());
        return () => {
            dispatch(appWillUnmount());
        };
    }, []);
    const loadStateFromLocalStorage = useCallback(() => {
        const menuOption = localStorage.getItem('menu_option');
        if (menuOption) {
            try {
                const parsedMenuOption = JSON.parse(menuOption);
                setMenu(parsedMenuOption.menu || DEFAULT_MENU);
                setMenuDetail(parsedMenuOption.menuDetail || '');
                setShowDepth(parsedMenuOption.showDepth || 'list');
                setProps(parsedMenuOption.props || {});
            }
            catch (e) {
                console.error('Error parsing menu_option from localStorage:', e);
            }
        }
        else {
            const detail = getMenuDetailData(DEFAULT_MENU, menuDetail);
            setMenu(DEFAULT_MENU);
            setMenuDetail(detail);
            setShowDepth('list');
            setProps({});
        }
        setInitLoad(true);
    }, []);
    // 이전 메뉴로 이동
    const handlerPreviousMenu = useCallback(() => {
        const { menu: prevMenu, menuDetail: prevMenuDetail, showDepth: prevShowDepth, props: prevProps, } = previousDepthRef.current;
        setMenu(prevMenu);
        setMenuDetail(prevMenuDetail);
        setShowDepth(showDepth === prevShowDepth || (showDepth === 'detail' && prevShowDepth === 'modify')
            ? 'list'
            : prevShowDepth);
        setProps(prevProps);
    }, [menu, menuDetail, showDepth, props, previousDepthRef.current]);
    const handlerUpdateMenu = useCallback((data) => {
        // console.log(initLoad, data);
        if (initLoad) {
            setShowDepth(data.showDepth);
            setProps(data.props);
        }
    }, [initLoad]);
    const handleMenuChange = useCallback((newMenu) => {
        setMenu(newMenu);
        setShowDepth('list');
        setProps({});
    }, []);
    useEffect(() => {
        loadStateFromLocalStorage();
        APP.eventManager.subscribe('CHANGE_MENU', handlerUpdateMenu);
        return () => {
            APP.eventManager.unsubscribe('CHANGE_MENU', handlerUpdateMenu);
        };
    }, [loadStateFromLocalStorage, initLoad]);
    useEffect(() => {
        APP.eventManager.subscribe('PREVIOUS_MENU', handlerPreviousMenu);
        return () => {
            APP.eventManager.unsubscribe('PREVIOUS_MENU', handlerPreviousMenu);
        };
    }, [handlerPreviousMenu]);
    useEffect(() => {
        if (initLoad) {
            localStorage.setItem('menu_option', JSON.stringify({ menu, menuDetail, showDepth, props }));
        }
        return () => {
            if (initLoad) {
                previousDepthRef.current = { menu, menuDetail, showDepth, props };
            }
        };
    }, [initLoad, menu, menuDetail, showDepth, props]);
    useEffect(() => {
        if (initLoad) {
            const detail = getMenuDetailData(menu, menuDetail);
            // console.log(menu, detail, menuDetail);
            setMenuDetail(detail);
        }
    }, [menu]);
    return (React.createElement("div", { className: `admin-container ${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'}  ` },
        React.createElement("div", { className: "admin-sidebar" },
            React.createElement(Button, { className: "btn-logo", option: {
                    buttonBeforeIcon: {
                        show: true,
                        width: 98,
                        height: 35,
                        name: 'logo_white',
                    },
                }, onClick: () => handleMenuChange(DEFAULT_MENU) }),
            React.createElement(AdminMenu, { menu: menu, menuDetail: menuDetail, setMenuDetail: setMenuDetail })),
        React.createElement("div", { className: "admin-content" },
            React.createElement("div", { className: "admin-menu-detail" },
                React.createElement(AdminMenuDetail, { menu: menu, setMenu: handleMenuChange }),
                React.createElement(MyPageDropDown, null)),
            React.createElement(AdminContent, { menu: menu, menuDetail: menuDetail, showDepth: showDepth, props: props, handlerPreviousMenu: handlerPreviousMenu }))));
});

import _ from 'lodash';
import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalState } from '../../GlobalStateProvider';
import { TableOption } from '../option';
import InfinityScroll from './InfinityScroll';
import Paging from './Paging';
import { TableHeader } from './TableHeader';
import RenderBody from './TableItem';
// 컴포넌트 선언
export const Table = React.memo(({ className, selectedId, forceShowheader = true, titleFontClass = 'body3', showIndex = false, showCheckBox = false, isInifinite, isInnerPage = false, isKeepChecked = false, data = [], showCount = 10, pagingCount = 10, defaultCheckList, disableIdList = [], noPaging, page, totalCount, sortDefault, headTitleList, renderTitle, renderFixItem, renderItem, renderMoreItem, renderEmpty, option, styles, handlerMoreData, handlerSelectItem, handlerUpdateList, handlerCheckList, setFixedAllChecked, }) => {
    // 상태 정의
    const [sort, setSort] = useState(sortDefault);
    const [desc, setDesc] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [tableList, setTableList] = useState(data);
    const isShowMore = useMemo(() => tableList?.length < totalCount, [tableList, totalCount]);
    const tableOption = useMemo(() => _.merge({}, TableOption, option), [option]);
    const handlerScroll = throttle(() => {
        APP.eventManager.publish('scroll');
    }, 1000, { leading: true, trailing: false });
    // 정렬 업데이트 핸들러
    const handlerUpdateSort = (changeSort) => {
        if (sort === changeSort) {
            setDesc(!desc);
        }
        else {
            setSort(changeSort);
            setDesc(false);
        }
    };
    const handlerUpdateCheck = () => {
        setCheckedList([]);
    };
    const cleanCheckList = props => {
        if (props?.className === 'sms-send-list') {
            setCheckedList(props?.defaultChekList ? props.defaultChekList : []);
            return;
        }
        if (props?.className !== className)
            return;
        setCheckedList([]);
    };
    useEffect(() => {
        APP.eventManager.subscribe('SUCCESS_DELETE', handlerUpdateCheck);
        return () => {
            APP.eventManager.unsubscribe('SUCCESS_DELETE', handlerUpdateCheck);
        };
    }, []);
    useEffect(() => {
        APP.eventManager.subscribe('UPDATE_TABLE_ITEM', cleanCheckList);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_TABLE_ITEM', cleanCheckList);
        };
    }, [className]);
    useEffect(() => {
        if (!isInifinite && !isKeepChecked) {
            setCheckedList([]);
        }
    }, [page]);
    useEffect(() => {
        if (handlerCheckList) {
            handlerCheckList(checkedList);
        }
    }, [checkedList]);
    useEffect(() => {
        throttleAllChecked();
    }, [tableList, checkedList]);
    useEffect(() => {
        if (defaultCheckList && defaultCheckList.length > 0 && !isKeepChecked) {
            setCheckedList(defaultCheckList);
        }
    }, [defaultCheckList]);
    // 데이터 변경 감지 및 리스트 업데이트
    useEffect(() => {
        if (_.isEqual(tableList, data))
            return;
        setTableList(data);
    }, [data]);
    // 정렬 변경 감지 및 리스트 업데이트
    useEffect(() => {
        handlerUpdateList && handlerUpdateList(sort, desc);
    }, [sort, desc]);
    const throttleAllChecked = () => {
        const ids = tableList.map(obj => obj.id);
        const newCheckedList = checkedList.filter(item => ids.includes(item));
        if (tableList.length > 0 &&
            ((_.isEqual(ids, checkedList) && checkedList.length === tableList.length) ||
                (isKeepChecked &&
                    checkedList.length > 0 &&
                    tableList.length === newCheckedList.length)))
            setAllChecked(true);
        else
            setAllChecked(false);
    };
    // 체크박스 핸들러
    const handlerCheck = (id, checked) => {
        if (id === 'all') {
            setAllChecked(checked);
            setFixedAllChecked && setFixedAllChecked(checked);
            if (checked)
                setCheckedList(prevData => {
                    const newIds = tableList.map(item => item.id);
                    return isKeepChecked ? [...prevData, ...newIds] : newIds;
                });
            else
                setCheckedList(prevData => {
                    const ids = new Set(tableList.map(obj => obj.id));
                    return isKeepChecked
                        ? prevData.filter(item => !ids.has(item)) // ids에 포함되지 않은 항목만 필터링합니다.
                        : [];
                });
        }
        else {
            setCheckedList(checked ? [...checkedList, id] : checkedList.filter(item => item !== id));
        }
    };
    //드래그 앤 드롭 파일 막기
    const handleDrop = e => {
        e.preventDefault();
        const dt = e.dataTransfer;
        // 드롭된 항목이 폴더인지 파일인지 확인
        for (let i = 0; i < dt.items.length; i++) {
            const item = dt.items[i];
            if (item.kind === 'file') {
                const file = item.getAsFile();
                // 파일 확장자 확인 (예시: mp4, mov 확장자만 허용)
                if (file.type.startsWith('video/') &&
                    (file.type.includes('mp4') || file.type.includes('quicktime'))) {
                    // 파일 처리 로직 추가
                }
                else {
                    alert('폴더를 등록할 수 없습니다.');
                    return;
                }
            }
            else {
                alert('폴더를 등록할 수 없습니다.');
                return;
            }
        }
    };
    const handleDragOver = e => {
        e.preventDefault();
    };
    const { isMobile, isTablet } = useGlobalState();
    const renderPage = useMemo(() => {
        if (noPaging)
            return null;
        return (tableList?.length > 0 &&
            !isInifinite && (React.createElement(Paging, { defaultShowCount: pagingCount, showCount: showCount, page: page, pagingCount: pagingCount, data: tableList, totalCount: totalCount, handlerMoreData: handlerMoreData })));
    }, [noPaging, tableList, showCount, page, pagingCount, totalCount, isInifinite]);
    const renderTableItem = useMemo(() => (React.createElement(React.Fragment, null,
        renderFixItem,
        renderEmpty || (React.createElement(RenderBody, { isMobile: isMobile, tableList: tableList, page: page, selectedId: selectedId, showCount: showCount, showCheckBox: showCheckBox, showIndex: showIndex, styles: styles, checkedList: checkedList, disableIdList: disableIdList, handlerSelectItem: handlerSelectItem, handlerCheck: handlerCheck, isInifinite: isInifinite, renderItem: renderItem, renderMoreItem: renderMoreItem })),
        isShowMore && isInifinite && (React.createElement(InfinityScroll, { page: page, data: tableList, totalCount: totalCount, handlerMoreData: handlerMoreData })))), [
        tableList,
        disableIdList,
        page,
        selectedId,
        showCount,
        showCheckBox,
        showIndex,
        styles,
        checkedList,
        isInifinite,
    ]);
    // 테이블 컴포넌트 반환
    return (React.createElement(React.Fragment, null,
        renderTitle,
        React.createElement("div", { className: `table ${className}` },
            forceShowheader && (React.createElement(TableHeader, { allChecked: allChecked, titleFontClass: titleFontClass, styles: styles, showCheckBox: showCheckBox, showIndex: showIndex, headTitleList: headTitleList, sort: sort, desc: desc, handlerUpdateSort: handlerUpdateSort, handlerCheck: handlerCheck, tableOption: tableOption })),
            React.createElement("div", { onDrop: handleDrop, onDragOver: handleDragOver, className: "scroll-content", style: styles?.scrollContent, onScroll: handlerScroll },
                isMobile ? (React.createElement("div", { className: "table-item-wrap" }, renderTableItem)) : (renderTableItem),
                isInnerPage && renderPage)),
        !isInnerPage && renderPage));
});

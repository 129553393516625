import React, { useEffect } from 'react';
import { CheckboxInput } from '../ui';
const MEMBER_FILTER_OPTION = [
    { id: 'all', value: '전체' },
    // { id: '01', value: '강사' },
    { id: '02', value: '조교' },
    { id: '03', value: '학생' },
    // { id: '04', value: '관리자' },
];
export const MemberRoleFilter = React.memo(({ isModify, defaultValues, handlerUpdateState, }) => {
    const [values, setValues] = React.useState(defaultValues);
    useEffect(() => {
        handlerUpdateState(values);
    }, [values]);
    return (React.createElement(CheckboxInput, { inputType: "radio", noShowButtonArea: !isModify, defaultValues: defaultValues, handlerUpdate: setValues, options: MEMBER_FILTER_OPTION }));
});

import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Colors } from '../../../Colors';
import { Button } from '../../../common';
export const CheckboxInput = forwardRef(({ className, title, defaultValues = [], options, handlerUpdate, buttonStyle = {
    width: '123px',
}, noShowButtonArea = true, buttonOption, inputType = 'checkbox', // Default to checkbox
disabled, ...props }, ref) => {
    const [selectedValues, setSelectedValues] = useState(defaultValues);
    useImperativeHandle(ref, () => ({
        focus: () => {
            // Focus on the first input or another appropriate element
        },
        getValues: () => selectedValues,
        setValues: (newValues) => {
            setSelectedValues(newValues);
            handlerUpdate(newValues);
        },
    }));
    const handleInputChange = (e) => {
        const { value, checked } = e.target;
        let updatedValues;
        if (inputType === 'checkbox') {
            updatedValues = checked
                ? [...selectedValues, value]
                : selectedValues.filter(v => v !== value);
        }
        else {
            updatedValues = [value];
        }
        setSelectedValues(updatedValues);
        handlerUpdate(updatedValues);
    };
    useEffect(() => {
        if (_.isEqual(selectedValues, defaultValues))
            return;
        setSelectedValues(defaultValues);
    }, [defaultValues]);
    return (React.createElement("div", { className: `text-input ${className}` },
        title && React.createElement("label", { className: "title" }, title),
        React.createElement("div", { className: "choice-wrap" }, options.map(option => (React.createElement("label", { key: option.id, style: { cursor: `${!disabled ? 'pointer' : 'default'}` } },
            React.createElement("input", { type: inputType, value: option.id, checked: selectedValues.includes(option.id), onChange: handleInputChange, name: inputType === 'radio' ? title : undefined, disabled: disabled, ...props }),
            React.createElement(Button, { text: option.value, option: {
                    buttonBeforeIcon: {
                        show: true,
                        name: selectedValues.includes(option.id)
                            ? inputType === 'radio'
                                ? 'radio_checked'
                                : 'checked'
                            : inputType === 'radio'
                                ? 'radio_unchecked'
                                : 'unchecked',
                        size: 22,
                        color: Colors.transparent,
                    },
                } }))))),
        noShowButtonArea && buttonOption && (React.createElement("div", { style: buttonStyle },
            React.createElement(Button, { className: buttonOption.className, text: buttonOption.title, onClick: buttonOption.onClick })))));
});

import React, { Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import { GlobalProvider, useGlobalState } from './GlobalStateProvider';
import LoadingPage from './Loading';
import './assest/css/all.scss';
import { default as eventManager } from './eventManager';
import { StreamingContainer } from './features/DST/DSTT007/component/DSTT007_List_Child/StreamingContainer';
import { AdminMain } from './features/admin/AdminMain';
import { ErrorPage, Login } from './features/base/account';
import { Login2 } from './features/base/account/component/Login2';
import { appWillLoad, appWillUnmount, closeWindow, setClassesId } from './features/base/app';
import { MainClasses } from './features/base/layout';
import { PopupContainer } from './features/base/popup';
import i18next from './lang/i18next';
import './redux/app/middleware.any';
import './redux/app/reducers.any';
import createAppStore from './redux/store';
import { changeMenuFromName } from './features/menu/action';
window.APP =
    {
        eventManager: eventManager,
        deviceType: 'desktop',
    } || {};
const App = React.memo(() => {
    const queryClient = new QueryClient();
    const store = createAppStore();
    return (React.createElement(I18nextProvider, { i18n: i18next },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(Provider, { store: store },
                React.createElement(Suspense, { fallback: React.createElement(LoadingPage, { defaultLoading: true }) },
                    React.createElement(Router, null,
                        React.createElement(GlobalProvider, null,
                            React.createElement(RouterApp, null))))))));
});
export default App;
const RouterApp = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { classesId } = useGlobalState();
    const load = useSelector((state) => state['base/app'].isLoad);
    // Function to clear cache
    const clearCache = () => {
        if ('caches' in window) {
            caches.keys().then(names => {
                names.forEach(name => {
                    caches.delete(name);
                });
            });
        }
    };
    useEffect(() => {
        clearCache();
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVh();
        window.addEventListener('resize', setVh);
        return () => {
            window.removeEventListener('resize', setVh);
        };
    }, []);
    useEffect(() => {
        dispatch(appWillLoad());
        return () => {
            dispatch(appWillUnmount());
        };
    }, []);
    useEffect(() => {
        if (classesId) {
            dispatch(setClassesId());
        }
    }, [classesId]);
    const handlerUpdateMenu = (menu, isMain = false) => {
        if (menu === 'logout') {
            closeWindow();
            return;
        }
        else if (menu === 'accept') {
            navigate('/accept');
            closeWindow();
            return;
        }
        else if (menu === 'error') {
            navigate('/error');
            closeWindow();
            return;
        }
        dispatch(changeMenuFromName(menu, isMain));
    };
    useEffect(() => {
        APP.eventManager.subscribe('updateMenu', handlerUpdateMenu);
        return () => {
            APP.eventManager.unsubscribe('updateMenu', handlerUpdateMenu);
        };
    }, []);
    if (!load && !(location.pathname.includes('accept') || location.pathname.includes('error')))
        return React.createElement(LoadingPage, { defaultLoading: true });
    return (React.createElement(React.Fragment, null,
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement("iframe", { src: "https://klassmate.dev.meetmate.co.kr", style: {
                        width: '100%',
                        height: '100%',
                        outline: 'none',
                    } }) }),
            React.createElement(Route, { path: "/admin/*", element: React.createElement(AdminMain, null) }),
            React.createElement(Route, { path: "/login", element: React.createElement(Login, null) }),
            React.createElement(Route, { path: "/dev", element: React.createElement(Login2, null) }),
            React.createElement(Route, { path: "/class/*", element: React.createElement(MainClasses, null) }),
            React.createElement(Route, { path: "/accept", element: React.createElement(ErrorPage, { message: "\uC811\uADFC\uC774 \uBD88\uAC00\uB2A5\uD55C \uD398\uC774\uC9C0\uC785\uB2C8\uB2E4. \uB2E4\uC2DC \uB85C\uADF8\uC778\uD574\uC8FC\uC138\uC694." }) }),
            React.createElement(Route, { path: "/error", element: React.createElement(ErrorPage, { message: "\uC911\uBCF5 \uB85C\uADF8\uC778\uC744 \uC9C0\uC6D0\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uB2E4\uC2DC \uB85C\uADF8\uC778 \uD574\uC8FC\uC138\uC694." }) }),
            React.createElement(Route, { path: "/player/*", element: React.createElement(StreamingContainer, null) })),
        React.createElement(LoadingPage, null),
        React.createElement(PopupContainer, null)));
});

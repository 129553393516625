import React from 'react';
import { Button, Icon } from '../../../common';
export const AdminTitle = React.memo(({ oneDepthMenu, twoDepthMenu, children, handlerPreviousMenu, }) => {
    return (React.createElement("div", { className: "admin-title" },
        React.createElement("div", { className: "admin-title-left" },
            React.createElement("p", null, oneDepthMenu),
            twoDepthMenu && (React.createElement(React.Fragment, null,
                React.createElement(Icon, { className: "arrow-right", icon: "arrow", size: 32 }),
                React.createElement("p", null, twoDepthMenu),
                React.createElement(Button, { className: "menu_close", option: {
                        buttonBeforeIcon: {
                            show: true,
                            size: 24,
                            name: 'detail-delete',
                        },
                    }, onClick: handlerPreviousMenu })))),
        React.createElement("div", { className: "admin-title-right" }, children)));
});

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button } from '../../../common';
export const DropDownInput = forwardRef(({ title, defaultValue = '', dropdownList, handlerUpdate, buttonStyle = {
    width: '123px',
}, noShowButtonArea = false, buttonOption, noSelectOption, ...props }, ref) => {
    const selectRef = useRef(null);
    const [list, setList] = useState([]);
    const [value, setValue] = useState(defaultValue);
    useImperativeHandle(ref, () => ({
        focus: () => selectRef.current?.focus(),
        getValue: () => value,
        setValue: (newValue) => {
            setValue(newValue);
            if (selectRef.current) {
                selectRef.current.value = newValue;
            }
        },
    }));
    const onChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        handlerUpdate?.(newValue);
    };
    useEffect(() => {
        if (!noSelectOption) {
            setList(dropdownList);
        }
        else if (noSelectOption.show) {
            setList([{ id: noSelectOption.id, value: noSelectOption.title }, ...dropdownList]);
        }
        else {
            setList(dropdownList);
        }
    }, [dropdownList, noSelectOption]);
    useEffect(() => {
        setValue(defaultValue);
        if (selectRef.current) {
            selectRef.current.value = defaultValue;
        }
    }, [defaultValue]);
    return (React.createElement("div", { className: "text-input" },
        React.createElement("label", { className: "title" }, title),
        React.createElement("select", { className: "custom-select", ref: selectRef, value: value, onChange: onChange, ...props }, list.map((item, index) => (React.createElement("option", { key: index, value: item.id }, item.value)))),
        !noShowButtonArea && (React.createElement("div", { style: buttonStyle }, buttonOption && (React.createElement(Button, { className: buttonOption.className, text: buttonOption.title, onClick: buttonOption.handlerClick }))))));
});

import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../common';
import { getUserInfo } from '../../base/account';
import { TextInput } from '../ui';
import { DEFAULT_COMPANY_ITEM } from './types';
import { DropDownInput } from '../ui/DropDownInput';
import moment from 'moment';
export const CompanyItem = React.memo(({ id, isModify }) => {
    const user = useSelector((state) => getUserInfo(state));
    const passwordRef = React.useRef(null);
    const rePasswordRef = React.useRef(null);
    // 회사명 중복 체크 여부
    const [isNameCheck, setIsNameCheck] = React.useState(false);
    // 회사 ID 중복 체크 여부
    const [isIdCheck, setIsIdCheck] = React.useState(false);
    // 접속 URL 중복 체크 여부
    const [isUrlCheck, setIsUrlCheck] = React.useState(false);
    const [item, setItem] = React.useState(DEFAULT_COMPANY_ITEM);
    const [password, setPassword] = React.useState('');
    const [rePassword, setRePassword] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [companyId, setCompanyId] = React.useState('');
    const [url, setUrl] = React.useState('');
    const [serviceSetting, setServiceSetting] = React.useState('');
    const [serviceStart, setServiceStart] = React.useState('');
    const [serviceEnd, setServiceEnd] = React.useState('');
    // (onClick) 취소 버튼 클릭 시 이전 페이지로 이동
    const cancelMenu = () => {
        APP.eventManager.publish('PREVIOUS_MENU');
    };
    // (onClick) 비밀번호 초기화 버튼 클릭 시 비밀번호 초기화
    const handlerResetPassword = () => {
        if (passwordRef.current)
            passwordRef.current.setValue('');
        if (rePasswordRef.current)
            rePasswordRef.current.setValue('');
        setPassword('');
        setRePassword('');
    };
    // (onClick) 버튼 클릭 시 회사 정보 삭제
    const handlerDelete = () => {
        if (!id)
            return;
        console.log('삭제');
    };
    // (onClick) 버튼 클릭 시 회사 정보 등록 및 수정
    const handlerSubmit = () => {
        if (id) {
            if (isModify) {
                // 수정 패이지
                console.log('수정');
                console.log({
                    companyName,
                    companyId,
                    url,
                    serviceSetting,
                    serviceStart,
                    serviceEnd,
                    password,
                    rePassword,
                });
            }
            else {
                // 상세 페이지 - 수정 페이지로 이동
                APP.eventManager.publish('CHANGE_MENU', {
                    showDepth: 'modify',
                    props: { id },
                });
            }
        }
        else {
            // 등록 페이지
            console.log({
                companyName,
                companyId,
                url,
                serviceSetting,
                serviceStart,
                serviceEnd,
                password,
                rePassword,
            });
        }
    };
    React.useEffect(() => {
        if (!!id) {
            const data = {
                name: 'name' + id,
                id: 'id' + id,
                url: 'url' + id,
                userId: 'userId',
                serviceSetting: 'serviceSetting',
                serviceStart: moment().format('YYYY-MM-DD'),
                serviceEnd: moment().format('YYYY-MM-DD'),
            };
            // 상세 페이지
            setItem(data);
            setCompanyName(data.name);
            setCompanyId(data.id);
            setUrl(data.url);
            setServiceSetting(data.serviceSetting);
            setServiceStart(data.serviceStart);
            setServiceEnd(data.serviceEnd);
        }
        else {
            setItem(DEFAULT_COMPANY_ITEM);
        }
    }, [id]);
    return (React.createElement("div", { className: "regist_container" },
        React.createElement("form", { onSubmit: e => e.preventDefault() },
            React.createElement("div", { className: "regist_box_wrapper" },
                React.createElement("div", { className: "regist_box" },
                    React.createElement(TextInput, { title: "\uD68C\uC0AC\uBA85", handlerUpdate: setCompanyName, defaultValue: item.name, disabled: !isModify, noShowButtonArea: !isModify, buttonOption: isModify && {
                            title: '중복 체크',
                            className: 'btn-semi-round btn-blue btn-xmd',
                            handlerClick: () => {
                                console.log('중복 체크');
                            },
                        } }),
                    React.createElement(TextInput, { title: "\uD68C\uC0AC ID", handlerUpdate: setCompanyId, defaultValue: item.id, disabled: !isModify, noShowButtonArea: !isModify, buttonOption: isModify && {
                            title: '중복 체크',
                            className: 'btn-semi-round btn-blue btn-xmd',
                            handlerClick: () => {
                                console.log('중복 체크');
                            },
                        } }),
                    React.createElement(TextInput, { title: "\uC544\uC774\uB514", noShowButtonArea: !isModify, disabled: true, defaultValue: user?.id, autoComplete: "username" }),
                    isModify && (React.createElement(React.Fragment, null,
                        React.createElement(TextInput, { ref: passwordRef, type: "password", title: "\uBE44\uBC00\uBC88\uD638", disabled: !isModify, noShowButtonArea: !isModify, handlerUpdate: setPassword, buttonOption: {
                                title: '초기화',
                                className: 'btn-semi-round btn-peach btn-xmd',
                                handlerClick: handlerResetPassword,
                            }, autoComplete: "new-password" }),
                        React.createElement(TextInput, { ref: rePasswordRef, type: "password", title: "\uBE44\uBC00\uBC88\uD638 \uD655\uC778", handlerUpdate: setRePassword, autoComplete: "new-password" })))),
                React.createElement("div", { className: "regist_box" },
                    React.createElement(TextInput, { title: "\uC811\uC18D URL", defaultValue: item.url, disabled: !isModify, noShowButtonArea: !isModify, handlerUpdate: setUrl, buttonOption: isModify && {
                            title: '중복 체크',
                            className: 'btn-semi-round btn-blue btn-xmd',
                            handlerClick: () => {
                                console.log('중복 체크');
                            },
                        } }),
                    React.createElement(TextInput, { type: "date", title: "\uC11C\uBE44\uC2A4 \uC2DC\uC791\uC77C", defaultValue: item.serviceStart, disabled: !isModify, noShowButtonArea: !isModify, handlerUpdate: setServiceStart }),
                    React.createElement(TextInput, { type: "date", title: "\uC11C\uBE44\uC2A4 \uC885\uB8CC\uC77C", defaultValue: item.serviceEnd, disabled: !isModify, noShowButtonArea: !isModify, handlerUpdate: setServiceEnd }),
                    React.createElement(DropDownInput, { title: "\uC11C\uBE44\uC2A4 \uC124\uC815", disabled: !isModify, noShowButtonArea: !isModify, noSelectOption: {
                            show: true,
                            id: null,
                            title: '선택 안함',
                        }, defaultValue: '1', dropdownList: [
                            {
                                id: '1',
                                value: '서비스 설정 1',
                            },
                        ], handlerUpdate: setServiceSetting })))),
        React.createElement("div", { className: "btn-container" },
            !!id && !isModify && (React.createElement(Button, { text: "\uC0AD\uC81C", className: "btn-default-round btn-peach btn-xmd", onClick: handlerDelete })),
            React.createElement(Button, { text: !id ? '등록' : '수정', className: "btn-default-round btn-blue btn-xmd", onClick: handlerSubmit }),
            React.createElement(Button, { text: "\uCDE8\uC18C", className: "btn-default-round btn-grey btn-xmd", onClick: cancelMenu }))));
});

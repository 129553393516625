import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';
import { Colors } from '../../../Colors';
import { useGlobalState } from '../../../GlobalStateProvider';
import { Button, InputElement } from '../../../common';
import { FileAttach } from '../../../common/option';
import { modifyQna, postFile, registQna, useQnaList, } from '../../../server';
import { getClassesId } from '../../base/classes';
import { DetailTitleInfo, MobileTabLayout } from '../../base/layout';
import { RegistContent } from '../DSTSL001/DSTSL001_Regist_Child';
import { CategoryRadio } from './DSTS004_Regist_Child/CategoryRadio';
import { QnAListTable } from './DTST004_List_Child';
import { QNA_TYPE } from './constant';
const DSTS004RegistStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 28px;
	align-items: flex-start;
	justify-content: flex-start;
	flex: 1;
	position: relative;
	overflow: hidden;
	width: 100%;

	.border-line {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
	}

	.retry-wrap {
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		border-radius: 4px;
		padding: 0px 9px;
		gap: 0px !important;

		button {
			padding: 6px 0px;
		}
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.regist-content {
		width: 100%;
		overflow: auto;
		height: 100%;
		gap: 28px;
		display: flex;
		flex-direction: column;
		padding-bottom: 28px;
	}

	.border-line {
		padding: 0px 0px 28px 0px;
	}

	.attach_file {
		width: 100%;
	}
`;
const tabletStyles = css `
	.regist-content {
		width: 100%;
		overflow: auto;
		height: 100%;
		gap: 12px;
		display: flex;
		flex-direction: column;
		padding-bottom: 28px;
	}

	.border-line {
		padding: 0px 0px 12px 0px;
	}

	label {
		span {
			font-size: 12px;
		}
	}
`;
const desktopStyles = css `
	.task-label {
		max-width: 135px !important;
		min-width: 135px !important;
	}
	.regist-content {
		width: 100%;
		overflow: auto;
		height: 100%;
		gap: 28px;
		display: flex;
		flex-direction: column;
		padding-bottom: 28px;
	}

	.border-line {
		padding: 0px 0px 28px 0px;
	}
`;
export const DSTS004_Regist = React.memo(({ id, isRetry = false, isTeacher, isPopUp = false, isModify, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const titleRef = React.createRef();
    const class_id = getClassesId();
    const containerRef = useRef(null);
    const [item, setItem] = useState();
    const [title, setTitle] = useState();
    const [category, setCategory] = useState();
    const [privateValue, setPrivateValue] = useState(false);
    const [content, setContent] = useState();
    const [textLength, setTextLength] = useState(0);
    const [files, setFiles] = useState([]);
    const [testData, setTestData] = useState(undefined);
    const [totalCount, setTotalCount] = useState(0);
    const fileMutation = useMutation(postFile);
    const registQnaMutation = useMutation(registQna);
    const modifyQnaMutation = useMutation(modifyQna);
    const { data, refetch } = useQnaList({
        ...(isRetry ? { root_id: id } : { id }),
        with_content: true,
    });
    useEffect(() => {
        if (data) {
            const { found_count, items, total_count } = data;
            if (found_count > 0) {
                setTestData(items);
                const item = items[0];
                if (item && !isRetry) {
                    if (titleRef.current)
                        titleRef.current.value = item.title;
                    setTitle(item.title);
                    setCategory(item.category);
                    setPrivateValue(item.private);
                    setContent(item.content);
                    setFiles(item.files);
                    setItem(item);
                }
                setTotalCount(total_count);
                return;
            }
        }
        if (titleRef.current)
            titleRef.current.value = '';
        setTitle('');
        setContent('');
        setCategory('');
        setPrivateValue(false);
        setItem(undefined);
    }, [data]);
    useEffect(() => {
        if (id)
            refetch();
    }, [id, isTeacher]);
    const previousPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: undefined,
            id: undefined,
        });
    };
    const handleAnswerOK = async (index) => {
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            // if (textLength > MAX_FONT_LENGTH) {
            // 	throw new Error(`글자수는 ${MAX_FONT_LENGTH}자 이하로 입력해주세요.`);
            // }
            if (content.replace(/<[^>]*>/g, '').trim().length === 0) {
                alert('Q&A 질문 내용을 입력해주세요.');
                return;
            }
            let convertAttachFileList = [];
            // 첨부 파일이 있는 경우
            if (files && files.length > 0) {
                const alredyFile = files.filter(file => typeof file === 'string');
                const filterFile = files.filter(file => file instanceof File);
                // 첨부 파일 업로드를 비동기로 실행하고 완료될 때까지 기다립니다.
                if (filterFile && filterFile.length > 0) {
                    // @ts-ignore
                    const data = await fileMutation.mutateAsync(filterFile);
                    convertAttachFileList = [...data.result.link, ...alredyFile];
                }
                else {
                    convertAttachFileList = alredyFile;
                }
            }
            if (id && !isRetry) {
                const data = {
                    attachments: convertAttachFileList,
                    category,
                    content,
                    private: privateValue,
                    title,
                };
                await modifyQnaMutation.mutateAsync({
                    id,
                    props: data,
                });
            }
            else {
                const data = isRetry
                    ? {
                        attachments: convertAttachFileList,
                        class_id,
                        content,
                        title,
                        type: QNA_TYPE.QUESTION,
                        root_id: id,
                    }
                    : {
                        attachments: convertAttachFileList,
                        category,
                        class_id,
                        content,
                        private: privateValue,
                        title,
                        type: isTeacher ? QNA_TYPE.ANSWER : QNA_TYPE.QUESTION,
                    };
                try {
                    //카테고리, 제목 미 설정 시 로딩창 닫기
                    if (!content || !category) {
                        APP.eventManager.publish('SHOW_LOADING', false);
                    }
                    await registQnaMutation.mutateAsync(data);
                }
                catch (error) {
                    if (error) {
                        alert(error);
                    }
                    return;
                }
            }
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'list',
                id: undefined,
            });
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    const renderTitleInfo = useMemo(() => (React.createElement(DetailTitleInfo, { key: 'DetailTitleInfo', title: t('supportRoom.qna'), name: id
            ? isRetry
                ? t('supportRoom.requestion')
                : t('common.modify')
            : t('common.regist'), previousPage: previousPage })), []);
    const renderTitle = useMemo(() => (React.createElement("div", { key: 'title', className: `fext-field ${isRetry ? 'retry-wrap' : ''}` },
        isRetry && React.createElement(Button, { className: "btn_tur mobile-xs", text: 'supportRoom.req' }),
        React.createElement(InputElement, { ref: titleRef, className: "showText", type: "text", placeholder: "\uC81C\uBAA9\uC744 \uC785\uB825\uD558\uC138\uC694", onChange: setTitle, style: {
                backgroundColor: '#ffffff',
                borderStyle: isRetry && !isTeacher && 'none',
                outline: isRetry && !isTeacher && 'none',
            } }))), [titleRef, setTitle, isTeacher, isRetry]);
    const renderTitleWrap = useMemo(() => (React.createElement("div", { key: 'title-wrap', className: "field-global-001" }, renderTitle)), [renderTitle]);
    const renderCategoryRadio = useMemo(() => !isRetry && (React.createElement(CategoryRadio, { key: 'category', defaultCategory: category, setCategory: setCategory, defaultPrivate: privateValue, setPrivate: setPrivateValue })), [category, privateValue]);
    const renderContent = useMemo(() => (React.createElement(RegistContent, { key: 'content', contentName: "\uB0B4\uC6A9", defaultValue: isRetry ? undefined : item?.content, setValue: setContent, handlerTextLength: setTextLength, isTeacher: isTeacher, containerRef: containerRef })), [isRetry, item]);
    const renderFileAttach = useMemo(() => (React.createElement(FileAttach, { key: 'attach-file', id: "attach_file", className: isMobile
            ? 'btn_tur mobile-xs'
            : isTablet
                ? 'btn_tur mobile-sm'
                : 'btn_tur xmd', isRegist: true, isQnA: true, fileList: files, setAttachFileList: setFiles })), [files]);
    const questionListPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'question_list',
            id: testData ? testData[0].id : undefined,
        });
    };
    //모바일일때 질문이력 보기 버튼
    const renderQuestionList = useMemo(() => totalCount >= 2 && (React.createElement(Button, { key: 'renderQuestionList', className: "btn_line_tur mobile-xs", text: t('supportRoom.showQuestionList'), option: {
            buttonBeforeIcon: {
                show: true,
                name: 'question',
                size: 16,
                color: Colors.tur,
            },
        }, styles: { buttonWrap: { width: '100%', padding: '8px 16px', gap: '4px' } }, onClick: questionListPage })), [totalCount]);
    const renderQnAListTable = useMemo(() => isPopUp ? (React.createElement(QnAListTable, { key: "list", id: id, isPopUp: isPopUp })) : (React.createElement(QnAListTable, { key: "list", items: testData, isRetry: true })), [testData, isPopUp, id]);
    if (isPopUp) {
        return (React.createElement(DSTS004RegistStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        component: [renderTitleInfo, renderQnAListTable],
                        styles: { gap: '12px', display: 'flex', flexDirection: 'column' },
                    },
                ], showPageButton: false, previousPageList: {
                    title: t('assignment.goBack'),
                    menuDetail: 're_regist',
                }, test_id: id })));
    }
    if (isMobile) {
        return (React.createElement(DSTS004RegistStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        component: [
                            renderTitleInfo,
                            renderQuestionList,
                            renderCategoryRadio,
                            renderTitleWrap,
                            renderContent,
                            renderFileAttach,
                        ],
                        styles: { gap: '12px', display: 'flex', flexDirection: 'column' },
                    },
                ], showNav: false, showPageButton: true, submitAssignment: handleAnswerOK, previousPageList: { menuDetail: undefined } })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(DSTS004RegistStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
            renderTitleInfo,
            React.createElement("div", { className: "regist-content", ref: containerRef },
                totalCount >= 2 && (React.createElement("div", { className: "field-global-001" },
                    React.createElement("div", { className: "task-label" }, t('supportRoom.questionList')),
                    renderQnAListTable)),
                React.createElement("div", { className: "field-global-001 border-line" },
                    React.createElement("div", { className: "task-label" }, t('supportRoom.title')),
                    renderTitle),
                renderCategoryRadio,
                renderContent,
                renderFileAttach)),
        React.createElement("div", { className: "btn-container", style: { gap: 6 } },
            React.createElement(Button, { className: "btn_white xmd", text: t('common.cancel'), onClick: previousPage }),
            React.createElement(Button, { className: "btn_default xmd", text: t('common.okay'), onClick: () => handleAnswerOK() }))));
});

import React from 'react';
import styled from 'styled-components';
import { Button, Icon, InputElement } from '../../../../common';
import { postLogin, postVerifyToken } from '../../../../server';
import { ClassesDropDown } from '../../classes';
const LoginStyled = styled.div `
	width: 100%;
	height: 100%;
	background: var(--color-tur, #3ab2bf);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;

	> * {
		box-sizing: border-box;
	}

	.login_line {
		display: flex;
		gap: 12px;
		align-items: center;
		justify-content: center;
		width: 100%;

		.login_title {
			width: 95px;
			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: var(--h4-font-size, 20px);
			line-height: var(--h4-line-height, 32px);
			font-weight: var(--h4-font-weight, 700);
			position: relative;
		}

		input {
			padding: 12px;
			flex: 1;
		}
	}

	.login_box {
		position: relative;
		overflow: auto;
		padding: 42px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
		border-radius: 10px;
		margin: 0 auto;
		background: var(--color-white, #ffffff);
		display: flex;
		flex-direction: column;
		gap: 24px;
		justify-content: flex-start;
		align-items: center;
		justify-content: flex-start;
		overflow: auto;
	}

	.login_wrap {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 450px;

		form {
			display: flex;
			flex-direction: column;
			gap: 28px;
			width: 100%;
		}
		.dropdown {
			flex: 1;

			.dropdown-field {
				border: 1px solid var(--color-gray-3, #e5e5e5);
				border-radius: 10px;
				padding: 12px 24px;
			}
		}

		input {
			padding: 15px 24px;
			border: 1px solid var(--color-gray-3, #e5e5e5);
			border-radius: 10px;
			box-sizing: border-box;
		}
	}
`;
export function setCookie(name, value, days) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}
export function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
export const Login = React.memo(() => {
    const [classes, setClasses] = React.useState();
    const [tenantId, setTenantId] = React.useState('');
    const [authName, setAuthName] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isAdmin, setIsAdmin] = React.useState(false);
    // const loginMutation = useMutation(postLogin);
    const onLogin = async () => {
        try {
            if (authName.trim() === '' || password.trim() === '') {
                alert('아이디와 비밀번호를 입력하세요.');
                return;
            }
            const response = await postLogin({
                partner_id: tenantId,
                auth_name: authName,
                auth_password: password,
            });
            const result = response.result;
            if (result) {
                const token = {
                    access_token: result.access_token,
                    refresh_token: result.refresh_token,
                };
                const verifyResponse = await postVerifyToken({
                    access_token: result.access_token,
                });
                const user = verifyResponse.result.user;
                const isAdmin = user.role_name === 'admin';
                const noSelectClasses = !classes || classes === '' || classes === 'select';
                if (noSelectClasses) {
                    if (!isAdmin) {
                        alert('클래스를 선택하세요.');
                        return;
                    }
                }
                token.refresh_token && setCookie('refresh_token', token.refresh_token, 1);
                const popupUrl = noSelectClasses && isAdmin ? '/admin' : `/class/${classes}`;
                const popupName = 'Test';
                const popupFeatures = 'popup=true, width=1920, height=1024, scrollbars=no';
                // Open the popup window
                const popupWindow = window.open(popupUrl, popupName, popupFeatures);
                // Focus on the popup window (optional)
                if (popupWindow) {
                    popupWindow.focus();
                    popupWindow.onload = function () {
                        // Remove an item from localStorage in the popup window
                        popupWindow.localStorage.clear();
                    };
                }
            }
        }
        catch (error) {
            alert(error);
        }
    };
    return (React.createElement(LoginStyled, null,
        React.createElement("div", { className: "login_box" },
            React.createElement(Icon, { icon: "logo", width: 90, height: 40 }),
            React.createElement("div", { className: "login_wrap" },
                React.createElement("form", { onSubmit: e => e.preventDefault() },
                    React.createElement("div", { className: "login_line" },
                        React.createElement("div", { className: "login_title" }, "\uD074\uB798\uC2A4"),
                        React.createElement(ClassesDropDown, { setClasses: setClasses, setTenantId: setTenantId })),
                    React.createElement("div", { className: "login_line" },
                        React.createElement("div", { className: "login_title" }, "\uC544\uC774\uB514"),
                        React.createElement(InputElement, { type: "text", isEnterPrevent: true, placeholder: "\uC544\uC774\uB514", onChange: setAuthName, onSubmit: onLogin })),
                    React.createElement("div", { className: "login_line" },
                        React.createElement("div", { className: "login_title" }, "\uBE44\uBC00\uBC88\uD638"),
                        React.createElement(InputElement, { placeholder: "\uBE44\uBC00\uBC88\uD638", isEnterPrevent: true, type: "password", onChange: setPassword, onSubmit: onLogin })))),
            React.createElement(Button, { className: "btn_default xmd", text: "\uB85C\uADF8\uC778", onClick: onLogin }))));
});

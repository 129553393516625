import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DropDown } from '../../../../common';
import { closeWindow } from '../../app';
import { getUserAuthName } from '../functions';
import { deleteCookie } from './Login';
const MyPageDropDown = React.memo(() => {
    const [dropValue, setDropValue] = React.useState(undefined);
    const user_name = useSelector((state) => getUserAuthName(state));
    useEffect(() => {
        if (!dropValue)
            return;
        if (dropValue.id === 'account.logout') {
            localStorage.clear();
            deleteCookie('refresh_token');
            closeWindow();
        }
    }, [dropValue]);
    return (React.createElement(DropDown, { className: "user dropdown-popup body4", value: { id: user_name, title: user_name }, list: [
            // { id: 'account.information', title: 'account.information' },
            { id: 'account.logout', title: 'account.logout' },
        ], setDropValue: setDropValue, styles: {
            dropWrap: {
                width: 130,
            },
            bodyItem: {
                width: 143,
            },
        }, option: {
            downIcon: {
                size: 10,
            },
        } }));
});
export default MyPageDropDown;

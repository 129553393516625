import React, { useEffect } from 'react';
import { useConferenceList } from '../../../../../server';
import { useTranslation } from 'react-i18next';
import { DropDown } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
export const ClassNameDropDown = React.memo(({ defaultClassId, classId, className, setClassId, }) => {
    const { t } = useTranslation();
    const { isDesktop } = useGlobalState();
    const [dropValue, setDropValue] = React.useState({ id: 'select', title: 'common.select' });
    const [dropList, setDropList] = React.useState([
        { id: 'select', title: 'common.select' },
    ]);
    const { data, refetch } = useConferenceList({
        status: 'booked',
    });
    useEffect(() => {
        refetch();
    }, []);
    useEffect(() => {
        if (!data)
            return;
        const { total_count, items } = data;
        if (total_count > 0) {
            const list = items.map((item) => {
                return {
                    id: item.id,
                    title: item.title,
                };
            });
            setDropList(prevList => {
                const seen = new Set(prevList.map(item => item.id));
                const mergedList = [...prevList, ...list.filter(item => !seen.has(item.id))];
                return mergedList;
            });
            const selected = list.find(item => item.id ===
                (classId === 'select' || !classId || classId === ''
                    ? defaultClassId
                    : classId));
            if (selected)
                setDropValue(selected);
            else
                setDropValue({
                    id: classId === '' ? 'select' : classId,
                    title: className === '' ? 'common.select' : className,
                });
        }
    }, [data, defaultClassId]);
    useEffect(() => {
        setClassId(dropValue.id);
    }, [dropValue]);
    return (React.createElement("div", { className: "field-global-001" },
        React.createElement("div", { className: "task-label" }, t('assignment.class_name')),
        React.createElement("div", { className: "fext-field" },
            React.createElement(DropDown, { className: `dropdown-popup${isDesktop ? '-big' : ''}`, value: dropValue, list: dropList, styles: {
                    dropWrap: {
                        width: '100%',
                    },
                    itemWrap: {
                        maxHeight: 200,
                    },
                }, setDropValue: (item) => {
                    console.log({ item, dropValue });
                    setDropValue(item);
                } }))));
});
